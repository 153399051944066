import axios, { post, put } from 'axios';

export const SegmentList = (params) => async (dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/segments`, { params })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};

export const FindSegment = (id) => async (dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/segments/${id}`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};

export const CreateSegment = (data) => async (dispatch) => {
  try {
    return await post(`${process.env.REACT_APP_BASE_URL}/api/segments`, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};

export const UpdateSegment =
  ({ id, data }) =>
  async (dispatch) => {
    try {
      return await put(
        `${process.env.REACT_APP_BASE_URL}/api/segments/${id}`,
        data
      )
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    } catch (error) {}
  };

export const DestroySegment = (id) => async () => {
  return await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/api/segments/${id}`)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};


export const VoucherList = (params) => async (dispatch) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/voucher`, { params })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};
export const CreateVoucher = (data) => async (dispatch) => {
  try {
    return await post(`${process.env.REACT_APP_BASE_URL}/api/voucher`, data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  } catch (error) {}
};
export const DestroyVoucher = (id) => async () => {
  return await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/api/voucher/${id}`)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
