import React, { useState, useEffect } from 'react';
import { useHistory , Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';

// Custom Component
import TabsIcon from 'Components/layout/TabsIcon';
import Footer from 'Components/Footer';
import MyPagination from 'Components/MyPagination';

// Icon
import Setting from 'assets/icon/setting.png';
import Back from 'assets/icon/back.png';
import Select from 'react-select';
import Edit from 'assets/icon/key-fix.png';
import Add from 'assets/icon/add_menu.png';
import Cancel from 'assets/icon/delete.png';
// API
import {
  SegmentList,
  CreateSegment,
  UpdateSegment,
  DestroySegment,

	CreateVoucher,
	VoucherList,
	DestroyVoucher,
} from 'actions/settings/segment';
import { loadAuth } from 'actions/auth';

export default function Segment() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [Pages, setPages] = useState([
    { value: null, label: 'ทั้งหมด' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ]);

	const TypeDiscount = [
		{
			id: 'bath',
			name: 'บาท'
		},
		{
			id: 'percent',
			name: '%'
		},
	];

  const [isLoading, setisLoading] = useState(false);
  const [Search, setSearch] = useState({
    PerPage: 10,
    CurrentPage: 1,
  });

  const [Data, setData] = useState({
    Name: null,
    Type: null,
    Discount: null,
    Total: null,
  });

  const [validated, setValidated] = useState(false);

  // list
  const [Rows, setRows] = useState(0);
  const [Segments, setSegments] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [deleting, setDeleting] = useState([]);

  const GetVoucher = async (data) => {
    setisLoading(true);
    const params = {
      limit: Search.PerPage,
      page: Search.CurrentPage,
    };
    try {
      const response = await dispatch(VoucherList(params));
      if (response) {
        const segments = response.data.data.data.map((obj) => ({
          ...obj,
          isEdit: false,
        }));
        setSegments(segments);
        setRows(response.data.data.last_page);
        setisLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const getAuth = async (data) => {
    try {
      const params = {
        name: [data.name],
        permission : data.permission,
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/setting');
      }
    } catch (error) {
	    history.push('/');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

		console.log(Data)
		// return null;

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    const data = {
      name: Data.Name,
      type: Data.Type,
      discount: Data.Discount,
      total: Data.Total,
    };

    try {
      const response = await dispatch(CreateVoucher(data));

      if (response) {
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          history.push('/setting');
        }
        setData({
					Name: null,
					Type: null,
					Discount: null,
					Total: null,
				});
        setValidated(false);
        GetVoucher();
      } else {
        console.log('failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  const deleteSegment = async (e, id) => {
    e.preventDefault();

    try {
      const response = await dispatch(DestroyVoucher(id));

      if (response) {
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          history.push('/setting');
        }
        GetVoucher();
      } else {
        console.log('failed');
      }
      setDeleting([]);
      setConfirmation(false);
    } catch (error) {
      console.error(error);
      setDeleting([]);
      setConfirmation(false);
    } finally {
      console.log('finally');
      setDeleting([]);
      setConfirmation(false);
    }
  };
  useEffect(() => {
    getAuth({name:'ตั้งค่า',permission:'view'});
    GetVoucher();
  }, [Search.PerPage, Search.CurrentPage]);

  const ToggleEdit = async (index, segment) => {
    console.log('segment', segment);
    let items = [...Segments];
    items[index] = { ...items[index], isEdit: !segment.isEdit };
    setSegments(items);

    if (segment.isEdit) {
      const form = {
        id: segment.id,
        data: segment,
      };
      try {
        const response = await dispatch(UpdateSegment(form));
        if(response.data.data === 'Unauthorized'){
          alert(response.data.message)
          history.push('/setting');
        }
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally');
      }
    }
  };

function SetDiscountTypeValue(data) {
	// console.log(data)
	setData({...Data, Type: data.id});
}

  function SetEditValue(index, value) {
    let items = [...Segments];
    items[index] = { ...items[index], name: value };
    setSegments(items);
  }

  function close() {
    history.push(`/setting`);
  }

  function deleteConfirmation(e, id) {
    const value = {
      e: e,
      id: id,
    }
    setDeleting(value)
    setConfirmation(true)
  }

  return (
    <>
      <Container fluid className="package-container Stock Setting bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; คูปอง</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={Setting} />
                  <b><Link to="/setting" className='link-pagination'>ตั้งค่า</Link> &gt; คูปอง</b>
                </h5>
              </div>
              <div className="modal-body ">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>

                <Row className="g-0 justify-content-center">
                  <Col xs={12}>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Col xs={12} md={9} lg={3}>
                          <Form.Control
                            placeholder="ชื่อคูปอง"
                            required
                            value={Data.Name ? Data.Name : ''}
                            onChange={(e) =>
                              setData({...Data, Name: e.target.value })
                            }
                          ></Form.Control>
                        </Col>
												<Col xs={12} md={9} lg={2}>
                          <Form.Control
														type="Number"
                            placeholder="จำนวนลด"
                            required
                            value={Data.Discount ? Data.Discount : ''}
                            onChange={(e) =>
                              setData({...Data, Discount: e.target.value })
                            }
                          ></Form.Control>
                        </Col>
												<Col xs={12} md={9} lg={2}>
													<Select
														placeholder="ประเภทส่วนลดคูปอง"
														className="text-left select-style"
														aria-label="Default select example"
														options={TypeDiscount}
														getOptionLabel={option => option.name}
														getOptionValue={option => option.id}
														required
														// value={Data.Type}
														onChange={(data) => SetDiscountTypeValue(data)}
													/>
                        </Col>
												<Col xs={12} md={9} lg={2}>
                          <Form.Control
														type="Number"
                            placeholder="จำนวนคูปอง"
                            required
                            value={Data.Total ? Data.Total : ''}
                            onChange={(e) =>
                              setData({...Data, Total: e.target.value })
                            }
                          ></Form.Control>
                        </Col>
												
                        <Col md={3} lg={3} xl={2}>
                          <Button type="submit" className="button">
                            <img src={Add} /> &nbsp; เพิ่มรายการ
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row className='mt-4'></Row>
                <div className="set-height">
                  <Row className="g-0  justify-content-center">
                    <Row className="">
                      <table className="table table-responsive ">
                        <thead>
                          <tr className="t-header-setting">
                            <th className="text-left">คูปอง</th>
                            <th className="text-left">ส่วนลด</th>
                            <th className="text-left">จำนวนการใช้งาน</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Segments?.map((segment, key) => {
                            return (
                              <tr className='bg-white' key={segment.id}>
                                <td className='text-left'>
                                  {segment.isEdit ? (
                                    <Form.Control
                                      placeholder="Segment"
                                      required
                                      value={segment.name ? segment.name : ''}
                                      onChange={(e) =>
                                        SetEditValue(key, e.target.value)
                                      }
                                    ></Form.Control>
                                  ) : (
                                    `${segment.name}`
                                  )}
                                </td>
																<td className='text-left'>
                                  {`${segment.discount} ${segment.type == "bath" ? `บาท` : '%'}`}
                                </td>
																<td className='text-left'>
                                  {`${segment.used} / ${segment.total}`}
                                </td>
																
                                <td className="text-left">
                                  {/* <Button
                                    className="button-image pr-2"
                                    onClick={() => ToggleEdit(key, segment)}
                                  >
                                    <img src={Edit} />
                                  </Button>
                                  &nbsp; &nbsp; */}
                                  <Button
                                    className="button-image"
                                    onClick={(e) =>
                                      deleteConfirmation(e, segment.id)
                                    }
                                  >
                                    <img src={Cancel} />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </Row>
                </div>
                <Row className="g-0 mt-3 mb-3">
                  <Col md={6} lg={4} className=" set-page">
                    <Row>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>แสดง</p>
                      </Col>
                      <Col md={6} lg={6}>
                        <Select
                          className="text-left select-style"
                          aria-label="Default select example"
                          isSearchable={false}
                          options={Pages}
                          value={Pages.find((data) => {return data.value === Search.PerPage})}
                          onChange={(data) =>
                            setSearch({ ...Search, PerPage: data.value })
                          }
                          defaultValue={{ value: 10, label: '10' }}
                        />
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                        <p style={{ margin: 0 }}>รายการ</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="d-flex justify-content-end set-paginate">
                    <div className="box-paginate">
                      {/* {!isLoading ? (
                        <MyPagination
                          totPages={Rows}
                          currentPage={Search.CurrentPage}
                          pageClicked={(e) => {
                            setSearch({ ...Search, CurrentPage: e });
                          }}
                        ></MyPagination>
                      ) : (
                        ''
                      )} */}
                      {
                        !isLoading ? 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                        : 
                          Rows > 1 && Search.PerPage > 0 ?
                            <MyPagination totPages={Rows} currentPage={Search.CurrentPage} pageClicked={(e) => { setSearch({...Search, CurrentPage: e }) }}></MyPagination> 
                          : ''
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        className="modal-style"
        centered
        show={confirmation}
        onHide={ () => setConfirmation(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>ยืนยันการลบ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setConfirmation(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                <h3>ยืนยันการลบรายการหรือไม่</h3>
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => deleteSegment(deleting.e, deleting.id) }>
                      ยืนยัน
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => setConfirmation(false) }>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
