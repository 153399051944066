import {
  Switch,
  Route,
  useHistory
} from 'react-router-dom';
import { SignOut } from 'actions/auth/index';
import React, { useState, useEffect } from 'react';
// middle systems //
import Dashboard from 'MiddleSystems/Dashboard/Index';
import Dashboard_data from 'MiddleSystems/Dashboard/Dashboard';
import Package from 'MiddleSystems/Package/Index';
import ProductManage from 'MiddleSystems/Product/Index';
import AddProduct from 'MiddleSystems/Product/Add';
import EditProduct from 'MiddleSystems/Product/Edit';
import Member from 'MiddleSystems/Member/Index';
import MemberCreate from 'MiddleSystems/Member/MemberCreate';
import MemberInformation from 'MiddleSystems/Member/MemberInformation';
import JusticInformation from 'MiddleSystems/Member/JuristicPersonInformation';
import Login from 'Login/Index';
import User from 'MiddleSystems/User/Index';
import UserCreate from 'MiddleSystems/User/UserCreate';
import EditUser from 'MiddleSystems/User/EditUser';
import B2BCompany from 'MiddleSystems/B2B/Index';
import AddB2B from 'MiddleSystems/B2B/Add';
import EditB2B from 'MiddleSystems/B2B/Edit';
import Service from 'MiddleSystems/Service/Index';
import AddService from 'MiddleSystems/Service/Add';
import EditService from 'MiddleSystems/Service/Edit';

// New
import AddCarOwner from 'MiddleSystems/Member/Components/CarInfo/AddCar';
import EditCarOwner from 'MiddleSystems/Member/Components/CarInfo/EditCar';

import AddPackage from 'MiddleSystems/Package/Add';
import EditPackage from 'MiddleSystems/Package/Edit';
// import AddWashPart from 'MiddleSystems/Package/AddWashPart';
// import AddLightfilterPart from 'MiddleSystems/Package/AddLightfilterPart';
// import AddCoatPart from 'MiddleSystems/Package/AddCoatPart';
// import AddProtectivePart from 'MiddleSystems/Package/AddProtectivePart';
// import AddServiceFilter from 'MiddleSystems/Package/AddServiceFilter';
// import AddSpecialPackage from 'MiddleSystems/Package/AddSpecialPackage';
import Staff from 'MiddleSystems/Staff/Index';
import AddStaff from 'MiddleSystems/Staff/Add';
import EditStaff from 'MiddleSystems/Staff/Edit';
import Management from 'MiddleSystems/Management/Index';
import ManagementAdd from 'MiddleSystems/Management/Add';
import ManagementEdit from 'MiddleSystems/Management/Edit';
import ManageMentDashBoard from 'MiddleSystems/Management/Dashboard';
import Profile from 'MiddleSystems/Profile/Index';
import Property from 'MiddleSystems/Property/Index';
import DataProperty from 'MiddleSystems/Property/DataProperty';
import AddStorageProperty from 'MiddleSystems/Property/AddStorage';
import EditStorageProperty from 'MiddleSystems/Property/EditStorage';
import DataPropertyEdid from './MiddleSystems/Property/DataPropertyEdit';

import Stock from 'MiddleSystems/Stock/Index';
import Stock_History from 'MiddleSystems/Stock/History';
import Receipt_Order from 'MiddleSystems/Stock/ReceiptOrder';
import RequestOrder from 'MiddleSystems/Stock/RequestOrder/Index';
import RequestOrder_Status from 'MiddleSystems/Stock/RequestOrder/RequestOrderStatus';
import RequestOrder_Byid from 'MiddleSystems/Stock/RequestOrder/RequestOrderById';
import Admit from 'MiddleSystems/Stock/Admit/Index';
import Datadmit from 'MiddleSystems/Stock/Admit/Datadmit';
import CutStock from 'MiddleSystems/Stock/CutStock';
import AdjustStock from 'MiddleSystems/Stock/Adjust';
import PurchaseOrder from 'MiddleSystems/Stock/PurchaseOrder/Index';
// import DataPurchaseOrder from 'MiddleSystems/Stock/PurchaseOrder/DataPurchaseOrder';

import Film from 'MiddleSystems/Film/index';

// New Settings
import Setting from 'Setting/index'
import Category from 'Setting/Category/Index';
import CarType from 'Setting/CarType/Index';
import Voucher from 'Setting/Voucher/Index';
import Unit from 'Setting/Unit/Index';
import EditUnit from 'Setting/Unit/Edit';
import SettingCarBrand from 'Setting/CarBrand/Index';
import SettingCarColor from 'Setting/CarColor/Index';
import SettingAddCarBrand from 'Setting/CarBrand/Add';
import SettingEditCarBrand from 'Setting/CarBrand/Edit';
import Segment from 'Setting/Segment/Index';
import Bank from 'Setting/Bank/Index';
import AddBank from 'Setting/Bank/Add';
import EditBank from 'Setting/Bank/Edit';
import Permission from 'Setting/Permission/Index';
import AddPermission from 'Setting/Permission/Add';
import EditPermission from 'Setting/Permission/Edit';
import Department from 'Setting/Department/Index';

import Report from 'MiddleSystems/Report/Index';
import ProcessStatus from 'MiddleSystems/Report/ProcessStatus';
import StockReport from 'MiddleSystems/Report/Stock';
import BranchSalesReport from 'MiddleSystems/Report/BranchSalesReport';
import ProductSalesReport from 'MiddleSystems/Report/ProductSalesReport';
import AllBranchSalesReport from 'MiddleSystems/Report/AllBranchSalesReport';
import AllBranchSummaryReport from 'MiddleSystems/Report/AllBranchSummaryReport';
import CustomerServiceList from 'MiddleSystems/Report/CustomerServiceList';
import ShowroomSalesReport from 'MiddleSystems/Report/ShowroomSalesReport';
import TechnicianQueueReport from 'MiddleSystems/Report/TechnicianQueueReport';
import ProductCostReport from 'MiddleSystems/Report/ProductCostReport';
import IncomeReport from 'MiddleSystems/Report/IncomeReport';
import CarServiceReport from 'MiddleSystems/Report/CarServiceReport';
import Notification from 'MiddleSystems/Notification/Index';
import Coupon from 'MiddleSystems/Notification/Coupon';
import Appointment from 'MiddleSystems/Appointment/Index';
import Appointment_ID from 'MiddleSystems/Appointment/AppointmentByID';

import TechnicianQueue from 'MiddleSystems/TechnicianQueue/Index';
import TechnicianQueueDetail from 'MiddleSystems/TechnicianQueue/Detail';

// ///////// สาขา โฟลเดอร์ branch/////////////
import MenuBranch from 'BranchSystems/Index';
import BranchUser from 'BranchSystems/User/Index';
import BranchUserCreate from 'BranchSystems/User/UserCreate';
import BranchUserEdit from 'BranchSystems/User/EditUser';

import BranchMember from 'BranchSystems/Member/Index';
import BranchMemberCreate from 'BranchSystems/Member/MemberCreate';
import BranchMemberInformation from 'BranchSystems/Member/MemberInformation';
// import BranchJusticInformation from 'BranchSystems/Member/JuristicPersonInformation';
import BranchAddCarOwner from 'BranchSystems/Member/Components/CarInfo/AddCar';
import BranchEditCarOwner from 'BranchSystems/Member/Components/CarInfo/EditCar';

import BranchB2BCompany from 'BranchSystems/B2B/Index';
import BranchAddB2B from 'BranchSystems/B2B/Add';
import BranchEditB2B from 'BranchSystems/B2B/Edit';

import BranchBill from 'BranchSystems/Bill/Index';
import BranchAcceptPayment from 'BranchSystems/Bill/AcceptPayment';

import Profile_Branch from 'BranchSystems/Profile/Index';
import Appointment_Branch from 'BranchSystems/Appointment/Index';
import Appointment_ID_Branch from 'BranchSystems/Appointment/AppointmentByID';
import Stock_Branch from 'BranchSystems/Stock/Index';
import Stock_History_Branch from 'BranchSystems/Stock/History';
import Stock_Adjust_Branch from 'BranchSystems/Stock/Adjust';
import Stock_CutStock_Branch from 'BranchSystems/Stock/CutStock';
import Stock_TransferStore_Branch from 'BranchSystems/Stock/TransferStore';
import Stock_Recive_Branch from 'BranchSystems/Stock/ReceiptOrder';
import Stock_OrderToStock_branch from 'BranchSystems/Stock/OrderToStock/Index';
import Stock_OrderToStock_Data_Branch from 'BranchSystems/Stock/OrderToStock/Data';
import Stock_Admit_Branch from 'BranchSystems/Stock/Admit/Index';
import Report_Branch from 'BranchSystems/Report/Index';
import Notification_Branch from 'BranchSystems/Notification/Index';
import Dashboard_Branch from 'BranchSystems/Dashboard/Dashboard';

import Job from 'BranchSystems/Job/Index';
import Job_Inside from 'BranchSystems/Job/Inside';
import Job_Inside_Old from 'BranchSystems/Job/InsideOld';
import Job_Order from 'BranchSystems/Job/Order';
import Job_Inside_Data from 'BranchSystems/Job/InsideData';
import Job_Inside_Data_Old from 'BranchSystems/Job/InsideDataOld';
import Job_B2b_ShowRoom from 'BranchSystems/Job/CompanyB2BShowRoom';
import Job_B2b_Member from 'BranchSystems/Job/CompanyB2BMember';
import Job_B2B_Package from 'BranchSystems/Job/CompanyB2BPackage';
import Job_B2B_Order from 'BranchSystems/Job/CompanyB2BOrder';

import ImportExcel from 'Setting/ImportExcel/Index';

import {
  Modal,
  Form,
  Row,
  Col,
  Button
} from 'react-bootstrap';

// import BranchBillOrderPdf from 'BranchSystems/Job/components/PDFBillOrder'
// function requireAuth(nextState, replace, callback) {
//   const token = localStorage.getItem('access_token');
//   if (!token) replace('/login');
//   return callback();
// }

import PreviewBill from 'Setting/PreviewBill';
// import { updateUser } from 'slice/userSlice';

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
// import { updateNotification } from 'slice/notificationSlice';
import { setNotification } from 'slice/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import NotificationSound from 'assets/notification_sound.mp3'
import axios from 'axios';
window.Pusher = Pusher;

function App() {
  const dispatch = useDispatch()
  const history = useHistory();

  const { employee, authentication } = useSelector( (state) => state.user)
  const [DialogNotification, setDialogNotification] = useState(false);
  const { notification } = useSelector( (state) => state.user)

  const handleSubmit = (status) => {
    setDialogNotification(false)

    if (status === 'รายละเอียด') {
      history.push(`/notification/${notification?.job?.notification.id}`)
    }else{
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      axios.put(`${process.env.REACT_APP_BASE_URL}/api/notifications/${notification.id}/coupon`, {
        job_id: notification.job.id,
        status
      } , config).then((response) => {
        console.log('response', response)
      }).catch((e) => console.log(e))
    }
  }

  const [InitPusher, setInitPusher] = useState(false);

  useEffect(() => {
    if (authentication && authentication.isLogin && !InitPusher) {
      window.Echo = new Echo({
        broadcaster: process.env.REACT_APP_BROADCASTER,
        key: process.env.REACT_APP_BROADCASTER_KEY,
        cluster: process.env.REACT_APP_BROADCASTER_CLUSTER,
        forceTLS: true,
        encryption: true,
        authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        },
      });

      window.Echo.connector.pusher.connection.bind('connected', () => {
        setInitPusher(true)
      });

      window.Echo.private('notification').listen('NotificationEvent', (data) => {
        dispatch(setNotification(data.message))
        console.log('notification', data.message)
        if (employee && employee.branch_id === 1 && data.message.branch_id === 1 && data.message.type === 'ส่วนลด' && ['รออนุมัติ'].includes(data.message.status) ) {
          const audio = new Audio(NotificationSound);
          audio.play();
          setDialogNotification(true)
        }
      })
    }

  }, [authentication]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/import" component={ImportExcel} />

        <Route path="/" component={Dashboard} exact />
        <Route path="/dashboard" component={Dashboard_data} />
        
        <Route path="/product" component={ProductManage} exact />
        <Route path="/product/add" component={AddProduct} />
        <Route path="/product/:id/edit" component={EditProduct} />
        <Route path="/user" component={User} exact />
        <Route path="/user/add" component={UserCreate} />
        <Route path="/user/edit/:id" component={EditUser} />
        <Route path="/member" component={Member} exact />
        <Route path="/member/add" component={MemberCreate} />
        <Route exact path="/member/edit/:id" component={MemberInformation} />
        <Route path="/member/edit/:id/add-car" component={AddCarOwner} />
        <Route
          path="/member/edit/:id/car-owner/:car_owner_id"
          component={EditCarOwner}
        />
        {/* <Route
          path="/member/justic-information"
          component={JusticInformation}
        /> */}

        <Route path="/b2b" component={B2BCompany} exact />
        <Route path="/b2b/add" component={AddB2B} />
        <Route path="/b2b/:id/edit" component={EditB2B} />

        {/* package */}
        <Route path="/package/:name" component={Package} exact />
        <Route path="/package/:name/add" component={AddPackage} />
        <Route path="/package/:name/:id/edit/:type" component={EditPackage} />

        {/* <Route
          path="/package/add-light-filter-part"
          component={AddLightfilterPart}
        /> */}
        {/* <Route path="/package/add-coat-part" component={AddCoatPart} /> */}
        {/* <Route
          path="/package/add-protective-part"
          component={AddProtectivePart}
        /> */}
        {/* <Route
          path="/package/add-service-filter"
          component={AddServiceFilter}
        /> */}
        {/* <Route
          path="/package/add-special-package"
          component={AddSpecialPackage}
        /> */}
        <Route path="/service" component={Service} exact />
        <Route path="/service/add-service" component={AddService} />
        <Route path="/service/edit-service/:id" component={EditService} />
        <Route path="/staff" component={Staff} exact />
        <Route path="/staff/add-staff" component={AddStaff} />
        <Route path="/staff/edit-staff/:id" component={EditStaff} />
        <Route path="/management" component={Management} exact />
        <Route path="/management/add" component={ManagementAdd} />
        <Route path="/management/:id/edit" component={ManagementEdit} />
        <Route path="/management/dashboard" component={ManageMentDashBoard} />
        <Route path="/profile" component={Profile} />
        <Route path="/property" component={Property} exact />
        {/*    property  > :id */}
        <Route path="/property/data-property" component={DataProperty} />
        <Route
          path="/property/data-property-edit/:id"
          component={DataPropertyEdid}
        />
        <Route
          path="/property/add-storage/:id"
          component={AddStorageProperty}
        />
        <Route
          path="/property/edit-storage/:id/:information_id"
          component={EditStorageProperty}
        />
        {/* <Route path="/property/data-property-edit/:id/add-storage" component={AddStorageProperty} /> */}
        <Route path="/stock" component={Stock} exact />
        <Route path="/stock/history/:product_id" component={Stock_History} />
        <Route path="/stock/receipt-order" component={Receipt_Order} exact />
        <Route path="/stock/request-order" component={RequestOrder} exact />
        <Route
          path="/stock/request-order/status/:id"
          component={RequestOrder_Status}
        />
        <Route path="/stock/request-order/:id" component={RequestOrder_Byid} />
        <Route path="/stock/receipt-order/admit/:id" component={Admit} />
        <Route path="/stock/receipt-order/:id" component={Datadmit} />
        <Route path="/stock/cut-stock" component={CutStock} />
        <Route path="/stock/adjust-stock" component={AdjustStock} />
        <Route path="/stock/purchase-order" component={PurchaseOrder} exact />
        {/* <Route path="/stock/purchase-order/:id" component={DataPurchaseOrder} /> */}

          {/* New Settings */}
          
          <Route path="/setting" component={Setting} exact/>
          <Route path="/setting/department" component={Department} />
          <Route path="/setting/category" component={Category} />
          <Route exact path="/setting/unit" component={Unit} />
          <Route path="/setting/unit/:id/edit" component={EditUnit} />
          <Route path="/setting/segment" component={Segment} />
          <Route exact path="/setting/car-brand" component={SettingCarBrand} />
          <Route exact path="/setting/car-color" component={SettingCarColor} />
          <Route
            exact
            path="/setting/car-brand/add"
            component={SettingAddCarBrand}
          />
          <Route
            path="/setting/car-brand/:id/edit"
            component={SettingEditCarBrand}
          />
          <Route exact path="/setting/voucher" component={Voucher} />
          <Route exact path="/setting/bank" component={Bank} />
          <Route exact path="/setting/bank/add" component={AddBank} />
          <Route path="/setting/bank/:id/edit/:type" component={EditBank} />
          <Route path="/setting/car-type" component={CarType} />
          <Route exact path="/setting/permission" component={Permission} />
          <Route path="/setting/permission/add" component={AddPermission} />
          <Route path="/setting/permission/edit/:id" component={EditPermission} />
          
          <Route path="/report" component={Report} exact/>
          <Route path="/report/process-status" component={ProcessStatus} />
          <Route path="/report/stock" component={StockReport} />
          <Route path="/report/branch-sales-report" component={BranchSalesReport} />
          <Route path="/report/product-sales-report" component={ProductSalesReport} />
          <Route path="/report/all-branch-sales-report" component={AllBranchSalesReport} />
          <Route path="/report/all-branch-summary-report" component={AllBranchSummaryReport} />
          <Route path="/report/customer-service-list" component={CustomerServiceList} />
          <Route path="/report/car-service-report" component={CarServiceReport} />
          <Route path="/report/showroom-sales-report" component={ShowroomSalesReport} />
          <Route path="/report/technician-queue-report" component={TechnicianQueueReport} />
          <Route path="/report/product-cost-report" component={ProductCostReport} />
          <Route path="/report/income-report" component={IncomeReport} />

          <Route path="/notification" component={Notification} exact />
          <Route path="/notification/:id" component={Coupon} />
          
          <Route path="/appointment" component={Appointment} exact />
          <Route path="/appointment/:id" component={Appointment_ID} />

        <Route path="/film" component={Film} />
        {/* ///////// Branch สาขา */}
        <Route path="/branch" component={MenuBranch} exact />
        <Route path="/job" component={Job} exact />
        <Route path="/job/inside-data" component={Job_Inside_Data} />
        <Route path="/job/inside-data-old" component={Job_Inside_Data_Old} />
        <Route path="/job/inside-create" component={Job_Inside} />
        <Route path="/job/inside-create-old" component={Job_Inside_Old} />
        <Route path="/job/order/:id" component={Job_Order} />
        <Route
          path="/job/company-b2b-show-room"
          component={Job_B2b_ShowRoom}
        />
        <Route
          path="/job/company-b2b-member"
          component={Job_B2b_Member}
        />
        <Route
          path="/branch/job/company-b2b-package"
          component={Job_B2B_Package}
        />
        <Route path="/branch/job/company-b2b-order" component={Job_B2B_Order} exact/>
        
        <Route path="/branch/profile" component={Profile_Branch} />
        <Route
          path="/branch/appointment"
          component={Appointment_Branch}
          exact
        />
        <Route
          path="/branch/appointment/:id"
          component={Appointment_ID_Branch}
        />
        <Route path="/branch/user" component={BranchUser} exact />
        <Route path="/branch/user/add" component={BranchUserCreate} />
        <Route path="/branch/user/edit" component={BranchUserEdit} />

        <Route path="/branch/member" component={BranchMember} exact />
        <Route path="/branch/member/add" component={BranchMemberCreate} />
        <Route exact path="/branch/member/edit/:id" component={BranchMemberInformation} />
        <Route path="/branch/member/edit/:id/add-car" component={BranchAddCarOwner} />
        <Route
          path="/branch/member/edit/:id/car-owner/:car_owner_id"
          component={BranchEditCarOwner}
        />
        {/* <Route
          path="/branch/member/justic-information"
          component={BranchJusticInformation}
        /> */}

        <Route path="/branch/b2b" component={BranchB2BCompany} exact />
        <Route path="/branch/b2b/add" component={BranchAddB2B} />
        <Route path="/branch/b2b/:id/edit" component={BranchEditB2B} />

        <Route path="/branch/stock" component={Stock_Branch} exact />
        <Route
          path="/branch/stock/receive"
          component={Stock_Recive_Branch}
          exact
        />
        <Route path="/branch/stock/adjust" component={Stock_Adjust_Branch} />
        <Route
          path="/stock/transfer-store"
          component={Stock_TransferStore_Branch}
        />
        <Route
          path="/branch/stock/cutstock"
          component={Stock_CutStock_Branch}
        />
        <Route
          path="/stock/receive/order-to-stock"
          component={Stock_OrderToStock_branch}
          exact
        />
        <Route
          path="/branch/stock/receive/admit"
          component={Stock_Admit_Branch}
          exact
        />
        <Route
          path="/stock/receive/order-to-stock/:id"
          component={Stock_OrderToStock_Data_Branch}
        />
        <Route path="/branch/stock/history" component={Stock_History_Branch} />
        <Route path="/branch/notification" component={Notification_Branch} />
        <Route path="/branch/report" component={Report_Branch} />
        <Route path="/branch/dashboard" component={Dashboard_Branch} />
        <Route path="/bill" component={BranchBill} exact/>
        <Route path="/bill/:id" component={BranchAcceptPayment} exact/>

        <Route exact path="/technician-queue/:jobId?" component={TechnicianQueue}/>
        <Route path="/technician-queue/:date/detail" component={TechnicianQueueDetail}/>

        <Route path="/preview-bill" component={PreviewBill} exact/>
      </Switch>
      
      <Modal
        className="modal-style"
        centered
        show={DialogNotification}
        onHide={ () => setDialogNotification(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            {
              `
              คำขออนุมัติ
              ${notification?.type}
              หมายเลขบิล -
              ${notification?.job?.bill?.bill_code}
              `
            }
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setDialogNotification(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body className="body-set-height">
          {/* onSubmit={handleSubmit} */}
          <Form noValidate validated={true}>
              <Row
                className="justify-content-end align-items-center"
              >
                <Col xs={4}>
                  <Button type="button" className="button" onClick={() => handleSubmit('รายละเอียด')}>
                    ดูรายละเอียด
                  </Button>
                </Col>
              </Row>
            <Row
              className="justify-content-center align-items-center"
              style={{ height: '200px' }}
            >
              <Col xs={10} lg={10} className="mt-2 text-start">
                <h5 className='mb-1'>{ `สาขา - ${notification?.job?.branch?.name}` }</h5>
                <h5 className='mb-1'>{ `ราคาสินค้า/บริการ - ${notification?.job?.total_price}` }</h5>
                <h5 className='mb-1'>{ `ส่วนลด - ${notification?.job?.discount_calculate}` }</h5>
                <h5 className='mb-1'>{ `ราคารวม - ${notification?.job?.total_price - notification?.job?.discount_calculate}` }</h5>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={() => handleSubmit('อนุมัติ')}>
                      อนุมัติ
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => handleSubmit('ไม่อนุมัติ')}>
                      ปฏิเสธ
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default App;
