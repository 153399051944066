import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Modal, ToastContainer, Toast } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import Select, { components } from 'react-select';
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import Save from '../../assets/icon/save.png';
import print from '../../assets/icon/print.png';
import Return from '../../assets/icon/new-re-turn.png';
import Delete from '../../assets/icon/delete.png';
import Calendar from '../../assets/icon/calendar-bg.png';
import { DatePicker, Space } from 'antd';
import Assign from '../../assets/icon/assign.png';
import Upload from '../../assets/icon/upload.png';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js'

import PenddingApproved from'assets/icon-job/pending_approved.png';
import Pending from'assets/icon-job/pending.png';
import Approved from'assets/icon-job/approved.png';
import Rejected from'assets/icon-job/rejected.png';
import Done from'assets/icon-job/done.png';

import { Bill7 } from 'Setting/PreviewBill/Component/Bill7'

//component 
import MainComponent from './Package/Index';
import WashPart from './Package/WashPart';
import Lightfilter from './Package/Lightfilter';
import CoatingService from './Package/CoatingService';
import Protective from './Package/Protective';
import ServiceFilter from './Package/ServiceFilter';
import SpecialPackage from './Package/SpecialPackage';

//API 
import { ProductList} from 'actions/product' 
import { LoadServices } from 'actions/service'
import { PackageList,PackageTypeList,FindPackageType, GetFilmModel,GetFilmSizeCodeByModel } from 'actions/package';
import { UpdateJob, MergeJobPackage } from 'actions/jobs'
import { Customer } from 'actions/customer'
import { loadAuth } from 'actions/auth';
import { VoucherList } from 'actions/settings/segment';

//Bill
import { Bill8 } from 'Setting/PreviewBill/Component/Bill8'

export default function Order(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  let job = props.JobState

  const [canShowroomEdit, setCanShowroomEdit] = useState(false);
  const getAuthShowRoomCanEdit = async () => {
    try {
      const params = {
        name: ['showroom'],
        permission : 'edit',
      }
      const response = await dispatch(loadAuth(params));
      // console.log('s1',canShowroomCreate);
      if(response.data.data !== 'Unauthorized'){
        // alert(response.data.message)
        // history.push('/report');
        setCanShowroomEdit(true);
        // console.log('s2',canShowroomCreate);
      }
    } catch (error) {
      // history.push('/report');
      console.error(error);
    }
  };
  
  const [isDisabled, setDisabled] = useState(false);
  const [isClearable, setClearable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isRtl, setRtl] = useState(false);
  const [isSearchable, setSearchable] = useState(true);

  const [statusService, setStatusService] = useState(false);
  const [regisNumber, setRegisNumber] = useState([{ value: '', label: '' }]);
  const [hour, setHour] = useState([{ value: '', label: '' }]);
  const [minute, setMinute] = useState([{ value: '', label: '' }]);
  const [Discount, setDiscount] = useState(
    { value: 'baht', label: 'ส่วนลด(บาท)' }
    );
  const [view_placeholder, Setview_placeholder] = useState('%');
  const statusServiceToggler = (data) => {
    setStatusService(data) ;
    if(data === false) { 
      setPayerForm({
        ...PayerForm, 
          payer_id: JobState.customer && JobState.customer.id,
          customer_code: JobState.customer && JobState.customer.customer_code,
          customer: JobState.customer,
          fullname: JobState.customer ? JobState.customer.first_name === null && JobState.customer.last_name === null ? JobState.customer.organization !== null ? JobState.customer.organization : null : `${JobState.customer.first_name} ${JobState.customer.last_name} (${JobState.customer.nickname})` : null,
          address: JobState.customer && JobState.customer.address,
          phone: JobState.customer && JobState.customer.phone ? JobState.customer.phone : [],
      });
    }
    else{
      getCustomers()
      setPayerForm({
        ...PayerForm, 
          payer_id: null,
          customer_code: null,
          fullname: null,
          customer: [],
          address: null,
          phone: [],
      });
    }
    
  };
  const [CustomersState,setCustomers] = useState([]);
  const [product, setProduct] = useState(true);
  const [service, setService] = useState(false);
  const [Package, setPackage] = useState(false);

  const JobId = props.JobId
  const JobState = props.JobState
  const CarOwner = props.CarOwnerState
  const PayerState = props.PayerState
  const JobDetails = JobState && JobState.job_details ? JobState.job_details : []
  const [PayerForm,setPayerForm] = useState({
    customer_code: '',
    fullname: '',
    address: '',
    phone: [],
  }); 
  const [Packages,setPackages] = useState([]);
  const [PackagesDefault,setPackagesDefault] = useState([]);
  const [Products,setProducts] = useState([]);
  const [Vouchers,setVouchers] = useState([]);
  const [Services,setServices] = useState([]);
  const [PackageTypes,setPackageTypes] = useState([]);
  const [Alert,setAlert] = useState([]);
  const optionDiscountType = [
    { value: 'percent', label: 'ส่วนลด(เปอร์เซ็น)' },
    { value: 'baht', label: 'ส่วนลด(บาท)' },
  ];
  const [Data,setData] = useState({
    StatusJob: { value: 'pending_approved', label: 'รออนุมัติ' },
    StorageList: [],
    DiscountType: { value: 'baht', label: 'ส่วนลด(บาท)' },
    // Note:'',
    // InsiteNote: '',
    SumTotal: '',
    DiscountTotal: '',
    DiscountTotalCalculate:'',
    SumTotalGrand: '', //SumTotal - DiscountTotalCaluculate
    Vat: {
      value: 0,
      label: '0%'
    },
    VoucherDiscount: 0,
    VoucherId: null,
    TaxActive: false,
    VatCalculate: '',
    SumTotalAfterVat:'',
    BeforeUploadFile: [],
    AfterUploadFile: [],
  });
  const [validated, setValidated] = useState(true);
  const [DisplayButtonOrder,setDisplayButtonOrder] = useState(false)
  const [TypeWork,setTypeWork] = useState([])

  const [washPart,setWashPart] = useState([]);
  const [lightfilter,setLightfilter] = useState([]);
  const [coatingService,setCoatingService] = useState([]);
  const [protective,setProtective] = useState([]);
  const [serviceFilter,setServiceFilter] = useState([]);

  const [modelNumber, setModelNumber] = useState([{ value: '', label: '' }]);
  const [filmThickness, setFilmThickness] = useState('');
  const [TextDetails,setTextDetails] = useState('');
  const [FilmModel, setFilmModel] = useState();
  const [PackageSizeModel,setPackageSizeModel] = useState([]);
  const [PackageSizeCode,setPackageSizeCode] = useState([]);
  // const [PointCoatingPackage,setPointCoatingPackage] = useState([]);
  // const [pointCoatings, setPointCoatings] = useState(null);

  const checkboxRef = useRef(null);
  const [MergeJob, setMergeJob] = useState([]);
  const [MergeJobPrice, setMergeJobPrice] = useState();
  

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  function SetDiscount(data) {
    setDiscount({ value: data.value, label: data.label });
  }

  function SetDiscountType(data){
    setData({...Data, DiscountType: data})
  }

  function SetVoucherDiscount(data){
    console.log(data)
    let result = 0;
    let vid = data.id;
    if(data.type == "bath"){
      result = data.discount;
    }else{
      let sum = Data.StorageList.reduce((n, {GrandTotal}) => n + GrandTotal, 0)
      result = Number(sum - Data.DiscountTotalCalculate) * Number(data.discount / 100);
    }
    setData({...Data, VoucherDiscount: result, VoucherId: vid })
    // setData({...Data, VoucherId: vid})
  }

  const optionDiscount = [
    { value: 'percent', label: 'ส่วนลด(เปอร์เซ็น)' },
    { value: 'baht', label: 'ส่วนลด(บาท)' },
  ];

  function gatNameCustomerss(JobState){
    if(JobState.customer.first_name === null && JobState.customer.last_name === null){
      if(JobState.customer.organization !== null){
        return JobState.customer.organization
      }else{ return '-'}
    }else{ 
      return `${JobState.customer.first_name} ${JobState.customer.last_name} (${JobState.customer.nickname})`
    }
  }

  function setStorageKey(item){
    localStorage.setItem('key',item)
  }

  function SetMergeJob(item) {
    let array = MergeJob;
    let finding = false;
    let itemkey = null;

    array.map((obj, key) =>{
      if(obj.id === item.id){
        finding = true;
        itemkey = key;
      }
    });

    if(finding){
      array.splice(itemkey, 1);
      setMergeJob(array);
      console.log(MergeJob);
    }else{
      array.push(item);
      setMergeJob(array);
      console.log(MergeJob);
    }
  }
  

  async function getCustomers(){
    const params = {
      type: ['บุคคลธรรมดา','นิติบุคคล'],
    }
    try {
      const response = await dispatch(Customer(params));
      
      if(response){
        const data = response.data.data
        setCustomers(data);
      }else{
        console.log('failed')
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }

  const handleProduct = () => {
    setProduct(true);
    setService(false);
    setPackage(false);
    GetProducts();
  };
  const handleService = () => {
    setProduct(false);
    setService(true);
    setPackage(false);
    GetServices();
  };
  const handlePackage = () => {
    setProduct(false);
    setService(false);
    setPackage(true);
    GetPackageType();
  };

  const optionTax = [
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
  ];

  const optionVat = [
    { value: 1, label: '1%' },
    { value: 3, label: '3%' },
    { value: 7, label: '7%' },
  ];
  const optionStatusJob = [
    { value: 'pending_approved', label: 'รออนุมัติ',icon: <img src={PenddingApproved} className="icon-job" /> },
    { value: 'pending', label: 'รอดำเนินการ',icon: <img src={Pending} className="icon-job"/> },
    { value: 'approved', label: 'ดำเนินการ',icon: <img src={Approved} className="icon-job"/> },
    { value: 'rejected', label: 'ยกเลิก',icon: <img src={Rejected} className="icon-job"/> },
    { value: 'done', label: 'เสร็จสิ้น',icon: <img src={Done} className="icon-job"/> },
  ];
  const optionStatusJobIfPaid = [
    { value: 'pending_approved', label: 'รออนุมัติ',icon: <img src={PenddingApproved} className="icon-job" /> },
    { value: 'pending', label: 'รอดำเนินการ',icon: <img src={Pending} className="icon-job"/> },
    { value: 'approved', label: 'ดำเนินการ',icon: <img src={Approved} className="icon-job"/> },
    { value: 'done', label: 'เสร็จสิ้น',icon: <img src={Done} className="icon-job"/> },
  ];

  const optionHour = [
    { value: '01', label: '01' },
  ];
  const optionMinute = [
    { value: '01', label: '01' },
  ];
  function SetHour(data) {
    setHour({ value: data.value, label: data.value });
  }
  function Setminut(data) {
    setMinute({ value: data.value, label: data.value });
  }

  function SetVat(data) {
    // setTax({ value: data.value, label: data.label });
    setData({...Data, Vat: {value: data.value, label: data.label} });
  }
  function SetTax(data) {
    // setTax({ value: data.value, label: data.label });
    setData({...Data, Tax: {value: data.value, label: data.label} });
  }
  function SetStatusJob(data) {
    setData({...Data, StatusJob: { value: data.value, label: data.label} });
  }

  // Uploadimage
  function uploadSingleFileBefore(e) {
    // set upload file
    const files = [...Data.BeforeUploadFile];
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      files.push(item)
    }
    //set display
    const url_image = [...Data.ImagesDisplayBF]
    const newfilter = url_image.filter( item => {
      return item.status !== 'new'
    });

    let addNew = []
    for (let index = 0; index < files.length; index++) {
      const item = files[index]
      const url = URL.createObjectURL(item)
      newfilter.push({
        image:url,
        id: null,
        status:'new',
        key: (Data.ImagesDisplayBF.slice(-1).length > 0 ? Data.ImagesDisplayBF.slice(-1)[0].key : 0) + 1
      })
     addNew.push(item);
    }
    setData({ ...Data, BeforeUploadFile: addNew,
      ImagesDisplayBF: newfilter 
    });
  }
  function deleteFileBefore(item) {
   
    switch (item.status) {
      case 'new':

          const getItem = Data.BeforeUploadFile.find(
            file => file.key === item.key
          );
          
      // 
          setData({ ...Data, BeforeUploadFile: Data.BeforeUploadFile.filter(function(upload, i) { 
              return upload.key !== getItem.key
            }),
            ImagesDisplayBF: Data.ImagesDisplayBF.filter(function(display, key) { 
              return display.key !== getItem.key
            })
          })
        break;
      case 'old':
        const getRemove = Data.ImagesDisplayBF.find(
          file => file.id === item.id
        );

        const remove = Data.BeforeUploadFileRemove ? Data.BeforeUploadFileRemove : []
        remove.push(Number(getRemove.id))
        setData({ ...Data, 
          ImagesDisplayBF: Data.ImagesDisplayBF.filter(function(item) { 
            return item.key !== getRemove.key
          }),
          BeforeUploadFileRemove: remove,
        })

      break;
      default:
        break;
    }

  }
  function uploadSingleFileAfter(e) {

    // set upload file
    const files = [...Data.AfterUploadFile];
    for (let index = 0; index < e.target.files.length; index++) {
      const item = e.target.files[index];
      files.push(item)
    }
    //set display
    const url_image = [...Data.ImagesDisplayAF]
    const newfilter = url_image.filter( item => {
      return item.status !== 'new'
    });
     
    let addNew = []
      for (let index = 0; index < files.length; index++) {
        const item = files[index]
        const url = URL.createObjectURL(item)
        newfilter.push({
          image:url,
          id: null,
          status:'new',
          key: (Data.ImagesDisplayAF.slice(-1).length > 0 ? Data.ImagesDisplayAF.slice(-1)[0].key : 0) + 1
        })
        addNew.push(item);
      }


      setData({ ...Data, AfterUploadFile: addNew,
        ImagesDisplayAF: newfilter 
      });
  }

  function deleteFileAfter(item)  {
   
    switch (item.status) {
      case 'new':

          const getItem = Data.AfterUploadFile.find(
            file => file.key === item.key
          );
          
      // 
          setData({ ...Data, AfterUploadFile: Data.AfterUploadFile.filter(function(upload, i) { 
              return upload.key !== getItem.key
            }),
            AfterUploadFile: Data.AfterUploadFile.filter(function(display, key) { 
              return display.key !== getItem.key
            })
          })
        break;
      case 'old':
        const getRemove = Data.AfterUploadFile.find(
          file => file.id === item.id
        );

        const remove = Data.AfterUploadFileRemove ? Data.AfterUploadFileRemove : []
        remove.push(Number(getRemove.id))
        setData({ ...Data, 
          AfterUploadFile: Data.AfterUploadFile.filter(function(item) { 
            return item.key !== getRemove.key
          }),
          AfterUploadFileRemove: remove,
        })

      break;
      default:
        break;
    }

  }
  /////////////////Modal/////////////////////
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const close = () => {
    setShow(false);
  };

  const [showPackage, setShowPackage] = useState(false);

  const handleClosePackage = () => setShowPackage(false);
  const handleShowPackage = () => setShowPackage(true);
  const closePackage = () => {
    setShowPackage(false);
  };
  ///
  ////////////////////////////////////////////

  ////////////////// product /////////////////

 


  const StorageModelProduct = {
    key: 0,
    PackageId: null,
    PackageCode: null,
    PackageName: null,
    Quantity: 1,
    UnitId: null, 
    Price: '',
    Discount: '',
    form: 'product',
    Status: 'new',
    service_now: true,
    total_quantity: null,
  }

  const StorageModelPackage = {
    key: 0,
    PackageId: null,
    PackageCode: null,
    PackageName: null,
    PackageTypeId: null,
    PackageTypeName: null,
    form: 'package',
    Status: 'new',
    Quantity: 1,
    Price: '',
    Discount: '',
    type: null,
    film_thickness: null,
    coating_point: null,
    check_setting: null,
    treatment: null,
    keep_working: null,
    renewal_claim: null,
    continuous_service: null,
    service_now: false,
    claim_renewal: null,
    spray_coating: null,
    discount_type : null,
    discount_calculate: null,
    total_quantity: null,
  }

  const StorageModelService = {
    key: 0,
    PackageId: null,
    PackageCode: null,
    PackageName: null,
    Quantity: 1,
    Price: '',
    form: 'service',
    Status: 'new',
    Discount: '',
    service_now: true,
    total_quantity: null,
  }

  const [printType, setPrintType] = useState([]);
  const [reRender, setReRender] = useState(false);

  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const OnlyShop = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ช่างshop
      </Button>
    )
  }, []);

  const OnlyAdminOfficer = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ธุรการ
      </Button>
    )
  }, []);

  const OnlyCustomer = React.useCallback(() => {
    return (
      <Button type="button" className="button">
        ลูกค้า
      </Button>
    )
  }, []);

  const AllPrint = React.useCallback(() => {
    return (
      <a href="#" target="_blank">
      <Button type="button" className="button">
        ทั้งหมด
      </Button>
      </a>
    )
  }, []);
  
  const printList = [
    'ช่างshop',
    'ธุรการ',
    'ลูกค้า',
  ]

  const handleAfterPrint = React.useCallback(() => {
    setReRender(false)
  }, []);

  const handleBeforePrint = React.useCallback(() => {
  }, []);

  const [waiting, setWaiting] = useState(false);

  const handleOnBeforeGetContentShop = React.useCallback(() => {
    setPrintType(['ช่างshop'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentAdminOfficer = React.useCallback(() => {
    setPrintType(['ธุรการ'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentCustomer = React.useCallback(() => {
    setPrintType(['ลูกค้า'])
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handleOnBeforeGetContentAll = React.useCallback(() => {
    setPrintType(printList)
    setReRender(true)
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  function SetProductBox(item) {

      console.log(1);
      let insert = StorageModelProduct
      insert.key = (Data.StorageList.slice(-1).length > 0 ? Data.StorageList.slice(-1)[0].key : 0) + 1
      insert.id = null
      insert.PackageId = item.id
      insert.PackageCode = item.code
      insert.PackageName= item.name
      insert.total_quantity = item.total_quantity ? item.total_quantity : null
      insert.UnitId = item.unit && item.unit.id
      insert.UnitName = item.product_unit_sales && item.product_unit_sales.unit_sale && item.product_unit_sales.unit_sale.name_sale
      insert.SpecialType = item.special_type ? item.special_type : 'normal'
      insert.form = 'product'
      insert.Price = item.product_unit_sales && item.product_unit_sales.price ? item.product_unit_sales.price : ''
      insert.Status = 'new'

      if(JobState.status !== 'done'){
        setData({...Data, StorageList:[...Data.StorageList, insert]})
      }
      

  }


  const [sPrice, setsPrice] = useState(null);
  const [sDiscount, setsDiscount] = useState(null);
  const [sCost, setsCost] = useState(null);
  const [sCommission, setsCommission] = useState(null);
  const [sDifferent, setsDifferent] = useState(null);
  const [sTotal, setsTotal] = useState(null)
  const [Trigger, setTrigger] = useState(false)
  const [PreTrigger, setPreTrigger] = useState(true)

  function modifileShowroom(name, val){
    if(name == "price"){setTrigger(false);setsPrice(val)}
    if(name == "discount"){setTrigger(false);setsDiscount(val)}
    if(name == "cost"){setTrigger(false);setsCost(val)}

    if(name == "comission"){
      setTrigger(true)
      setsCommission(val)
      // upComiss(true)
    }
    if(name == "preprice"){
      setPreTrigger(true)
      setPrePrice(val)
      // upComiss(true)
    }

    getPrePrice();

    // setsDifferent(Number(prePrice) - Number(sCost));
    // console.log(sPrice)
  }


  function upComiss(check) {
    if(!check){
      // getPrePrice(true);
      let result = Number(sPrice) - (Number(prePrice) - Number(sCost));
      setsCommission(result)
    }

    // let total = Number(sPrice) - Number(sDiscount);
    // setsTotal(total)

    // setsDifferent(Number(prePrice) - Number(sCost));

  }

  const [updateCommission, setupdateCommission] = useState(0);

  const [prePrice, setPrePrice] = useState(0);
  const getPrePrice = (check = false) => {
    
    // console.log(Data.StorageList)
    if(Data.StorageList.length > 0){
      let total = 0;
      Data.StorageList.map(item => {
        let result = item.Quantity * item.Total;
        total = total + result;
      });
      if(!PreTrigger){
        setPrePrice(total);
      }
    }else{
      if(!PreTrigger){
        setPrePrice(0);
      }
    }

    if(!Trigger){
      let result = Number(sPrice) - (Number(prePrice) - Number(sCost));
      setsCommission(result)
      console.log('Commission', result)
    }

    let total = Number(sPrice) - Number(sDiscount);
    setsTotal(total)

    setsDifferent(Number(prePrice) - Number(sCost));


  };
  ////////////////// service /////////////////

  const optionServiceBox = [
    { value: 'บริการล้างและเคลือบแว๊กซ์', label: 'บริการล้างและเคลือบแว๊กซ์' },
    { value: 'บริการฟิล์มกรองแสง', label: 'บริการฟิล์มกรองแสง' },
    { value: 'บริการเคลือบเหมา', label: 'บริการเคลือบเหมา' },
    { value: 'บริการเคลือบเฉพาะจุด', label: 'บริการเคลือบเฉพาะจุด' },
    { value: 'บริการฟิล์มกันรอย', label: 'บริการฟิล์มกันรอย' },
    { value: 'แพ็คเกจพิเศษ', label: 'แพ็คเกจพิเศษ' },
  ];
  function SetServiceBox(item) {
    console.log(2);
    // console.log('item',item);
    const car_owner_size = CarOwner?.car_serie?.size
    let insert = StorageModelService
    insert.key = (Data.StorageList.slice(-1).length > 0 ? Data.StorageList.slice(-1)[0].key : 0) + 1
    insert.id = null
    insert.PackageId = item.id
    insert.Status = 'new'
    insert.PackageCode = item.code
    insert.PackageName= item.name
    insert.total_quantity = item.total_quantity ? item.total_quantity : null
    insert.form = 'service'
    let result = 0
    if(item.sale_type === 'size'){
      result = item.service_sale_type?.find((i) => {
        return i.size === car_owner_size
      })
    }
    insert.Price = item.sale_type === 'all' ? item.all_price : result.amount
    setData({...Data, StorageList:[...Data.StorageList, insert]})
  }
  
  function SetTaxActive(data){
    setData({...Data, TaxActive: data.target.checked})
    if(data.target.checked === false){
      setData({...Data, TaxActive: data.target.checked,Tax: {
        value: 0,
        label: '0%'
      }})
    }else if(data.target.checked === true){
      setData({...Data, TaxActive: data.target.checked, Tax: {
        value: 3,
        label: '3%'
      } })
    }
  }

  function SetVatActive(data){
    setData({...Data, VatActive: data.target.checked})
    if(data.target.checked === false){
      setData({...Data, VatActive: data.target.checked,Vat: {
        value: 0,
        label: '0%'
      }})
    }else if(data.target.checked === true){
      setData({...Data, VatActive: data.target.checked, Vat: {
        value: 7,
        label: '7%'
      } })
    }
  }
  ////////////////// package /////////////////
  const [typepackage, setTypePackage] = useState([{ value: '', label: '' }]);
  const [packageSelect,setPackageSelect] = useState([{ value: '', label: '' }]);
  const [PointCoatings, setPointCoatings] = useState(null);
  const [PointCoatingPackage,setPointCoatingPackage] = useState([]);

  const [serviceMore, setServiceMore] = useState(false);
  const [serviceNow, setServiceNow] = useState(false);

  const TypeWorkList= [{id: 'ติดตั้งใหม่', name: 'ติดตั้งใหม่' },{id: 'ลอกอันเก่าและติดใหม่', name: 'ลอกอันเก่าและติดใหม่'}];

  const optionService = [
    { id: 'ติดตั้งใหม่', name: 'ติดตั้งใหม่' },
    {
      id: 'ลอกติดตั้ง',
      name: 'ลอกติดตั้ง',
    },
  ];

  const optionPosition = [
    {value: 'around_car', label: 'รอบคัน' },
    {value: 'car_head',label: 'หัวรถ'},
    {value: 'handle_hole,',label: 'หลุมมือจับ'},
    {value: 'headlight_set',label: 'ชุดไฟหน้า'},
    {value: 'tail_light_set',label: 'ชุดไฟท้าย'},
    {value: 'set_of_4_stairs',label: 'ชุดบันได 4 จุด'},
    {value: 'front_bumper',label: 'กันชนหน้า'},
    {value: 'bonnet',label: 'ฝากระโปรง'},
    {value: 'front_fender_L+R,',label: 'บังโคลนหน้า L+R'},
    {value: 'side_mirror_L+R',label: 'กระจกมองข้าง L+R'},
    {value: 'front',label: 'บานหน้า'},
  ];

  const [DefectCar,setDefectCar] = useState(false);
  const [ThingsInCar,setThingsInCar] = useState(false);
  const [PositionUse,setPositionUse] = useState([])
  const [StatusToggle,setStatusToggle] = useState(false)

  const handleThingsInCar = () => {
    setPayerForm({...PayerForm, ThingsInCar : !PayerForm.ThingsInCar});

    
  };

  function SetPosition(data){
    // SetPosition({ value: data.value, label: data.label})
    setPositionUse(data);
  }

  const handleDefectCar = () => {
    // setDefectCar(!DefectCar);
    setPayerForm({...PayerForm, DefectCar : !PayerForm.DefectCar});
  };


  const handleFastService = () => {
    setServiceNow(!serviceNow);
  };

  function SetPackage(data) {
    setTypePackage({ value: data.id ,label: data.name, data});
    setPackages(data.packages ? data.packages : null);
  }

  function resetSelectSpecialPackage(package_name){
    const reset = [{name: '', data: ''}];
      setWashPart(reset);
      setLightfilter(reset);
      setCoatingService(reset);
      setProtective(reset);
      setServiceFilter(reset);
      setPointCoatingPackage([]);
  }

  function SetSelectPackage(data){
  //  console.log('SetSelectPackage',data.package_type.name);
    setPackageSelect({value: data.id, label: data.name,data});
    if(data.package_type.id !== 6){
        switch (data.package_type.name) {
          case 'บริการล้างและเคลือบแว๊กซ์':
              // setWashPart({name: item.package.package_type.name, data: item})
            break;
          case 'บริการฟิล์มกรองแสง':
            // setLightfilter({name: item.package.package_type.name, data: item})
            getSeriesFilm(data.id);
            
            break;
          case 'บริการเคลือบเหมา':
            // setCoatingService({name: item.package.package_type.name, data: item})
            break;
          case 'บริการเคลือบเฉพาะจุด':
            // setProtective({name: item.package.package_type.name, data: item})
            break;
          case 'บริการฟิล์มกันรอย':
            getSeriesFilm(data.id);
            // setServiceFilter({name: item.package.package_type.name, data: item})
            // setPointCoatingPackage(item.package?.product_quantities ? item.package.product_quantities : []);
            break;
        
          default:
            break;
        }
    }
    // getSeriesFilm(item.id);
    resetSelectSpecialPackage();
    if(data.special_packages.length > 0){
      data.special_packages?.map((item) => {
        switch (item.package.package_type.name) {
          case 'บริการล้างและเคลือบแว๊กซ์':
              setWashPart({name: item.package.package_type.name, data: item})
            break;
          case 'บริการฟิล์มกรองแสง':
            setLightfilter({name: item.package.package_type.name, data: item})
            
            break;
          case 'บริการเคลือบเหมา':
            setCoatingService({name: item.package.package_type.name, data: item})
            break;
          case 'บริการเคลือบเฉพาะจุด':
            setProtective({name: item.package.package_type.name, data: item})
            break;
          case 'บริการฟิล์มกันรอย':
            setServiceFilter({name: item.package.package_type.name, data: item})
            setPointCoatingPackage(item.package?.product_quantities ? item.package.product_quantities : []);
            break;
        
          default:
            break;
        }
      });
    }
    
    
  }



  function SetTypeCoating(item) {
    console.log('SetTypeCoating',item);
    // setPackageSelect({value: item.id, label: item.name,item});
    SetSelectPackage(item)
    setPointCoatingPackage(item.product_quantities? item.product_quantities : []);
  }
  function SetPointCoating(item) {
    // console.log('SetPointCoating',item);
    setPointCoatings(item);
  }

  function SetSwitch(data) {
    setStatusToggle(data);
    // clear ค่า เลือกตำแหน่ง 
    if(data === false){
      setPositionUse([])
    }
  }

  function AddPackageToStorageList(){
    console.log(3);
    // console.log('packageSelect',packageSelect.data.id);
    if(packageSelect.data && packageSelect.data.id){
      if(packageSelect.data.category === "ธรรมดา"){
          let insert = StorageModelPackage
          insert.key = (Data.StorageList.slice(-1).length > 0 ? Data.StorageList.slice(-1)[0].key : 0) + 1
          insert.id = null
          insert.PackageId = packageSelect.data.id
          insert.PackageCode = packageSelect.data.code
          insert.PackageName = packageSelect.data.name
          insert.PackageTypeId = typepackage.value
          insert.total_quantity = packageSelect.data.total_quantity ? packageSelect.data.total_quantity : null
          insert.PackageTypeName = typepackage.label
          insert.film_thickness = typepackage.label === 'บริการฟิล์มกรองแสง' ? filmThickness.value : null
          insert.FilmModel = FilmModel ? FilmModel.value : null
          insert.modelNumber = modelNumber ? modelNumber.value : null
          insert.type = typepackage.label === 'บริการฟิล์มกรองแสง' || typepackage.label === 'บริการฟิล์มกันรอย' ? TypeWork.value : null
          insert.form = 'package'
          insert.coating_point = typepackage.label === "บริการเคลือบเฉพาะจุด" ? PointCoatings : null
          insert.PositionUse = typepackage.label === 'บริการฟิล์มกันรอย' || typepackage.label === 'บริการเคลือบเฉพาะจุด' ? PositionUse : null
          insert.TextDetails = typepackage.label === 'บริการฟิล์มกรองแสง' || typepackage.label === 'บริการเคลือบเฉพาะจุด' ? TextDetails : null
          const CarSize = JobState.car_size
          const MiddleSize = packageSelect.data.package && packageSelect.data.middle_sizes
          // แก้ไขชัวคราว
          let resultCarSize = []
          if(MiddleSize && typepackage.label !== 'บริการเคลือบเฉพาะจุด'){
            resultCarSize = MiddleSize.find((item) => {
              return item.size === CarSize
            }) 
          }else{
            
          }
          insert.Price = resultCarSize.price ? resultCarSize.price : ''
          insert.Status = 'new'
          insert.service_now = serviceNow
        setData({...Data, StorageList:[...Data.StorageList, insert]})

        resetSelect()
      }else{
        //! Special Package
          let insert = StorageModelPackage
          insert.key = (Data.StorageList.slice(-1).length > 0 ? Data.StorageList.slice(-1)[0].key : 0) + 1
          insert.id = null
          // console.log('packageSelect',packageSelect.data.id);
          insert.PackageId = packageSelect.data.id
          insert.total_quantity = packageSelect.data.total_quantity ? packageSelect.data.total_quantity : null
          insert.PackageCode = packageSelect.data.code
          insert.PackageName = packageSelect.data.name
          insert.PackageTypeId = typepackage.value
          insert.PackageTypeName = typepackage.label
          insert.film_thickness = typepackage.label === 'บริการฟิล์มกรองแสง' ? filmThickness.value : null
          insert.type = typepackage.label === 'บริการฟิล์มกรองแสง' || typepackage.label === 'บริการฟิล์มกันรอย' ? TypeWork.value : null
          insert.form = 'package'
          insert.coating_point = typepackage.label === "บริการเคลือบเฉพาะจุด" ? PointCoatings : null

          const CarSize = JobState.car_size
          const MiddleSize = packageSelect.data.package && packageSelect.data.middle_sizes
          // แก้ไขชัวคราว
          let resultCarSize = []
          if(MiddleSize && typepackage.label !== 'บริการเคลือบเฉพาะจุด'){
            resultCarSize = MiddleSize.find((item) => {
              return item.size === CarSize
            }) 
          }else{
            console.log('MiddleSize',packageSelect.data.package);
          }
          insert.Price = resultCarSize.price ? resultCarSize.price : ''
          insert.Status = 'new'
          insert.service_now = null
        
          const SpecialPackages = []
          const special_package_list = packageSelect.data.special_packages
          special_package_list.map((item) => {

          item.FilmThickness = null
          SpecialPackages.push(item)

          });
          insert.SpecialPackages = SpecialPackages
          //! setData
          setData({...Data, StorageList:[...Data.StorageList, insert]})
      }
    }else{
      alert('เลือกรายการ')
    }
   
  }

  function resetSelect(){
    setTypePackage({ value: '' ,label: ''});
    setPackages(null);
    setPackageSelect({value: '', label: ''});
    setServiceNow(false)
    setTypeWork({value: '', label: ''});
    setPointCoatings(null);
  }

  function LinkTo(url){
    history.push(url)
  }

  async function SetPayerForm(data){
    // console.log('data',data);
    setPayerForm({
      ...PayerForm , 
        payer_id: data.id,
        customer_code: data.customer_code,
        customer: JobState.customer,
        fullname: data ? data.first_name === null && data.last_name === null ? data.organization !== null ? data.organization : '' : `${data.first_name} ${data.last_name} (${data.nickname})ฟหกฟหก` : null,
        address: data && data.address,
        phone: data && data.phone ? data.phone : [],
    })
  }

  async function GetProducts(){
    try {
      const response = await dispatch(ProductList({ has_sales: true }));
      if (response) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }
  }
  async function GetServices(){
    // const params = {
    //   service_product: true,
    // }
    try {
      const response = await dispatch(LoadServices());
      const service = response.data.data
      setServices(service);
    }  catch (error) {
      console.error(error);
    } finally {

    }
  }
  async function GetPackages(type){
    try {
      const params = {
        type
      }
      const response = await dispatch(PackageList(params))
      if (response) {
        setPackages(response.data.data)
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally')
    }

  }

  const GetVoucher = async (data) => {
    // setisLoading(true);
    const params = {
      limit: 999999999,
      page: 1,
      load: 'can_used',
    };
    try {
      const response = await dispatch(VoucherList(params));
      if (response) {
        const vouchers = response.data.data.data.map((obj) => ({
          ...obj,
          isEdit: false,
        }));
        setVouchers(vouchers);
        // setSegments(segments);
        // setRows(response.data.data.last_page);
        // setisLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log('finally');
    }
  };

  async function GetPackageType(){
    setLoading(true)
      try {
        const response = await dispatch(PackageTypeList());
        setPackageTypes(response.data.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  const removePackageList = (item) => {
    let find = null;

    find = Data.StorageList.filter(i => i.key !== item.key);

    setData({ ...Data,
      StorageList : find,
    })

    if(Data.StorageList < 1){
      setValidated(true)
    }
  } 

  function UpdateService(data,item) {
    let insert = []
        switch (item.package.package_type.name) {
          case 'บริการล้างและเคลือบแว๊กซ์':
            insert = item
              setWashPart({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการฟิล์มกรองแสง':
            insert = item
            insert.typework = data.value
            setLightfilter({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการเคลือบเหมา':
            insert = item
            setCoatingService({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการเคลือบเฉพาะจุด':
            insert = item
            setProtective({name: item.package.package_type.name, data: insert})
            break;
          case 'บริการฟิล์มกันรอย':
            insert = item
            insert.typework = data.value
            setServiceFilter({name: item.package.package_type.name, data: insert})
            setPointCoatingPackage(item.package?.product_quantities ? item.package.product_quantities : []);
            break;
        
          default:
            break;
        }
  }

  async function goMergeJob(){
    if(MergeJob.length > 0 && MergeJobPrice >= 0){
      const mergedata = {
        products: MergeJob,
        price: MergeJobPrice,
      }
      const response = await dispatch(MergeJobPackage(mergedata));
      if (response.data.message === 'success') {
        console.log(JobId);
        window.location.reload(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(Data)
    // return null;

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    // console.log('Data',Data.StorageList);
    let formData = new FormData();
    formData.append('job_id', JobId);
    formData.append('customer_id', Data.CustomerId)
    formData.append('car_owner_id', Data.CarOwnerId)
    formData.append('payer_id_by', PayerForm.payer_id)
    for (let i = 0; i < Data.StorageList.length; i++) {
      formData.append(`package_list[${i}]`, JSON.stringify(Data.StorageList[i]));
    }
    for (let i = 0; i < Data.StorageListAppointment.length; i++) {
      formData.append(`package_list_appointment[${i}]`, JSON.stringify(Data.StorageListAppointment[i]));
    }
    if(Data.BeforeUploadFile.length > 0) {
      let i = 0 ;
      while (i < Data.BeforeUploadFile.length){
        formData.append(`file_before[]`, Data.BeforeUploadFile[i]);
        i++;
      }
    }
    if(Data.BeforeUploadFileRemove) formData.append('file_before_remove',JSON.stringify(Data.BeforeUploadFileRemove));
    
    if(Data.AfterUploadFile.length > 0) {
      let i = 0 ;
      while (i < Data.AfterUploadFile.length){
        formData.append(`file_after[]`, Data.AfterUploadFile[i]);
        i++;
      }
    }
    if(Data.AfterUploadFileRemove) formData.append('file_after_remove',JSON.stringify(Data.AfterUploadFileRemove));
    
    formData.append('discount_list_type', Discount.value);
    formData.append('discount_total', Data.DiscountTotal ? Data.DiscountTotal : 0);
    formData.append('voucher_discount', Data.VoucherDiscount ? Data.VoucherDiscount : 0);
    formData.append('voucher_id', Data.VoucherId ? Data.VoucherId : null);
    formData.append('discount_total', Data.DiscountTotal ? Data.DiscountTotal : 0);
    formData.append('discount_total_calculate', Data.DiscountTotalCalculate ? Data.DiscountTotalCalculate : 0);
    formData.append('discount_type', Data.DiscountType.value);
    formData.append('sum_total', numeral(Data.SumTotal).format('0.00'))
    formData.append('sum_total_after_vat', numeral(Data.SumTotalAfterVat).format('0.00'))
    if(PayerForm.Note) formData.append('note', PayerForm.Note);
    if(PayerForm.InsiteNote) formData.append('insite_note', PayerForm.InsiteNote);
    if(PayerForm.ThingsInCar) formData.append('things_in_car', PayerForm.ThingsInCar);
    if(PayerForm.DefectCar) formData.append('defect_car', PayerForm.DefectCar);
    if(PayerForm.Area) formData.append('area', PayerForm.Area);
    formData.append('tax', Data.Tax.value);
    formData.append('tax_active', Data.TaxActive);
    formData.append('tax_calculate', numeral(Data.TaxCalculate).format('0.00'));
    formData.append('vat', Data.Vat.value);
    formData.append('vat_active', Data.VatActive);
    formData.append('vat_calculate', numeral(Data.VatCalculate).format('0.00'));
    formData.append('status',Data.StatusJob.value);

    if(Number(sPrice))formData.append('showroom_price', sPrice)
    if(Number(prePrice))formData.append('showroom_preprice', prePrice)
    if(Number(sDiscount))formData.append('showroom_discount', sDiscount)
    if(Number(sCost) > 0)formData.append('showroom_cost', sCost)
    if(Number(sCommission) > 0)formData.append('showroom_comission', sCommission)
    if(Number(sTotal) > 0)formData.append('showroom_total', sTotal)

    if(Data.OrderText) formData.append('order_text', Data.OrderText);
    if(Data.OrderPay) formData.append('order_pay', Data.OrderPay);
    formData.append('_method', 'put');
    let id = JobId
    try {
      const response = await dispatch(UpdateJob({ id, formData }));

      if (response.data.message === 'success') {
        console.log('Update', response);
        // history.push('/job');
        alert('อัพเดทข้อมูลเสร็จสิ้น')
        history.push('/job/order/'+JobId);
      } else if(response.data.message === 'error'){
        alert(response.data.data)
      }else if(response.data.message === 'request_import_stock'){
          const data = response.data.data
          const result = []
          data?.map((item) => {
            result.push({
              id: item.id,
              name: item.name,
              less : item.total_request_stock, //รายงานจำนวน สินค้าที่ขาดใน stock
              form : item.form,
            })
          })
          setAlert(result);
      } else {
        console.log('failed');
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      console.log('finally');
    }
  };

  function GrandTotalProduct(item){
    const quantity = item.Quantity ? item.Quantity :0
    const discount = item.Discount && item.Discount > 0 ? item.Discount : ''
    const price = item.Price ? item.Price : 0
    const price_total = price * quantity
    
    let result =[]
    let resultDiscount =[]
    switch (Discount.value) {
      case 'percent':
        resultDiscount = discount > 0 ? (price_total / 100) * discount  : 0
        result = price_total - resultDiscount      
        break;
      case 'baht':
        resultDiscount = discount
        result = price_total - resultDiscount
          break;
      default:
        break;
    }
    item.Total = price_total
    item.GrandTotal = result
    item.DiscountType = Discount.value
    item.discount_calculate = resultDiscount
    return numeral(result).format('0,0.00')
  }

  function SumTotal(Data){
    const sum = Data.StorageList.reduce((n, {GrandTotal}) => n + GrandTotal, 0)
    Data.SumTotal = sum
    return numeral(sum).format('0,0.00');
  }
  function DisplayDiscountTotalCalculate(Data){
    const discount = Data.DiscountTotal && Data.DiscountTotal > 0 ? Data.DiscountTotal : ''
    const sum_total = Data.SumTotal
    let resultDiscount = []
    if(Data.DiscountType.value === 'percent'){
      resultDiscount = discount > 0 ? (sum_total / 100) * discount  : 0  
    }else if(Data.DiscountType.value === 'baht'){
      resultDiscount = discount
    }
    Data.DiscountTotalCalculate = resultDiscount
    return numeral(resultDiscount).format('0,0.00')
  }
  function DisplayVoucherDiscountTotalCalculate(Data){
    // let result = 0;
    // if(data.type == "bath"){
    //   result = data.discount;
    // }else{
    //   let sum = Data.StorageList.reduce((n, {GrandTotal}) => n + GrandTotal, 0)
    //   result = Number(sum - Data.DiscountTotalCalculate) * Number(data.discount / 100);
    // }
    
    return numeral(Data.VoucherDiscount).format('0,0.00')
  }
  function funcSumTotalGrand(Data){
    const discount = Data.DiscountTotal && Data.DiscountTotal > 0 ? Data.DiscountTotal : ''
    const sum_total = Data.SumTotal
    let result =[]
    let resultDiscount = []
    if(Data.DiscountType.value === 'percent'){
      resultDiscount = discount > 0 ? (sum_total / 100) * discount  : 0
      result = sum_total - resultDiscount       
    }else if(Data.DiscountType.value === 'baht'){
      resultDiscount = discount
      result = sum_total - resultDiscount
    }
    Data.DiscountTotalCalculate = resultDiscount
    result = result - Data.VoucherDiscount
    Data.SumTotalGrand = result
    return numeral(result).format('0,0.00')
  }
  function DisplayVatCalculate(Data){
    
    let result = []
    if(Data.VatActive === true){
      result = Data.SumTotalGrand - (Data.SumTotalGrand * (100 - Data.Vat.value) / 100)
    }else{
      result = 0
    }
    Data.VatCalculate = result
    return numeral(result).format('0,0.00')
    
  }
  function DisplayTaxCalculate(Data){
    
    let result = []
    if(Data.TaxActive === true){
      result = Data.SumTotalGrand - (Data.SumTotalGrand * (100 - Data.Tax.value) / 100)
    }else{
      result = 0
    }
    Data.TaxCalculate = result
    return numeral(result).format('0,0.00')

  }
  function DisplaySumTotalAfterVat(Data){
    // let result = Data.SumTotalGrand + Data.VatCalculate - Data.TaxCalculate
    let result = JobState.total_price < 1 ? 0 : Data.SumTotalGrand;
    Data.SumTotalAfterVat =  result
    return numeral(result).format('0,0.00')
  }

  function DisplayTaxCalculate(Data){
    
    let result = []
    if(Data.TaxActive === true){
      result = Data.SumTotalGrand - (Data.SumTotalGrand * (100 - Data.Tax.value) / 100)
    }else{
      result = 0
    }
    Data.TaxCalculate = result
    return numeral(result).format('0,0.00')

  }

  function DisplaySumTotalAfterTax(Data){
    let result = Data.SumTotalGrand - Data.TaxCalculate
    Data.SumTotalAfterTax=  result
    return numeral(result).format('0,0.00')
  }

  function SetService(data) {
    setService({ value: data.id, label: data.name, data });
    getSeriesFilm(data.id);
    // console.log('SetService',{ value: data.id, label: data.name, data });
  }

function SetFilmModel(data) {
    // console.log('SetFilmModel',data);
    setFilmModel({ value: data.model, label: data.model, data});
    getFilmListCode(data)
  }
  function SetModelNumber(data) {
    setModelNumber({ value: data.code, label: data.code, data });

  }

  function SetTypeWork(data){
    setTypeWork(data.name)
  }


  function SetFilmThickness(data) {
    setFilmThickness({...filmThickness, value: data.target.value});
  }

  function SetTextDetails(data) {
    setTextDetails(data.target.value);
  }

  function arrImage(data){
    const arr = []
    data.map( (i,key) => {
      arr.push({
        image:i.image,
        status: 'old',
        key: key, 
        id:i.id
      })
    })
    return arr
  }

  function checkSpotCoating(){
    Data.StorageList.find((item) => {
      if(item.PackageTypeName === "บริการเคลือบเฉพาะจุด"){
        setDisplayButtonOrder(true)
      }else{
        setDisplayButtonOrder(false);
      }
    });
  }

  function getTextByPackage(item){
    let set_text = ''
    switch (item.PackageTypeName) {
      case 'บริการล้างและเคลือบแว๊กซ์':
        return ''
      case 'บริการฟิล์มกรองแสง':
        set_text =  `${item.type ? item.type : ''} ${ item.FilmModel !== null ? item.FilmModel : ''}-${item.modelNumber !== null ? item.modelNumber : ''}  `
        return set_text
      case 'บริการเคลือบเหมา':
        return ''
      case 'บริการเคลือบเฉพาะจุด':
        return ''
      case 'บริการฟิล์มกันรอย':
        set_text =  `${ item.FilmModel !== null ? item.FilmModel : ''} ${item.modelNumber !== null ? item.modelNumber : ''}`
        return set_text
      case 'แพ็คเกจพิเศษ':
        return ''
    }
  }

  function  getDisplayTextPositionCoating(item) {
    // console.log('item',item);
    if(item.Status ==='new' && item?.PositionUse?.length < 1 && item?.PointCoatings?.length > 0){
      // console.log(' item?.PointCoatings', item?.PointCoatings);
      const result = item?.coating_point.map((item) => {
        return item.size 
      }).join(", ")
  
      return result ? 'บริเวณ :' + result : ''
    }else{
      return ''
    }
  }

  function getDisplayTextPositionUse(item){
    if(item.Status ==='new' &&  item?.PositionUse !== null && item?.coating_point === null && item.PackageTypeId !== 6){
      const result = item?.PositionUse.map((item) => {
        return item.label 
      }).join(", ")
  
      return result ? 'บริเวณ : ' + result : ''
    }else{
      return ''
    }
    
  }

  async function getSeriesFilm(PackageId){
    // console.log('getSeriesFilm',PackageId);
    setLoading(true)
    try {
      const response = await dispatch(GetFilmModel(PackageId));
      const packages_size = response.data.data
      setPackageSizeModel(packages_size);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }

  async function getFilmListCode(data){
    // console.log('getFilmListCode',data);
    setLoading(true)
    try {
      const params = {
        package_id: data.package_id,
        model: data.model,
      }
      const response = await dispatch(GetFilmSizeCodeByModel(params));
      const package_size_code = response.data.data
      setPackageSizeCode(package_size_code);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    } finally {
      console.log('finally')
    }
  }



  useEffect(() => {
    checkSpotCoating()
    // upComiss()
    getPrePrice();

  })

  useEffect(() => {

    getAuthShowRoomCanEdit();

    setsPrice(Number(job.total));
    setsDiscount(Number(job.discount_calculate));
    setsCost(job.showroom_cost !== null ? Number(job.showroom_cost.total) : 0);
    setPrePrice(job.pre_price !== null ? Number(job.pre_price) : 0);
    setsCommission(Number(job?.com_mission?.total))
    setsTotal(Number(sPrice) - Number(sDiscount))
    setsDifferent(Number(prePrice) - Number(sCost));
    // console.log('LoadComm', sCommission);
    // setsCommission(Number(job.total) - (Number(prePrice) - Number(job.showroom_cost.total)));
    // upComiss(false);
  

    GetProducts()
    GetVoucher()
    loadThisPage()


    },[PayerState,JobState,JobState.payer_by_id]);

    function loadThisPage() {
      if (Discount.value === 'percent') {
        Setview_placeholder('%');
      } else if (Discount.value === 'baht') {
        Setview_placeholder('บาท');
      } 
      let findTax= { value: 0,label: '0%'}
      let findVat= { value: 0,label: '0%'}

      let tax_active = false
      let vat_active = false

      if(numeral(JobState.tax).value() > 0){
        findTax = optionTax.find((item) => {
          return item.value === numeral(JobState.tax).value()
        })
        tax_active = numeral(JobState.tax).value() > 0 ? true : false
      }

      if(numeral(JobState.vat).value() > 0){
        findVat = optionVat.find((item) => {
          return item.value === numeral(JobState.vat).value()
        })
        vat_active = numeral(JobState.vat).value() > 0 ? true : false
      }

      let findJobStatus = { value: 'pending_approved', label: 'รออนุมัติ' }
      if(JobState.status){
        findJobStatus = optionStatusJob.find((item) => {
          return item.value === JobState.status
        })
      }
        
      setPayerForm({
        ...PayerForm ,
          payer_id: JobState.payer_by && JobState.payer_by.id,
          customer_code: JobState.payer_by && JobState.payer_by.customer_code,
          customer: JobState.customer,
          fullname: JobState.payer_by ? JobState.payer_by.first_name === null && JobState.payer_by.last_name === null ? JobState.payer_by.organization !== null ? JobState.payer_by.organization : '' : `${JobState.payer_by.first_name} ${JobState.payer_by.last_name} (${JobState.payer_by.nickname})` : null,
          address: JobState.payer_by && JobState.payer_by.address,
          phone: JobState.payer_by && JobState.payer_by.phone ? JobState.payer_by.phone : [],
          Note:JobState.note, 
          InsiteNote: JobState.insite_note,
          Area: JobState.area,
          ThingsInCar: JobState.things_in_car,
          DefectCar: JobState.defect_car,
      })

      if(JobDetails[0] && JobDetails[0].discount_type){
        let findDiscount = optionDiscount.find((item) => {
          return item.value === JobDetails[0].discount_type
        })
        SetDiscount(findDiscount)
      }
      let findDiscountTypeTotal=[]
      if(JobState.discount_type){
        findDiscountTypeTotal = optionDiscountType.find((item) => {
          return item.value === JobState.discount_type
        })
      }else{
        findDiscountTypeTotal = { value: 'baht', label: 'ส่วนลด(บาท)' }
      }

      let insertArr= []
      JobDetails.map?.((item,index) => {
        if(item.product_id !== null){
          let insert = {
            key: index,
            id: item.id,
            PackageId: item.product.id,
            Status : 'old',
            PackageCode: item.product.code,
            PackageName: item.product.name,
            UnitId : item.product.unit && item.product.unit.id,
            UnitName : item.product.product_unit_sales && item.product.product_unit_sales.unit_sale && item.product.product_unit_sales.unit_sale.name_sale,
            Quantity: item.quantity,
            total_quantity: item.total_quantity ? item.total_quantity : null,
            Price: item.price ? item.price : item.product.product_unit_sales && item.product.product_unit_sales.price ? item.product.product_unit_sales.price : '',
            Discount: item.discount,
            form: 'product',
            service_now: item.service_now ,
            
          }
          insertArr.push(insert)
        }else if(item.service_id !== null){
          const CarSize = JobState.car_size
          const serviceSaleType = item.service.service_sale_type
          const result = serviceSaleType.find((item) => {
            return item.size === CarSize
          })

          let insert = {
            key: index,
            id: item.id,
            PackageId: item.service.id,
            Status : 'old',
            PackageCode: item.service.code,
            PackageName: item.service.name,
            Quantity: item.quantity,
            total_quantity: item.total_quantity ? item.total_quantity : null,
            Price: item.price !== null? item.price : item.sale_type === 'all' ? item.service.all_price : result.amount, 
            Discount: item.discount,
            form: 'service',
            service_now: item.service_now   
          }
          
          insertArr.push(insert)
        }else if(item.package_type_id !== null){
          const CarSize = JobState.car_size
          const MiddleSize = item.package_type.name !== 'บริการเคลือบเฉพาะจุด' ? item.package && item.package.middle_sizes : item.job_coating_point
          // แก้ไขชัวคราว
          let resultCarSize = []
          if(MiddleSize && item.package_type.name !== 'บริการเคลือบเฉพาะจุด'){
            // console.log('MiddleSize',MiddleSize);
            // some time ราคาเหมา  not have
            let check_type_price = MiddleSize.find((item) => { 
              return item.size === 'ราคาเหมา' && item.active === 1 ? true : false
            })

            if(check_type_price === undefined){
              // console.log('by size');
              resultCarSize = MiddleSize.find((item) => {
                return item.size === CarSize
              }) 
            }else{
              // console.log('all size');
              let check_type_price = MiddleSize.find((item) => { 
                return item.size === 'ราคาเหมา' && item.active === 1 
              })
              resultCarSize = check_type_price
            }
          }else{
            
          }

          let formCoatingPoint = []
            if(item.job_coating_point){
              const ContingPoint = item.job_coating_point
              ContingPoint.map((i) => {
                let setForm = {
                  id: i?.package_product.id,
                  package_id: i?.package_product.package_id,
                  product_id: i?.package_product.product_id,
                  quantity: i?.package_product.quantity,
                  size: i?.package_product.size,
                  type: i?.package_product.type,
                  unit_sale_id: i?.package_product.unit_sale_id,
                  created_at: i?.package_product.created_at,
                  updated_at: i?.package_product.updated_at,
                }

                formCoatingPoint.push(setForm)
              });
            }
            let Price = '';
            let job_detail_type = '';
            // ถ้าเป็นนัดหมาย หากไม่ใช่ treatment 
            if(item?.service_appointment_id && item?.service_appointment_id?.total !== null || item?.service_appointment_id?.clean_total !== null && item?.service_appointment_id?.setup === null || item?.service_appointment_id?.setup === true ){
              
              if(item?.service_appointment_id?.treatment === null || item?.service_appointment_id?.treatment === true){
                Price = 0
              }else{
                const middle_sizes = item.package.middle_sizes;
                
                let find = '';
                
                if(item.package_type.name === "บริการเคลือบเฉพาะจุด"){

                }else{
                  find = middle_sizes.find(i => 
                    i.size === CarSize 
                  );
                  Price = find.treatment_price
                }
                
              }
              job_detail_type = 'appointment'
              // console.log( 'appointment');
            }else{
              console.log( 'new'); 
              // created for job 
              Price = item.price ? item.price : resultCarSize.price ? resultCarSize.price : ''
              job_detail_type = 'new'
            }
          // console.log('item.position_details',JSON.parse(item.position_details));
            let insert = {
              key: index,
              id: item.id,
              PackageId: item.package.id,
              Status : 'old',
              PackageCode: item.package.code,
              PackageName: item.package.name,
              PackageTypeId: item.package_type.id,
              PackageTypeName: item.package_type.name,
              Quantity: item.quantity,
              Discount: item.discount,
              total_quantity: item.total_quantity ? item.total_quantity : null,
              form: 'package',
              type: item.type,
              film_thickness: item.film_thickness,
              FilmModel: item.package_sizes_model,
              modelNumber: item.package_sizes_model_code,
              coating_point: formCoatingPoint,
              service_now: item.service_now,
              CleanCar : item.clean_car,
              // แก้ไขชัวคราว
              Price:  item.price ? item.price : resultCarSize.price ? resultCarSize.price : 0 ,
              // Price:  Price ,
              PositionUse : item.position_details ? JSON.parse(item.position_details) : '',
              TextDetails : item.text_details ? JSON.parse(item.text_details) : '',
              //set display to do 
            
              todo: item.service_appointment_id !== null ? item.service_appointment_id.use_type : '',
              clean_total: item.service_appointment_id !== null ? item.service_appointment_id.clean_total : '',
              clean_remaining: item.service_appointment_id !== null ? item.service_appointment_id.clean_remaining : '',
              job_detail_type: job_detail_type,
              

            }
            insertArr.push(insert)
        }
        
       
      })
      setData({...Data, 
        StorageList: insertArr, 
        StorageListAppointment: [],
        DiscountType: findDiscountTypeTotal,
        DiscountTotal: JobState.discount,
        TaxActive: tax_active,
        VatActive: vat_active,
        Tax: findTax,
        Vat: findVat,
        StatusJob : findJobStatus,
        VoucherDiscount : JobState.voucher_discount,
        VoucherId : JobState.voucher_id,
        CustomerId : JobState.customer_id,
        CarOwnerId: JobState.car_owner_id,
        BeforeUploadFile: [],
        AfterUploadFile: [],
        ImagesDisplayBF: JobState.job_images_before ? arrImage(JobState.job_images_before) : [],
        ImagesDisplayAF: JobState.job_images_after ? arrImage(JobState.job_images_after) : [],
        OrderText: JobState.order_text,
        OrderPay: JobState.order_pay,
      });

    };

    const [PContent,setPContent] = useState(null)
    const handlePrint2 = useReactToPrint({
      content: () => componentRef.current,
      // onBeforeGetContent: handleOnBeforeGetContentAll,
      // copyStyles: true,
      print: async (printIframe) => {
        setWaiting(true);
        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementById('bill-7')
          var opt = {
            filename:     `Bill.pdf`,
            image:        { type: 'png', quality: 1 },
            html2canvas:  { scale: 2, logging: false },
            jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: 'p' }
          };
  
          await html2pdf().set(opt).from(html).toPdf().get('pdf').then(function (pdfObj) 
          {
            setWaiting(false);
            // pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');

            // a.href = pdfObj.output('bloburl');
            // a.download =  `ใบฟิล์มกรองแสง.pdf`;
            // a.click();

            // var myWindow = window.open();
            // myWindow.document.write(`<html><head><title>ใบฟิล์มกรองแสง</title></head><body style="margin: 0;"><embed width="100%" height="100%" name="plugin" src="${URL.createObjectURL(new File([pdfObj.output('blob')], 'TEST', {type: 'application/pdf'}))}" type="application/pdf" internalinstanceid="21" /></body></html>`);
            // myWindow.document.close();
          });
        }else{
          console.log('pop')
        }
        setPContent(null);
      }
    });

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      copyStyles: true,
      // onBeforeGetContent: handleOnBeforeGetContentAll,
      print: async (printIframe) => {
        setWaiting(true);
        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementById('bill-8')
          var opt = {
            filename:     `ใบฟิล์มกรองแสง.pdf`,
            image:        { type: 'png', quality: 1 },
            html2canvas:  { scale: 2, logging: false },
            jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: 'p' }
          };
  
          await html2pdf().set(opt).from(html).toPdf().get('pdf').then(function (pdfObj) 
          {
            setWaiting(false);
            // pdfObj.autoPrint();
            window.open(pdfObj.output('bloburl'), '_blank');

            // a.href = pdfObj.output('bloburl');
            // a.download =  `ใบฟิล์มกรองแสง.pdf`;
            // a.click();

            // var myWindow = window.open();
            // myWindow.document.write(`<html><head><title>ใบฟิล์มกรองแสง</title></head><body style="margin: 0;"><embed width="100%" height="100%" name="plugin" src="${URL.createObjectURL(new File([pdfObj.output('blob')], 'TEST', {type: 'application/pdf'}))}" type="application/pdf" internalinstanceid="21" /></body></html>`);
            // myWindow.document.close();
          });
        }
      }
    });
  return (
    <>
      <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
        <Container fluid className="set-height-order-component">
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left  mb-3 bg-document d-flex align-items-center"
            >
              <div className="position-select-job" style={{ top: '0px' }}>
                <Select
                  options={ JobState.status_pay !== 'done' ? optionStatusJob : optionStatusJobIfPaid}
                  placeholder="สถานะ JOB"
                  isClearable={false}
                  isSearchable={false}
                  getOptionLabel={e => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {e.icon}
                      <span style={{ marginLeft: 5 }}>{e.label}</span>
                      
                    </div>
                  )}
                  getOptionValue={(option) => option.value}
                  value={Data.StatusJob ? optionStatusJob.find((item) =>{
                    return item.value === Data.StatusJob.value
                  }): null}
                 
                  onChange={(data) => SetStatusJob(data)}
                  className="select-list"
                />
              </div>
              <Form.Label className=" font-weight-bold">
                เอกสารเปิดงานเสนอราคา
              </Form.Label>
              {/* <div className="position-select-job text-center">
                <Link className="link-page" to="#">
                  <p className="p-0 m-0 pr-4 pt-4 text-dark text-link">แก้ไข</p>
                </Link>
              </div> */}
            </Col>
            <Col lg={6}>
              <Row>
                <Col xs={12} className="pl-5 text-left">
                  <Form.Label className=" font-weight-bold">
                    ข้อมูลเจ้าของรถ
                  </Form.Label>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={4} className="text-right mt-3 mb-2">
                      <Form.Label>รหัสสมาชิก : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>{JobState.customer && JobState.customer.customer_code}</Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={4} className="text-right mt-3 mb-2">
                      <Form.Label>ชื่อ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>{JobState.customer ? JobState.customer.first_name === null && JobState.customer.last_name === null ? JobState.customer.organization !== null ? JobState.customer.organization : null : `${JobState.customer.first_name} ${JobState.customer.last_name} (${JobState.customer.nickname})` : null}</Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={4} className="text-right mt-3 mb-2">
                      <Form.Label>ที่อยู่ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>
                        {`
                          ${JobState.customer && JobState.customer.address ? JobState.customer.address : ''}
                          ${JobState.customer && JobState.customer.amphure !== null ? JobState.customer.amphure.name_th : ''}
                          ${JobState.customer && JobState.customer.postcode ? JobState.customer.postcode : ''}
                        `}
                      </Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={4} className="text-right mt-3 mb-2">
                      <Form.Label>เบอร์โทรศัพท์ : </Form.Label>
                    </Col>

                    <Col className="text-left mt-3 mb-2" style={{ padding: 0 }}>
                      <Form.Label>
                      {
                        JobState.customer && JobState.customer.phone && JobState.customer.type != 'นิติบุคคล' ? 
                        <span>{ `${JSON.parse(JobState.customer.phone)}` }</span>
                        : JobState.customer.contacts !== null ? <span>
                          {
                            JobState.customer.contacts.map(i => {
                              return `${JSON.parse(i.phone)},`
                            })
                          }
                        </span> : '-'
                      }
                      </Form.Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12} className="text-left mt-3 mb-3 ml-4">
                  <Form.Label>
                    <b>ลูกค้าไม่ได้ชำระเอง</b>
                  </Form.Label>
                  &nbsp;
                  <Switch
                    disabled={JobState.status === 'done'}
                  //  onClick={(data) => statusServiceToggler(data)} 
                   onChange={(data) => statusServiceToggler(data)}
                   checked={statusService}/>
                </Col>
                {
                  statusService === true ? (
                    <>
                      <Col xs={12} className="mt-3 mb-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="ค้นหาด้วยชื่อ,รหัสสมาชิก,เบอร์โทรศัพท์"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        onChange={(data) => SetPayerForm(data)}
                        options={CustomersState}
                        getOptionLabel={(option) =>`${option.first_name} ${option.last_name}`}
                        getOptionValue={(option) => option.id}
                      />
                    </Col>
                    {/* <Col xs={12} className="mt-3 mb-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="ค้นหาเลขทะเบียน"
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        onChange={(data) => SetRegisNumber(data)}
                        options={optionRegisNumber}
                      />
                    </Col> */}
                    </>
                  ) : ''
                }
                <Col xs={11} className="bg-data-order">
                  <Row className="p-2">
                    <Col xs={12}>
                      <Row>
                        <Col xs={4} className="text-right mt-3 mb-2">
                          <Form.Label>รหัสสมาชิก : </Form.Label>
                        </Col>

                        <Col
                          className="text-left mt-3 mb-2"
                          style={{ padding: 0 }}
                        >
                          <Form.Label>{PayerForm.customer_code}</Form.Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={4} className="text-right mt-3 mb-2">
                          <Form.Label>ชื่อ : </Form.Label>
                        </Col>

                        <Col
                          className="text-left mt-3 mb-2"
                          style={{ padding: 0 }}
                        >
                          <Form.Label>{PayerForm.fullname}</Form.Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={4} className="text-right mt-3 mb-2">
                          <Form.Label>ที่อยู่ : </Form.Label>
                        </Col>

                        <Col
                          className="text-left mt-3 mb-2"
                          style={{ padding: 0 }}
                        >
                          <Form.Label>
                          {`
                            ${PayerForm.address ? PayerForm.address : ''}
                            ${PayerForm.customer && PayerForm.customer.amphure && PayerForm.customer.amphure?.name_th}
                            ${PayerForm.customer && PayerForm.customer.postcode}
                          `}
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={4} className="text-right mt-3 mb-2">
                          <Form.Label>เบอร์โทรศัพท์ : </Form.Label>
                        </Col>

                        <Col
                          className="text-left mt-3 mb-2"
                          style={{ padding: 0 }}
                        >
                          <Form.Label>
                          {
                            PayerForm.phone.length > 0 ? 
                            <span>{ `${JSON.parse(PayerForm.phone)}` }</span>
                            : '-' 
                          }
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="mt-5 mb-3">
              <Row>
                <Col xs={12} className="mt-1 mb-1">
                  <p className="p-0 m-0">จำนวนทั้งสิ้น</p>
                </Col>
                <Col xs={12} className="mt-1 mb-1">
                  <p className="p-0 m-0" style={{ fontSize: '36px' }}>
                    {numeral(JobState.total_price).format('0,0.00')} บาท
                  </p>
                </Col>
                <Col xs={12} className="mt-1 mb-1">
                  <p className="p-0 m-0">วันที่ {dayjs(JobState.created_at).format('DD-MM-YYYY')}</p>
                </Col>
                <Col xs={12} className="mt-1 mb-1">
                  <p className="p-0 m-0">ผู้บันทึก {JobState.create_by && JobState.create_by.employee && JobState.create_by.employee.fullname}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                ข้อมูลรถยนต์
              </Form.Label>
            </Col>
            <Col className="">
              <Row className="align-items-center ">
                <Col xs={12} lg={11}>
                <Form.Label>
                    เลขทะเบียนรถ : {CarOwner && CarOwner.license_plate},
                     รายละเอียด : ยี่ห้อ : {CarOwner && CarOwner.car_serie && CarOwner.car_serie.car_model && CarOwner.car_serie.car_model.car_brand && CarOwner.car_serie.car_model.car_brand.name}, 
                    รุ่น {CarOwner && CarOwner.car_serie && CarOwner.car_serie.car_model && CarOwner.car_serie.car_model.model}, 
                    สี : {CarOwner && CarOwner.color_job?.name}, ป้าย : {CarOwner && CarOwner.plate_type}, จังหวัด : {CarOwner && CarOwner.province && CarOwner.province?.name_th}
                    เลขตัวถัง : {CarOwner && CarOwner.chassis_number}
                  </Form.Label>
                </Col>
                {/* <Col xs={12} lg={1} className="text-right">
                  <Form.Label>
                    <a
                      href="#"
                      style={{ color: 'black', textDecoration: 'underline' }}
                    >
                      แก้ไข
                    </a>
                  </Form.Label>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                รายละเอียดสินค้า
              </Form.Label>
            </Col>
            <Col xs={12}>
              <Row className="mt-4 mb-4 justify-content-center">
                <Col className="d-flex justify-content-start set-position-radio">
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="สินค้า"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        onChange={handleProduct}
                        checked={product}
                        disabled={JobState.status === 'done'}
                      />
                      <Form.Check
                        inline
                        label="บริการ"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                        onChange={handleService}
                        checked={service}
                        disabled={JobState.status === 'done'}
                      />
                      <Form.Check
                        inline
                        label="แพคเกจ"
                        name="group1"
                        type={type}
                        id={`inline-${type}-3`}
                        onChange={handlePackage}
                        checked={Package}
                        disabled={JobState.status === 'done'}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
              <Row>
                {product && !service && !Package ? (
                  <Container fluid>
                    <Row>
                      <Col>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="ค้นหาสินค้าด้วยรหัสสินค้า, ชื่อสินค้า"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          options={Products}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(data) => SetProductBox(data)}
                          disabled={JobState.status === 'done'}
                        />
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  ''
                )}
                {!product && service && !Package ? (
                  <Container fluid>
                    <Row>
                      <Col>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="ค้นหาสินค้าด้วยรหัสบริการ, ชื่อบริการ"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          options={Services}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(data) => SetServiceBox(data)}
                          disabled={JobState.status === 'done'}
                        />
                      </Col>
                    </Row>

                  </Container>
                ) : (
                  ''
                )}
                {!product && !service && Package ? (
                  <Container fluid>
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={10} className=" mb-4">
                            <Select
                              options={PackageTypes}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              placeholder="ค้นหาสินค้าด้วยรหัสสินค้า, ชื่อสินค้า"
                              isClearable={false}
                              isSearchable={false}
                              onChange={(data) => SetPackage(data)}
                              className="select-list"
                              disabled={JobState.status === 'done'}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                    {/* package */}
                
                    <Row className="mt-3 mb-3 g-0 justify-content-center">
                        {
                            typepackage.label === 'บริการล้างและเคลือบแว๊กซ์' ? (
                              <>
                                <Row className="g-0 justify-content-start">
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={10}>
                                        <Select
                                          options={Packages}
                                          getOptionValue={(option)=> option.id}
                                          getOptionLabel={(option) => option.name}
                                          placeholder=""
                                          isClearable={false}
                                          isSearchable={false}
                                          onChange={(data) => SetSelectPackage(data)}
                                          className="select-list"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6} className="d-none d-lg-block">
                                    <Row className="justify-content-end">
                                      <Col lg={6} xl={5}>
                                        <div className="service-fast-position">
                                          <Form.Check
                                            type="checkbox"
                                            label="รับบริการทันที"
                                            onChange={handleFastService}
                                            checked={serviceNow}
                                            className="checkbox-input"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="g-0 justify-content-start">
                                  <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-4 d-lg-none">
                                    <Form.Check
                                      type="checkbox"
                                      label="รับบริการทันที"
                                      onChange={handleFastService}
                                      checked={serviceNow}
                                      className="checkbox-input"
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : typepackage.label === 'บริการฟิล์มกรองแสง' ? (
                                <>
                                  <Row className="g-0 justify-content-start align-items-center d-lg-none">
                                    <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-3 mb-3 ">
                                      <Form.Check
                                        type="checkbox"
                                        label="รับบริการทันที"
                                        onChange={handleFastService}
                                        checked={serviceNow}
                                        className="checkbox-input"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start">
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={10}>
                                          <Select
                                            options={TypeWorkList}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder="ประเภทงาน"
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(data) => SetTypeWork(data)}
                                            className="select-list"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg={6} className="d-none d-lg-block">
                                      <Row className="justify-content-between">
                                        <Col lg={6} xl={5}>
                                          <div className="service-fast-position">
                                            <Form.Check
                                              type="checkbox"
                                              label="รับบริการทันที"
                                              onChange={handleFastService}
                                              checked={serviceNow}
                                              className="checkbox-input"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Select
                                        options={Packages}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        placeholder="บริการของสินค้า"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetSelectPackage(data)}
                                        className="select-list"
                                      />
                                    </Col>

                                    <Col lg={5} className="mt-2 mb-2">
                                      <Form.Control className="input-table2"
                                        placeholder="ความหนาของฟิล์ม"
                                        value={filmThickness.value}
                                        onChange={(e) => SetFilmThickness(e)}
                                        type='number'
                                      ></Form.Control>
                                    </Col>
                                  </Row>

                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={3} className="mt-2 mb-2">
                                      <Select
                                        options={PackageSizeModel}
                                        placeholder="เลือกรุ่นฟิล์ม"
                                        getOptionValue={(option)=> option.model}
                                        getOptionLabel={(option) => option.model}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetFilmModel(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                    <Col lg={3} className="mt-2 mb-2">
                                      <Select
                                        options={PackageSizeCode}
                                        getOptionValue={(option)=> option.id}
                                        getOptionLabel={(option) => option.code}
                                        placeholder="เลือกเลขรุ่น"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetModelNumber(data)}
                                        className="select-list"
                                      />
                                    </Col>

                                  </Row>

                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                        <Form.Label>รายละเอียด</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            placeholder=""
                                            className="input-area"
                                            rows={5}
                                            value={TextDetails}
                                            onChange={(e) => SetTextDetails(e)}
                                          />
                                    </Col>
                                  </Row>
                                  
                                </>
                            ) : typepackage.label === 'บริการเคลือบเหมา' ? (
                              <>
                                <Row className="g-0 justify-content-start">
                                  <Col
                                    xs={8}
                                    sm={5}
                                    md={4}
                                    lg={3}
                                    xl={3}
                                    className="mt-4 mb-4 p-0 set-position-service "
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      label="รับบริการทันที"
                                      onChange={handleFastService}
                                      checked={serviceNow}
                                      className="checkbox-input"
                                    />
                                  </Col>
                                </Row>
                                <Row className="g-0 justify-content-start">
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={10}>
                                        <Select
                                          options={Packages}
                                          getOptionValue={(option)=> option.id}
                                          getOptionLabel={(option) => option.name}
                                          placeholder="เลือกประเภทการเคลือบ"
                                          isClearable={false}
                                          isSearchable={false}
                                          onChange={(data) => SetSelectPackage(data)}
                                          className="select-list"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="g-0 justify-content-start mt-2">
                                  <Col lg={5} className="mt-2 mb-2">
                                    <Form.Label>รายละเอียด</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            placeholder=""
                                            className="input-area"
                                            rows={5}
                                            value={TextDetails}
                                            onChange={(e) => SetTextDetails(e)}
                                          />
                                  </Col>
                                  <Col lg={5} className="mt-2 mb-2">
                                    
                                  </Col>
                                </Row>
                                
                              </>

                            ) : typepackage.label === 'บริการเคลือบเฉพาะจุด' ? (
                              <>
                                <Row className="g-0 justify-content-start">
                                    <Col lg={6}>
                                      <Row>
                                      </Row>
                                    </Col>
                                    <Col lg={6} className="">
                                      <Row className="justify-content-end">
                                        <Col lg={6} xl={5}>
                                          <div className="d-none d-lg-block service-fast-position">
                                            <Form.Check
                                              type="checkbox"
                                              label="รับบริการทันที"
                                              onChange={handleFastService}
                                              checked={serviceNow}
                                              className="checkbox-input"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start align-items-start">
                                    <Col xs={6} sm={5} md={4} lg={4} xl={3} className=" d-lg-none">
                                      <Form.Check
                                        type="checkbox"
                                        label="รับบริการทันที"
                                        onChange={handleFastService}
                                        checked={serviceNow}
                                        className="checkbox-input"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Select
                                        options={Packages}
                                        getOptionValue={(option)=> option.id}
                                        getOptionLabel={(option) => option.name}
                                        placeholder="เลือกประเภทการเคลือบ"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetTypeCoating(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Select
                                        options={PointCoatingPackage}
                                        getOptionValue={(option)=> option.id}
                                        getOptionLabel={(option) => option.size}
                                        defaultValue={PointCoatings}
                                        isMulti
                                        placeholder="เลือกจุดที่ต้องการเคลือบ"
                                        isClearable={false}
                                        isSearchable={false}
                                        isDisabled={PointCoatingPackage.length < 1 ? true :false || StatusToggle === true}
                                        onChange={(data) => SetPointCoating(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Row>
                                        <Col xs={12} className="text-left mt-3 mb-3 ml-4">
                                          <Switch checked={StatusToggle} onClick={(data) =>SetSwitch(data)} />
                                          &nbsp;
                                          <Form.Label>
                                          <b>{StatusToggle === true ? 'เลือกตำแหน่ง' : 'เลือกจุดที่ต้องการเคลือบ'}</b>
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg={5} className="mt-2 mb-2">
                                      
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                    <Select
                                          isMulti
                                          placeholder="เลือกตำแหน่ง"
                                          options={optionPosition}
                                          value={PositionUse.length > 0 ? PositionUse : ''}
                                          onChange={(data) => SetPosition(data)}
                                          isDisabled={StatusToggle === false}
                                          className={`select-list ` }
                                        />
                                    </Col>
                                    <Col lg={5} className="mt-2 mb-2">
                                        
                                    </Col>
                                  </Row>

                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Form.Label>รายละเอียด</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              placeholder=""
                                              className="input-area"
                                              rows={5}
                                              value={TextDetails}
                                              onChange={(e) => SetTextDetails(e)}
                                            />
                                    </Col>
                                    <Col lg={5} className="mt-2 mb-2">
                                      
                                    </Col>
                                  </Row>
                                
                              </>

                            ) : typepackage.label === 'บริการฟิล์มกันรอย' ? (
                                <>
                                  <Row className="g-0 justify-content-start">
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={10}>
                                          <Select
                                            options={optionService}
                                            getOptionValue={(option)=> option.id}
                                            getOptionLabel={(option) => option.name}
                                            placeholder="ประเภทงาน"
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(data) => SetTypeWork(data)}
                                            className="select-list"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg={6} className="">
                                      <Row className="justify-content-end">
                                        <Col lg={6} xl={5}>
                                          <div className="d-none d-lg-block service-fast-position">
                                            <Form.Check
                                              type="checkbox"
                                              label="รับบริการทันที"
                                              onChange={handleFastService}
                                              checked={serviceNow}
                                              className="checkbox-input"
                                              value
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start">
                                    <Col
                                      xs={6}
                                      sm={5}
                                      md={4}
                                      lg={4}
                                      xl={3}
                                      className="mt-4  d-lg-none p-0"
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="รับบริการทันที"
                                        onChange={handleFastService}
                                        checked={serviceNow}
                                        className="checkbox-input"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                    <Select
                                            isMulti
                                            placeholder="เลือกตำแหน่ง"
                                            options={optionPosition}
                                            value={PositionUse}
                                            onChange={(data) => SetPosition(data)}
                                            className={`select-list ` }
                                          />
                                    </Col>
                                    <Col lg={6} className="mt-2 mb-2">

                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={5} className="mt-2 mb-2">
                                      <Select
                                        options={Packages}
                                        getOptionValue={(option)=> option.id}
                                        getOptionLabel={(option) => option.name}
                                        placeholder="เลือกยี่ห้อฟิล์ม"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetSelectPackage(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                    <Col lg={6} className="mt-2 mb-2">

                                    </Col>
                                  </Row>
                                  <Row className="g-0 justify-content-start mt-2">
                                    <Col lg={3} className="mt-2 mb-2">
                                      <Select
                                        options={PackageSizeModel}
                                        placeholder="เลือกรุ่นฟิล์ม"
                                        getOptionValue={(option)=> option.model}
                                        getOptionLabel={(option) => option.model}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetFilmModel(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                    <Col lg={3} className="mt-2 mb-2">
                                      <Select
                                        options={PackageSizeCode}
                                        getOptionValue={(option)=> option.id}
                                        getOptionLabel={(option) => option.code}
                                        placeholder="เลือกเลขรุ่น"
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(data) => SetModelNumber(data)}
                                        className="select-list"
                                      />
                                    </Col>
                                  </Row>
                                  
                                </>
                            ) : typepackage.label === 'แพ็คเกจพิเศษ' ? (
                              <>
                                <Row className="g-0 justify-content-start">
                                  <Col lg={6}>
                                    <Row>
                                      <Col lg={10}>
                                        <Select
                                          options={Packages}
                                          placeholder="เลือกบริการ"
                                          getOptionValue={(option)=> option.id}
                                          getOptionLabel={(option) => option.name}
                                          isClearable={false}
                                          isSearchable={false}
                                          onChange={(data) => SetSelectPackage(data)}
                                          className="select-list"  
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6} className="">
                                    <Row className="justify-content-end">
                                      <Col lg={6} xl={5}>
                                        <div className="d-none d-lg-block service-fast-position">
                                          <Form.Check
                                            type="checkbox"
                                            label="รับบริการทันที"
                                            onChange={handleFastService}
                                            checked={serviceNow}
                                            className="checkbox-input"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="g-0 justify-content-start">
                                  <Col xs={6} sm={5} md={4} lg={4} xl={3} className="mt-4 d-lg-none">
                                    <Form.Check
                                      type="checkbox"
                                      label="รับบริการทันที"
                                      onChange={handleFastService}
                                      checked={serviceNow}
                                      className="checkbox-input"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3 mb-3 g-0 justify-content-center">
                                  {
                                    washPart.name === 'บริการล้างและเคลือบแว๊กซ์' ? (
                                      <Row className="g-0 justify-content-start">
                                        <Row>
                                          <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  { washPart.data.package.name }
                                                </Col>
                                              </Row>
                                            </Col>  
                                        </Row>    
                                      </Row>
                                    ) : ''
                                  }
                                  {
                                      lightfilter.name === 'บริการฟิล์มกรองแสง' ? (
                                        <Row className="g-0 justify-content-start">
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  { lightfilter.data.package.name }
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  <Select
                                                    options={TypeWorkList}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder="ประเภทงาน"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    // onChange={(data) => SetTypeWork(data)}
                                                    className="select-list"
                                                  />
                                                </Col>
                                                <Col lg={5} className="mt-2 mb-2">
                                                  <Form.Control className="input-table2"
                                                    placeholder="ความหนาของฟิล์ม"
                                                    // value={filmThickness.value}
                                                    // onChange={(e) => SetFilmThickness(e)}
                                                    type='number'
                                                  ></Form.Control>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      ) : ''
                                  }
                                  {
                                      coatingService.name === 'บริการเคลือบเหมา' ? (
                                        <Row className="g-0 justify-content-start">
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  { coatingService.data.package.name }
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      ) : ''
                                  }
                                  {
                                      protective.name === 'บริการเคลือบเฉพาะจุด' ? (
                                        <Row className="g-0 justify-content-start">
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  { protective.data.package.name }
                                                </Col>
                                                <Col lg={5} className="mt-2 mb-2">
                                                  <Select
                                                    options={PointCoatingPackage}
                                                    getOptionValue={(option)=> option.id}
                                                    getOptionLabel={(option) => option.size}
                                                    // value={Data.spray_coating}
                                                    defaultValue={PointCoatings}
                                                    isMulti
                                                    placeholder="เลือกจุดที่ต้องการเคลือบ"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isDisabled={PointCoatingPackage.length < 1 ? true :false}
                                                    onChange={(data) => SetPointCoating(data)}
                                                    className="select-list"
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                            
                                          </Row>
                                        </Row>
                                      ) : ''
                                  }
                                  {
                                      serviceFilter.name === 'บริการฟิล์มกันรอย' ? (
                                        <Row className="g-0 justify-content-start">
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  { serviceFilter.data.package.name }
                                                </Col>
                                                
                                              </Row>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg={6}>
                                              <Row>
                                                <Col lg={10}>
                                                  <Select
                                                    options={optionService}
                                                    getOptionValue={(option)=> option.id}
                                                    getOptionLabel={(option) => option.name}
                                                    placeholder="ประเภทงาน"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(data) => UpdateService(data,serviceFilter.data)}
                                                    className="select-list"
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      ) : ''
                                  }
                                </Row>
                              </>
                            ) : (
                              null
                            )
                        }
                      </Row>
                          {/* package */}
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col lg={3} className="mt-4 mb-3">
                            <Button type="button" disabled={JobState.status === 'done'} onClick={()=> {AddPackageToStorageList()}} className="button">
                              เพิ่ม
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  ''
                )}
              </Row>
              <Row className="mt-5 mb-5 m-0">
                <Col className=" p-0">
                  <table className="table table-responsive table-wash-part ">
                    <thead>
                      <tr className="t-header-package">
                        <th className="text-left">รหัสสินค้า</th>
                        <th className="text-left">ชื่อสินค้า</th>
                        <th>หน่วย</th>
                        <th>จำนวน</th>
                        <th>ราคาต่อหน่วย</th>
                        <th>
                          <div className="div-select-table" style={{ minWidth: '145px' }}>
                            <Select
                              options={optionDiscount}
                              placeholder={Discount.label}
                              isClearable={false}
                              isSearchable={false}
                              onChange={(data) => {
                                SetDiscount(data)
                              }}
                              disabled={JobState.status === 'done'}
                              className="select-in-table"
                            />
                          </div>
                        </th>
                        <th>ราคารวม</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="t-body-package ">
                      {
                        Data.StorageList?.map((item, key) => {
                          // console.log(Data.StorageList);
                          return (
                            <tr key={item.key}>
                              <td className="text-left">{item.PackageCode}</td>
                              <td className="text-left">
                                {item.PackageName}
                                <p className="m-0">
                                  { getTextByPackage(item)}
                                  { getDisplayTextPositionCoating(item) }
                                  { getDisplayTextPositionUse(item)}
                                  { item.TextDetails ? `(${item.TextDetails})` : '' }
                                </p>
                                {item.service_now === true ? 'บริการทันที' : ''} 
                                {item.CleanCar === 0 ? '(ไม่ล้าง)' : ''}
                                <>
                                  {/* will chage to functons for check and display text  */}
                                  {/* {item.form === 'package' ? item.todo + ` ` + `จำนวนที่เหลือ` + (item.clean_remaining ) + `/` + item.clean_total : ''}   */}
                                  { item.total_quantity !== null ? ` (คงเหลือ `+ ((item.total_quantity + 0).toFixed(2)) +` )` : '' }
                                </>
                              </td>
                              <td>{item.UnitName}</td>
                              <td>
                                <Form.Control className="input-table"  
                                  value={item.Quantity ? item.Quantity : ''}
                                  type="number"
                                  disabled={JobState.status === 'done' || item.job_detail_type === 'appointment' || JobState.status_pay === 'done'}
                                  onChange={
                                    (data) => {
                                      setData(prevState => ({...Data,
                                        StorageList: prevState.StorageList.map(
                                          el => el.key === item.key ? {...el, Quantity: data.target.value ? Number(data.target.value) : '' }: el
                                        )
                                      }))
                                    }
                                  }
                                ></Form.Control>
                              </td>
                              <td>
                              <Form.Control className="input-table"
                                  value={item.Price}
                                  type="number"
                                  disabled={JobState.status === 'done'  || item.job_detail_type === 'appointment' || JobState.status_pay === 'done'}
                                  onChange={
                                    (data) => {
                                      setData(prevState => ({...Data,
                                        StorageList: prevState.StorageList.map(
                                          el => el.key === item.key ? { ...el, Price: data.target.value ? Number(data.target.value) : ''  }: el
                                        )
                                      }))
                                    }
                                  }
                                ></Form.Control>
                              </td>
                              <td>
                                {
                                  Discount.value === 'percent' ? 
                                  (
                                    <Form.Control
                                      type="number"
                                      className="input-in-table"
                                      placeholder={view_placeholder}
                                      value={item.Discount}
                                      disabled={JobState.status === 'done' || item.job_detail_type === 'appointment' || JobState.status_pay === 'done'}
                                      onChange={
                                        (data) => {
                                          setData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Discount: data.target.value ? 
                                                Math.max(0, Math.min(100, Number(data.target.value))) : '' }: el
                                            )
                                          }))
                                        }
                                      }
                                    ></Form.Control>
                                  ) : (
                                    <Form.Control
                                      type="number"
                                      className="input-in-table"
                                      disabled={JobState.status === 'done'  || item.job_detail_type === 'appointment' || JobState.status_pay === 'done'}
                                      placeholder={view_placeholder}
                                      value={item.Discount}
                                      onChange={
                                        (data) => {
                                          setData(prevState => ({...Data,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? { ...el, Discount: data.target.value ? Number(data.target.value) : '' }: el
                                            )
                                          }))
                                        }
                                      }
                                    ></Form.Control>
                                  )
                                }
                              </td>
                              <td>{GrandTotalProduct(item)}</td>
                              <th>
                                <Form.Check 
                                  type='checkbox'
                                  id={`default-checkbox`}
                                  disabled={JobState.status === 'done' || JobState.status_pay === 'done'}
                                  label=''
                                  onClick={() => SetMergeJob(item,key)}
                                />
                                <Button
                                  type="button"
                                  className="button-package"
                                  disabled={JobState.status === 'done' || JobState.status_pay === 'done'}
                                  onClick={() => removePackageList(item)}
                                >
                                  <img src={Delete} />
                                </Button>
                              </th>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <Row className="g-0 justify-content-start mt-2">
                    <Col lg={6} className="mt-2 mb-2 text-right">ราคารวม Job </Col>
                    <Col lg={3} className="mt-2 mb-2">
                      <input 
                        className="input-table2"
                        style={{ fontSize: '1.1rem', padding: '10px' }}
                        type="number"
                        disabled={JobState.status === 'done' || JobState.status_pay === 'done'}
                        onChange={(data) => {setMergeJobPrice(data.target.value)}}
                      ></input>
                    </Col>
                    <Col lg={3} className="mt-2 mb-2">
                      <Button type="button" className="button" disabled={JobState.status === 'done' || JobState.status_pay === 'done'} onClick={() => {goMergeJob()}}>
                        รวม Job
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="g-0 bg-white">
            <Col
              xs={12}
              className="text-left mt-3 mb-3 bg-document d-flex align-items-center"
            >
              <Form.Label className=" font-weight-bold">
                รูปภาพประกอบการตรวจเช็คสภาพรถยนต์
              </Form.Label>
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <Row>
                <Col
                  xs={12}
                  className="text-left ml-3 mt-3 mb-3 d-flex align-items-center"
                >
                  <Form.Label className=" font-weight-bold">ก่อนทำ</Form.Label>
                </Col>
              </Row>
              <Row className="no-gutter pr-4">
                <Col lg={10} className="d-flex justify-content-start">
                  <div className="scale-image multi-preview">
                    <div className="result">
                      <Row>
                        {Data.ImagesDisplayBF && Data.ImagesDisplayBF.map((item, index) => {
                            return (
                              <Col xs="auto" key={item.key} className="mt-3 ">
                                <img
                                  src={item.image}
                                  alt=""
                                  width="150px"
                                  height="100%"
                                />
                                <button
                                  type='button'
                                  onClick={() => deleteFileBefore(item)}
                                  className="position-absolute-button-image"
                                >
                                  <img src={Delete} />
                                </button>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={2}
                  className="d-flex justify-content-center align-items-center  mt-3 mb-3"
                >
                  <div className="label-holder">
                    <input
                      type="file"
                      id="uploadBefore"
                      hidden
                      multiple
                      // disabled={filebefore.length === 5}
                      onChange={uploadSingleFileBefore}
                    />
                  </div>
                  <div className="label-holder">
                    <label htmlFor="file" className="label" for="uploadBefore">
                      <img src={Upload} /> &nbsp; อัพโหลดภาพ
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <Row>
                <Col
                  xs={12}
                  className="text-left ml-3 mt-3 mb-3 d-flex align-items-center"
                >
                  <Form.Label className=" font-weight-bold">หลังทำ</Form.Label>
                </Col>
              </Row>
              <Row  className="no-gutter pr-4">
                <Col lg={10} className="d-flex justify-content-start">
                  <div className="scale-image multi-preview">
                    <div className="result">
                      <Row>
                        {Data.ImagesDisplayAF && Data.ImagesDisplayAF.map((item, index) => {
                            return (
                              <Col xs="auto" key={item} className="mt-3 ">
                                <img
                                  src={item.image}
                                  alt=""
                                  width="150px"
                                  height="100%"
                                />
                                <button
                                  onClick={() => deleteFileAfter(item)}
                                  className="position-absolute-button-image"
                                >
                                  <img src={Delete} />
                                </button>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={2}
                  className="d-flex justify-content-center align-items-center mt-3 mb-3"
                >
                  <div className="label-holder">
                    <input
                      type="file"
                      id="uploadAfter"
                      hidden
                      multiple
                      // disabled={filebefore.length === 5}
                      onChange={uploadSingleFileAfter}
                    />
                  </div>
                  <div className="label-holder">
                    <label htmlFor="file" className="label" for="uploadAfter">
                      <img src={Upload} /> &nbsp; อัพโหลดภาพ
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={6}>
                  <Row>
                    <Col xs={6} md={3} className="mt-2 mb-2">
                      <Form.Check
                        type="checkbox"
                        label="สิ่งของในรถ"
                        onChange={handleThingsInCar}
                        checked={PayerForm.ThingsInCar}
                        className="checkbox-input"
                        disabled={JobState.status === 'done'}
                      />
                    </Col>
                    <Col lg={3} xl={3} className="mt-2 mb-2">
                      <Form.Check
                        type="checkbox"
                        label="ตำหนิรถ"
                        onChange={handleDefectCar}
                        checked={PayerForm.DefectCar}
                        className="checkbox-input"
                        disabled={JobState.status === 'done'}
                      />
                    </Col>
                    <Row className="mt-3 mb-3">
                      <Col xs={12} lg={3} className="text-left pl-5">
                        <Form.Label>บริเวณ</Form.Label>
                      </Col>
                      <Col className="mt-3 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          disabled={JobState.status === 'done'}
                          className="text-area"
                          value={PayerForm.Area}
                          onChange={ (data) => {
                            setPayerForm({...PayerForm, Area: data.target.value});
                          }}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3 mb-3">
              <Row className="align-items-center">
                <Col lg={6}>
                  <Col>
                    <Row className="mt-3 mb-3">
                      <Col xs={12} lg={3} className="text-left">
                        <Form.Label>หมายเหตุ</Form.Label>
                      </Col>
                      <Col className="mt-3 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          disabled={JobState.status === 'done'}
                          className="text-area"
                          value={PayerForm.Note}
                          onChange={ (data) => {
                            setPayerForm({...PayerForm, Note: data.target.value});
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                      <Col xs={12} lg={3} className="text-left">
                        <Form.Label>โน้ตภายใน</Form.Label>
                      </Col>
                      <Col className="mt-3 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          disabled={JobState.status === 'done'}
                          className="text-area"
                          value={PayerForm.InsiteNote}
                          onChange={ (data) => {
                            setPayerForm({...PayerForm, InsiteNote: data.target.value});
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                </Col>
                <Col lg={6}>
                  { job && job.type == 'show_room' ? (
                    <Row className="align-items-center mt-3 mb-3">
                      <Col xs={12} lg={12} className="text-left mt-2  mb-2">
                        <Row className="align-items-center mt-3 mb-3">

                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <Form.Label>ราคาส่ง</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <input 
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              value={prePrice}
                              disabled={!canShowroomEdit}
                              onChange={(data) => {modifileShowroom('preprice', data.target.value)}}
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                            <Form.Label>Showroom ขายลูกค้า</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                            <input 
                              value={sPrice}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              disabled={!canShowroomEdit}
                              onChange={(data) => {modifileShowroom('price', data.target.value);}}
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className="text-right mt-2  mb-1">
                            <Form.Label>Showroom แจ้งส่วนลด</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className="text-left mt-2  mb-1">
                            <input 
                              value={sDiscount}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              disabled={!canShowroomEdit}
                              onChange={(data) => {modifileShowroom('discount', data.target.value)}}
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <Form.Label>ใบสั่ง</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <input 
                              value={sCost}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              disabled={!canShowroomEdit}
                              onChange={(data) => {modifileShowroom('cost', data.target.value)}}
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <Form.Label>ส่วนต่าง</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <input 
                              value={sDifferent}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              onChange={(data) => {modifileShowroom('comission', data.target.value)}}
                              readOnly
                              disabled
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <Form.Label>Comission Sale</Form.Label>
                          </Col>
                          <Col xs={6} lg={6} className={!canShowroomEdit ? 'd-none' : 'text-right mt-2  mb-1'}>
                            <input 
                              value={sCommission}
                              className="input-table2"
                              style={{ fontSize: '1.1rem', padding: '10px' }}
                              type="number"
                              onChange={(data) => {modifileShowroom('comission', data.target.value)}}
                            ></input>
                          </Col>

                          <Col xs={6} lg={6} className="text-right mt-2  mb-1" className={canShowroomEdit ? 'd-none' : ''}>
                            <div className="text-right mt-3">ราคารวม</div>
                          </Col>
                          <Col xs={6} lg={6} className="text-right mt-2  mb-1" className={canShowroomEdit ? 'd-none' : ''}>
                            <h4 className="mt-3">{Number(job.total_price)} บาท</h4>
                          </Col>

                        </Row>
                      </Col>
                    </Row>
                    ) : (
                    <Row className="bg-data-sum pt-2 pb-2">
                      <Col xs={12}>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <p>รวมเป็นเงิน</p>
                          </Col>
                          <Col className="text-right  mt-3 mb-3 ">
                            <p>{SumTotal(Data)} บาท</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <p>ส่วนลดรวม</p>
                          </Col>
                          <Col className="text-right  mt-3 mb-3 ">
                            <Select
                              options={optionDiscountType}
                              disabled={JobState.status === 'done'}
                              isClearable={false}
                              isSearchable={false}
                              onChange={(data) => SetDiscountType(data)}
                              value={Data.DiscountType}
                              className="select-list"
                            />
                          </Col>
                          <Col className="text-right  mt-3 mb-3 ">
                            <Form.Control
                              type="number"
                              className="input-in-table"
                              placeholder={Data.label}
                              value={Data.DiscountTotal}
                              disabled={JobState.status === 'done'}
                              onChange={
                                (data) => {
                                  setData({...Data, DiscountTotal: data.target.value > 0 ? data.target.value : ''})
                                }
                              }
                          ></Form.Control>
                          </Col>
                          <Col className="text-right  mt-3 mb-3 ">
                            <p>{DisplayDiscountTotalCalculate(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3" sm={3}>
                            <p>คูปอง</p>
                          </Col>
                          <Col className="text-right  mt-3 mb-3 ">
                            <Select
                              placeholder="คูปองส่วนลด"
                              options={Vouchers}
                              disabled={JobState.status === 'done'}
                              isClearable={false}
                              isSearchable={false}
                              getOptionLabel={option => option.name}
														  getOptionValue={option => option.id}
                              onChange={(data) => SetVoucherDiscount(data)}
                              value={Data.VoucherId ? Vouchers.find((item) =>{
                                return item.id === Data.VoucherId
                              }): null}
                              className="select-list"
                            />
                          </Col>
                          <Col className="text-right  mt-3 mb-3 "  sm={3}>
                            <p>{DisplayVoucherDiscountTotalCalculate(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <p>ราคาหลังหักส่วนลด</p>
                          </Col>
                          <Col className="text-right  mt-3 mb-3">
                            <p>{funcSumTotalGrand(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between align-items-center">
                          <Col className="text-left  mt-3 mb-3">
                            <div key={`inline-checkbox`}>
                              <Row>
                                <Col md={5} lg={7} xl={5} className="d-flex">
                                  <Form.Check
                                    inline
                                    label="ภาษีมูลค่าเพิ่ม"
                                    name="group_vat"
                                    type='checkbox'
                                    id={`inline-checkbox-vat`}
                                    disabled={JobState.status === 'done'}
                                    className="checkbox-input"
                                    onChange={(data) => SetVatActive(data)}
                                    checked={Data.VatActive}
                                  />
                                </Col>
                                <Col xs={8} md={5} className="mt-1 mb-1">
                                  <Select
                                    options={optionVat}
                                    isClearable={false}
                                    isSearchable={false}
                                    disabled={JobState.status === 'done'}
                                    isDisabled={Data.VatActive === true ? false : true}
                                    onChange={(data) => SetVat(data)}
                                    value={Data.Vat}
                                    className="select-list"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xs={3} className="text-right  mt-3 mb-3">
                            <p>{DisplayVatCalculate(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between align-items-center">
                          <Col className="text-left  mt-3 mb-3">
                            <div key={`inline-checkbox`}>
                              <Row>
                                <Col md={5} lg={7} xl={5} className="d-flex">
                                  <Form.Check
                                    inline
                                    label="หักภาษี ณ ที่จ่าย"
                                    name="group_tax"
                                    type='checkbox'
                                    id={`inline-checkbox-tax`}
                                    className="checkbox-input"
                                    disabled={JobState.status === 'done'}
                                    onChange={(data) => SetTaxActive(data)}
                                    checked={Data.TaxActive}

                                  />
                                </Col>
                                <Col xs={8} md={5} className="mt-1 mb-1">
                                  <Select
                                    options={optionTax}
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={Data.TaxActive === true ? false : true}
                                    onChange={(data) => SetTax(data)}
                                    value={Data.Tax}
                                    disabled={JobState.status === 'done'}
                                    className="select-list"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xs={3} className="text-right  mt-3 mb-3">
                            <p>{DisplayTaxCalculate(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <p>ราคาสุทธิ</p>
                          </Col>
                          <Col className="text-right mt-3 mb-3 ">
                            <p>{DisplaySumTotalAfterVat(Data)}</p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <Row>
                              <Col>
                                <p>ใบสั่ง</p>
                              </Col>
                              <Col>
                                <Form.Control
                                  type="number"
                                  className="input-in-table"
                                  // placeholder={Data.label}
                                  value={Data.OrderText}
                                  disabled={JobState.status === 'done'}
                                  onChange={
                                    (data) => {
                                      setData({...Data, OrderText: data.target.value ? data.target.value : ''})
                                    }
                                  }
                              ></Form.Control>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="text-right mt-3 mb-3 ">
                            
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-left  mt-3 mb-3">
                            <Row>
                              <Col>
                                <p>จ่ายเอง</p>
                              </Col>
                              <Col>
                                <Form.Control
                                  type="number"
                                  className="input-in-table"
                                  // placeholder={Data.label}
                                  value={Data.OrderPay}
                                  disabled={JobState.status === 'done'}
                                  onChange={
                                    (data) => {
                                      setData({...Data, OrderPay: data.target.value ? data.target.value : ''})
                                    }
                                  }
                              ></Form.Control>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="text-right mt-3 mb-3 ">
                            
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-0 p-3 pt-5 pb-3 bg-white justify-content-between">
            <Col xs={12} md={10} lg={9}>
              <Row>
                <Col xs={6} md={3} className="mt-2 mb-2">
                  <Button type="submit" className="button" disabled={isLoading}>
                    <img src={Save} /> &nbsp; บันทึก
                  </Button>
                </Col>

                <Col lg={3} xl={3} className="mt-2 mb-2">
                  {/* <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle="Bill"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentShop}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyShop}
                  /> */}
                  <Button type="button" className="button" onClick={() => {setPrintType(['ช่างshop']); setPContent(handleOnBeforeGetContentShop()); handlePrint2();}}>
                    ช่างshop
                  </Button>
                </Col>

                <Col lg={3} xl={3} className="mt-2 mb-2">
                  {/* <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle="Bill"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentAdminOfficer}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyAdminOfficer}
                  /> */}
                  <Button type="button" className="button" onClick={() => {setPrintType(['ธุรการ']); setPContent(handleOnBeforeGetContentAdminOfficer()); handlePrint2();}}>
                    ธุรการ
                  </Button>
                </Col>
                <Col lg={3} xl={3} className="mt-2 mb-2">
                  {/*<ReactToPrint
                    content={reactToPrintContent}
                    documentTitle="Bill"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentCustomer}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={OnlyCustomer}
                  /> */}
                  <Button type="button" className="button" onClick={() => {setPrintType(['ลูกค้า']); setPContent(handleOnBeforeGetContentCustomer()); handlePrint2();}}>
                    ลูกค้า
                  </Button>
                </Col>

                <Col lg={3} xl={3} className="mt-2 mb-2">
                  {/* <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle="Bill"
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContentAll}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint
                    trigger={AllPrint}
                  /> */}
                  <Button type="button" className="button" onClick={() => {setPrintType(['ช่างshop','ธุรการ','ลูกค้า']); setPContent(handleOnBeforeGetContentAll()); handlePrint2();}}>
                    ทั้งหมด
                  </Button>
                </Col>

                <Col lg={3} xl={3} className="mt-2 mb-2">
                  <Button type="button" className="button" onClick={() => handlePrint()}>
                    Order Film
                  </Button>
                  {
                    job &&
                    <div style={{ display: 'none' }}>
                      <Bill8 Job={job} Data={Data} PayerForm={PayerForm} ref={componentRef} />
                    </div>
                  }
                </Col>

                <div style={{ display: 'none' }}>
                {
                  reRender &&
                  <Bill7 Job={job} Data={PContent} Type={printType} ref={componentRef} />
                }
              </div>
                {/* <Col xs={6} md={3} className="mt-2 mb-2">
                  <Button
                    type="button"
                    className="button"
                    onClick={() => {
                      // LinkTo(`/job/order/${JobId}`); 
                      setStorageKey('JobAssign')}}
                  >
                    <span>
                      <img src={Assign} /> &nbsp; มอบหมายงาน
                    </span>
                  </Button>
                </Col>
                <Col xs={6} md={3} className="mt-2 mb-2">
                  <Button type="button" className="button">
                    <img src={print} /> &nbsp; พิมพ์เอกสาร
                  </Button>
                </Col>
                {
                  DisplayButtonOrder === true ? (
                    <Col xs={6} md={3} className="mt-2 mb-2">
                      <Button type="button" className="button">
                        <img src={print} /> &nbsp; พิมพ์เอกสารใบฟิล์มกรองแสง
                      </Button>
                    </Col>
                  ): ''
                }
                <Col xs={6} md={3} className="mt-2 mb-2">
                  <Button type="button" className="button" onClick={handleShow}>
                    รับรถ
                  </Button>
                </Col> */}
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-end">
                <Col lg={9} className="mt-2 mb-2">
                  <Button type="button" className="button" onClick={()=> {
                    LinkTo('/job')
                  }}>
                    <img src={Return} /> &nbsp; ออก
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal className="modal-style" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-style">
          <p>
            <b>รับรถ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row
              className="justify-content-center "
              style={{ height: '300px' }}
            >
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-5">
                  <Col xs={3} lg={3} className="text-left">
                    <Form.Label className="set-text-height">วันที่</Form.Label>
                  </Col>
                  <Col className="p-0 pr-3">
                    <DatePicker
                      onChange={onChange}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center ">
                  <Col xs={2} className="text-left">
                    <Form.Label className="set-text-height">เวลา</Form.Label>
                  </Col>
                  <Col>
                    <Select
                      options={optionHour}
                      defaultValue={optionHour[0]}
                      isClearable={false}
                      isSearchable={false}
                      onChange={(data) => SetHour(data)}
                      className="select-list"
                    />
                  </Col>
                  :
                  <Col>
                    <Select
                      options={optionMinute}
                      defaultValue={optionMinute[0]}
                      isClearable={false}
                      isSearchable={false}
                      onChange={(data) => Setminut(data)}
                      className="select-list"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={7}>
                <Row className="align-items-center justify-content-center">
                  <Col lg={5}>
                    <Button type="button" className="button">
                      บันทึก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-style"
        centered
        show={showPackage}
        onHide={handleClosePackage}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>รายละเอียดแพคเกจ</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row
              className="justify-content-center "
              style={{ height: '300px' }}
            >
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-5">
                  <Col xs={12} lg={12} className="text-left">
                    <Col className="mt-3 mb-3">
                      <Form.Label className="set-text-height">
                        หมวดหมู่แพคเกจ : ล้างและเคลือบแวกซ์
                      </Form.Label>
                    </Col>
                    <Col className="mt-3 mb-3">
                      <Form.Label className="set-text-height">
                        บริการ : เหมาะบริการ + เคลือบแวกซ์
                      </Form.Label>
                    </Col>
                    <Col className="mt-3 mb-3">
                      <Form.Label className="set-text-height">
                        ใช้บริการครั้งที่ : 1/5
                      </Form.Label>
                    </Col>
                    <Col className="mt-3 mb-3">
                      <Form.Label className="set-text-height">
                        บริการเสริม(ทรีตเมนต์) : 1/40
                      </Form.Label>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal.Body className="body-set-height">
          <ToastContainer position='top-end'>
            {
              Alert?.map((error, key) => {
                return (
                  <Toast show={true} key={key} delay={5000} bg='danger' autohide onClose={() => setAlert([])}>
                    <Toast.Header>
                      <strong className="me-auto">แจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body>
                      ชื่อสินค้า:{ error.name } -
                      จำนวนที่ขาด:{ error.less} -
                      จาก:{ error.form}
                    </Toast.Body>
                  </Toast>
                )
              })
            }
          </ToastContainer>

        </Modal.Body>
    </>
  );
}
