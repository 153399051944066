
import React, { useState, useEffect, useRef  } from 'react';
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom';
import * as htmlToImage from 'html-to-image';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  ToastContainer, Toast
} from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js'
// import PDFBill from './Components/PDFBill'
import { loadAuth } from 'actions/auth';

import reload from'../../assets/reload.png';
import Echo from 'laravel-echo';
// import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import { PDFDownloadLink } from '@react-pdf/renderer';

import Footer from '../Components/Footer';
import TabsIcon from '../Components/layout/TabsIcon';
import Service from '../assets/icon/management.png';
import Back from '../assets/icon/back.png';
import Select, { components } from 'react-select';
import Print from '../assets/icon/print.png';
import Return from '../assets/icon/new-re-turn.png';
import Money from '../assets/icon/pay-money.png';
import { DatePicker, Space } from 'antd';
import Calendar from '../assets/icon/calendar-bg.png';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import BillImage from 'assets/icon/job.png'

import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';
import { Bill1 } from 'Setting/PreviewBill/Component/Bill1'
import { Bill2 } from 'Setting/PreviewBill/Component/Bill2'
import { Bill3 } from 'Setting/PreviewBill/Component/Bill3'
import { Bill4 } from 'Setting/PreviewBill/Component/Bill4'
import { Bill5 } from 'Setting/PreviewBill/Component/Bill5'
import { Bill6 } from 'Setting/PreviewBill/Component/Bill6'

//API
import { FindBill,SendRequestApprovedDiscount, CancelPaidBill } from 'actions/bill';
import { BankList } from 'actions/settings/bank';
import { CreatePaymentHistory } from 'actions/payment_history'
import dayjs from 'dayjs';
export default function AcceptPayment(props) {
  const [vat, setVat] = useState([{ value: '', label: '' }]);
  const [tax, setTax] = useState([{ value: '', label: '' }]);
  const [validated, setValidated] = useState(false);

  const [accountNumber, setAccountNumber] = useState([
    { value: '', label: '' },
  ]);
  const [comment, setCommtent] = useState('');
  const [cardswipemachine, setCardSwipeMachine] = useState([
    { value: '', label: '' },
  ]);
  const [searchPayer, setSearchPayer] = useState([{ value: '', label: '' }]);

  const [isLoading, setLoading] = useState(false);
  const [DialogDiscount, setDialogDiscount] = useState(false);
  

  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [Bill,setBill] = useState(null);
  const [Job,setJob] = useState({
    total_price: 0,
    total: 0,
    use_sprice: 0,
    discount: 0,
  });
  const [Banks,setBanks] = useState([]);
  const [Branchs,setBranchs] = useState([]);
  const [Accounts,setAccounts] = useState([]);
  const [AccountNumbers,setAccountNumbers] = useState([]);
  const [CarOwner, setCarOwner] = useState([]);
  const [PackageList,setPackageList] = useState({
    sum_total_after_vat: 0,
  })
  const [Payment, setPayment] = useState([]);
  const [errors, setErrors] = useState([]);

  const [Display, setDisplay] = useState(null);


  function close() {
    history.push(`/bill`);
  }
  
  const optionTax = [
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
  ];

  const optionVat = [
    { value: 1, label: '1%' },
    { value: 3, label: '3%' },
    { value: 7, label: '7%' },
  ];

  const PaymentModel = {
    amount: null,
    note: null,
    date: null,
    bank_branche_name: null,
    trace_number: null,
    card_number: null,
    bank: null,
    bank_branches: null,
    account: null,
  }

  const getAuth = async () => {
    try {
      const params = {
        name: ['bill'],
        permission : 'add',
      }
      const response = await dispatch(loadAuth(params));
      if(response.data.data === 'Unauthorized'){
        alert(response.data.message)
        history.push('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  function SetVat(data) {
    setVat({ value: data.value, label: data.value });
    setPackageList({...PackageList, vat: {value: data.value, label: data.value}})
  }

  function SetTax(data) {
    setTax({ value: data.value, label: data.value });
    setPackageList({...PackageList, tax: {value: data.value, label: data.value}})
  }

  function SetSearchPayer(data) {
    setSearchPayer({ value: data.value, label: data.value });
  }
  /////////////////Modal/////////////////////
  const [showTransfer, setShowTransfer] = useState(false);

  const handleShowTransfer = () => setShowTransfer(true);
  const closeModalTransfer = () => {
    setShowTransfer(false);
  };

  const [showCash, setShowCash] = useState(false);

  const handleShowCash = () => setShowCash(true);
  const closeModalCash = () => {
    setShowCash(false);
  };

  const [showSlip, setShowSlip] = useState(false);

  const handleShowSlip = () => setShowSlip(true);
  const closeModalSlip = () => {
    setShowSlip(false);
  };

  const [showCheck, setShowCheck] = useState(false);

  const handleShowCheck = () => setShowCheck(true);
  const closeModalCheck = () => {
    setShowCheck(false);
  };

  const [showEditPayer, setShowEditPayer] = useState(false);

  const closeModalEditPayer = () => {
    setShowEditPayer(false);
  };

  const [showfinishPayment, setShowFinishPayment] = useState(false);
  const [showAlert, setshowAlert] = useState(false)
  const [vat7_active, setVat7Active] = useState(false)
  const [vat7Total, setVat7Total] = useState(0)
  const handleShowAlert = () => setshowAlert(true);

  const closeModalFinishPayment = () => {
    setShowFinishPayment(false);
  };

  const closeModalAlert = () => {
    setshowAlert(false);
  };

  const optionDiscount = [
    { value: 'percent', label: 'ส่วนลด(เปอร์เซ็น)' },
    { value: 'baht', label: 'ส่วนลด(บาท)' },
  ];


  const [Discount, setDiscount] = useState(
    { value: 'baht', label: 'ส่วนลด(บาท)' }
  );

  const [DiscountType,setDiscountType] = useState(
    { value: 'baht', label: 'ส่วนลด(บาท)' }
  )

  const [view_placeholder, Setview_placeholder] = useState('%');

  const [Init, setInit] = useState(false);
  
  function SetDiscount(data) {
    setDiscount({ value: data.value, label: data.label });
    setPackageList({...PackageList, discount_type_list: data.value})
  }

  function SetDiscountType(data){
    setDiscountType({ value: data.value, label: data.label });
    setPackageList({...PackageList, discount_type: data.value})
  }

  function SetVatActive(data){
    setPackageList({...PackageList, vat_active: data.target.checked})
  }

  function SetVat7Active(data){
    setVat7Active(data.target.checked)
    let result = DisplayVat7Calculate();
    if(Number(result) > 0){
      setVat7Total(Number(result));
    }
    // DisplayOutstandingBalance(PackageList)
  }

  function SetTaxActive(data){
    setPackageList({...PackageList, tax_active: data.target.checked})
  }

  const handleSubmitTranfer = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    
    if (form.checkValidity()) {
        SetPaymentMethod(Payment,'tranfer');closeModalTransfer()
        setValidated(false);
    }

  };

  const handleSubmitSlip = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    
    if (form.checkValidity()) {
        SetPaymentMethod(Payment,'slip');closeModalSlip()
        setValidated(false);
    }

  };

  const handleSubmitCheck = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    
    if (form.checkValidity()) {
        SetPaymentMethod(Payment,'check');closeModalCheck()
        setValidated(false);
    }

  };

  async function handleSubmitRequestApprovedDiscount(){
    setLoading(true)
    
    let formData = new FormData();
      formData.append("bill_id", PackageList.bill_id);
      formData.append("payer_by_id", PackageList.payer_by_id);
      formData.append("job_id", PackageList.job_id);
      formData.append('discount_list_type', Discount.value);
      formData.append('discount_type', DiscountType.value);
      formData.append("discount_total", PackageList.discount_total);
      formData.append("discount_approved", PackageList.discount_approved);
      formData.append("discount_request_approved", PackageList.discount_request_approved);
      formData.append("discount_total_calculate", PackageList.discount_total_calculate);
      for (let i = 0; i < PackageList.StorageList.length; i++) {
        formData.append(`package_list[${i}]`, JSON.stringify(PackageList.StorageList[i]));
      }

      try {
         // use id to  sending request discount
        const response = await dispatch(SendRequestApprovedDiscount(formData));
        if(response){
            // history.push('/bill');
            // GetBill()
          }
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  function handleSubmitPaymethod(){
    //check discount_approved = approved
    if(PackageList.discount_approved_num !== PackageList.discount_approved_num){
      alert('test')
    }
    
    setLoading(true)
      // console.log('payment',PackageList);
      let ssum_total_after_vat = 0;
      if(Job.use_sprice == 1){
        ssum_total_after_vat = Job.total_price;
      }else{
        ssum_total_after_vat = PackageList.sum_total_after_vat;
      }
      if(vat7Total > 0){
        ssum_total_after_vat = Number(ssum_total_after_vat) + Number(vat7Total);
      }

      let formData = new FormData();
      formData.append("bill_id", PackageList.bill_id);
      formData.append("payer_by_id", PackageList.payer_by_id);
      formData.append("job_id", PackageList.job_id);
      formData.append("cash", JSON.stringify(PackageList.cash ? PackageList.cash : null));
      formData.append("tranfer", JSON.stringify(PackageList.tranfer ? PackageList.tranfer : null));
      formData.append("slip", JSON.stringify(PackageList.slip ? PackageList.slip : null));
      formData.append("check", JSON.stringify(PackageList.check ? PackageList.check : null));
      formData.append('discount_list_type', Discount.value);
      formData.append('discount_type', DiscountType.value);
      formData.append("discount_total", Job.use_sprice == 1 ? Job.discount : PackageList.discount_total);
      formData.append("discount_total_calculate", Job.use_sprice == 1 ? Job.discount : PackageList.discount_total_calculate);
      formData.append("sum_total", Job.use_sprice == 1 ? Job.total : PackageList.sum_total);
      formData.append("sum_total_after_vat", ssum_total_after_vat);
      formData.append("sum_total_grand", Job.use_sprice == 1 ? Job.total_price : PackageList.sum_total_grand);
      formData.append("vat", PackageList.vat.value);
      formData.append("vat_calculate", PackageList.vat_calculate);
      formData.append("tax", PackageList.tax.value);
      formData.append("tax_calculate", PackageList.tax_calculate);
      for (let i = 0; i < PackageList.StorageList.length; i++) {
        formData.append(`package_list[${i}]`, JSON.stringify(PackageList.StorageList[i]));
      }

      // check payment 
      const cash = PackageList?.cash?.amount ? PackageList?.cash?.amount : 0
      const tranfer = PackageList?.tranfer?.amount ? PackageList?.tranfer?.amount : 0
      const slip = PackageList?.slip?.amount ? PackageList?.slip?.amount : 0
      const check = PackageList?.check?.amount ? PackageList?.check?.amount : 0
      const sumTotalPayment = cash + tranfer + slip + check
      if(sumTotalPayment >= PackageList.outstanding_balance || Job.total_price == 0 || sumTotalPayment >= Job.total_price){
        dispatch(CreatePaymentHistory(formData)).then( () => {
          alert('ทำรายการสมบูรณ์')
          // history.push('/bill');
          GetBill();
          setLoading(false)
        }).catch(e => {

        })
      }else{
        alert('กรุณาระบุยอดชำระ');
        // setErrors('กรุณาระบุยอดชำระ');
        handleShowAlert()
        setLoading(false)
        
      }

      
  }

  async function cancelBill(){
    setLoading(true)
    setInit(false)
      try {
        const response = await dispatch(CancelPaidBill(id));
        alert('ยกเลิกบิลนี้เรียบร้อย')
        setTimeout(() => {
          history.push('/bill');
        }, 500)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  async function GetBill(){
    setLoading(true)
    setInit(false)
      try {
        const response = await dispatch(FindBill(id));
      
        setBill(response.data.data);
        const job = response.data.data?.job

        setJob({
          ...Job,
          total_price: response.data.data?.job.total_price,
          use_sprice: response.data.data?.job.use_sprice,
          total: response.data.data?.job.total,
          discount: response.data.data?.job.discount_calculate,
        });
        console.log('job',Job);
        const job_details = response.data.data?.job?.job_details
        if(job_details[0] && job_details[0].discount_type){
          let findDiscount = optionDiscount.find((item) => {
            return item.value === job_details[0].discount_type
          })
          if(findDiscount) SetDiscount(findDiscount)
        }
        let findVat= { value: 0,label: '0%'}
        let findTax= { value: 0,label: '0%'}

        let vat_active = false
        let tax_active = false
        if(numeral(job.vat).value() > 0){
          findVat = optionVat.find((item) => {
            return item.value === numeral(job.vat).value()
          })
          vat_active = numeral(job.vat).value() > 0 ? true : false
        }

        if(numeral(job.tax).value() > 0){
          findTax = optionTax.find((item) => {
            return item.value === numeral(job.tax).value()
          })
          tax_active = numeral(job.tax).value() > 0 ? true : false
        }

        if(job.discount_type){
          let findDiscountType = optionDiscount.find((item) => {
            return item.value === job.discount_type
          })
          if(findDiscountType) SetDiscountType(findDiscountType)
        }

        
        const new_form = []
        const stack_discount_check = []
        const stack_discount_check_equal = [];
        job_details?.map(( item , index ) => {
          if(item.product_id !== null){
            const insert = {
              key: index + 1,
              id : item.id,
              package_id: item.product.id,
              package_name: item.product.name,
              package_code: item.product.code,
              quantity: item.quantity,
              discount: item.discount,
              discount_approved: item.discount_approved,
              discount_approved_num: item.discount_approved_num,
              // discount_is_equal: Number(item.discount) === Number(item.discount_approved_num) ? true : false,
              UnitId : item.product.unit && item.product.unit.id,
              UnitName : item.product.product_unit_sales === null ? '' : item.product.product_unit_sales.unit_sale !== null ? item.product.product_unit_sales.unit_sale.name_sale : '',
              price: item.price,
              form: 'product',
            }
            new_form.push(insert)
          }else if(item.service_id !== null){
            const insert = {
              key: index + 1,
              id : item.id,
              package_id: item.service.id,
              package_name: item.service.name,
              package_code: item.service.code,
              quantity: item.quantity,
              discount: item.discount,
              discount_approved: item.discount_approved,
              discount_approved_num: item.discount_approved_num,
              // discount_is_equal: Number(item.discount) === Number(item.discount_approved_num) ? true : false,
              price: item.price,
              form: 'service',
            }
            new_form.push(insert)
          }else if(item.package_type_id !== null){
             const insert = {
              key: index + 1,
              id : item.id,
              package_id: item.package.id,
              package_name: item.package.name,
              package_code: item.package.code,
              package_type: item.package_type.id ,
              package_type_name: item.package_type.name,
              quantity: item.quantity,
              discount: item.discount,
              discount_approved: item.discount_approved,
              discount_approved_num: item.discount_approved_num,
              // discount_is_equal: Number(item.discount) === Number(item.discount_approved_num) ? true : false,
              price: item.price,
              form: 'package',
            }
            new_form.push(insert)
          }
          if(item.discount_approved === 'pending'){
            stack_discount_check.push(item.discount_approved);
          }
          // if(item.discount_approved !== null && Number(item.discount) !== Number(item.discount_approved_num)){
          //   stack_discount_check_equal.push('not_equal');
          // }
          
        })
        // console.log('Number(job.discount_approved_num)',job);
        setPackageList({...PackageList, StorageList: new_form, 
          vat_active: vat_active, 
          vat: findVat,
          tax: findTax,
          tax_active: tax_active,
          discount_total: job.discount  ? Number(job.discount) : 0,
          discount_approved: job.discount_approved,
          discount_approved_num: job.discount_approved_num  ? Number(job.discount_approved_num) : 0,
          discount_is_equal: Number(job.discount) === Number(job.discount_approved_num) ? true : false,
          discount_request_approved: stack_discount_check.includes("pending"),
          discount_request_approved_total : job.discount_approved === 'pending' ? true : false ,
          discount_request_approved_not_equal: stack_discount_check_equal.includes("not_equal") === true ? true : false,
          bill_id: id, job_id: job.id,
          payer_by_id: job.payer_by_id, status: job.status, status_pay: job.status_pay,
          cash:{ amount : ''}, tranfer:{ amount : ''},  slip:{ amount : ''},  check:{ amount : ''},
          OrderText: job.order_text,
          OrderPay: job.order_pay,
        })
        setCarOwner(response.data.data && response.data.data.job && response.data.data.job.car_owner)
        setLoading(false)
        setInit(true)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  async function GetBanks(){
    setLoading(true)
      const params = {
        active : true,
      }
      try {
        const response = await dispatch(BankList(params));
        setBanks(response.data.data);
        setLoading(false)
      } catch (error) {
        console.error(error);
      } finally {
        console.log('finally')
      }
  }

  function funcSumGrandTotal(data){
    const price = data.price
    const quantity = data.quantity
    const discount = data.discount
    const discount_type = Discount.value

    const sum_price = price * quantity
    let discount_calculate = 0
    let sum_price_discount = 0
    switch (discount_type) {
      case 'percent':
        discount_calculate = discount > 0 ? (sum_price / 100) * discount  : 0
        sum_price_discount = sum_price - discount_calculate 
        break;
      case 'baht':
        discount_calculate = discount
        sum_price_discount = sum_price - discount_calculate
        break;
    
      default:
        break;
    }
    data.discount_calculate = discount_calculate
    data.total = sum_price
    data.grand_total = sum_price_discount
    return numeral(sum_price_discount).format('0,0.00');
  }

  function funcSumTotal(){
    const sum_total = PackageList.StorageList?.map(i => i.grand_total).reduce((prev, curr) => prev + curr, 0)
    PackageList.sum_total = sum_total
    if(Job.use_sprice == 1){
      // console.log(Job.total_price);
      return numeral(Job.total).format('0,0.00')
    }else{
      return numeral(sum_total).format('0,0.00');
    }
  }

  function CheckDiscountTotal(data){
    let discount_approved = ''
    if(data.target.value === '' || data.target.value === 0){
      discount_approved = ''
    }else{
      if(PackageList.discount_approved_num === data.target.value){
        discount_approved = 'approved'
      }else{
        discount_approved = 'pending'
      }
    }
    setPackageList({...PackageList, discount_total: data.target.value > 0 ? data.target.value : '',
     discount_approved: discount_approved,
     discount_request_approved_total : discount_approved === 'pending' ? true : false ,
    })
    
  }

  function CheckDiscountDetails(data,item){
    // console.log('item',item);
    let discount_approved = ''
    if(data.target.value === '' || data.target.value === 0){
      discount_approved = ''
    }else{
      if(item.discount_approved_num === data.target.value){
        discount_approved = 'approved'
      }else{
        discount_approved = 'pending'
      }
    }

    setPackageList(prevState => ({...PackageList,
      StorageList: prevState.StorageList.map(
        el => el.key === item.key ? {...el, discount: data.target.value ? Number(data.target.value) : ''
        ,discount_approved : discount_approved,
       }: el
      )
    }))


  }


  function DisplayDiscountTotalCalculate(Data){
    const discount = Data.discount_total && Data.discount_total > 0 ? Data.discount_total : ''
    const sum_total = Data.sum_total
    let result_discount = []
    if(DiscountType.value === 'percent'){
      result_discount = discount > 0 ? (sum_total / 100) * discount  : 0  
    }else if(DiscountType.value === 'baht'){
      result_discount = discount
    }
    PackageList.discount_total_calculate = result_discount
    return numeral(result_discount).format('0,0.00')
  }

  function funcSumTotalGrand(Data){
    const discount = Data.discount_total && Data.discount_total > 0 ? Data.discount_total : ''
    const sum_total = Data.sum_total
    let result =[]
    let result_discount = []
    if(DiscountType.value === 'percent'){
      result_discount = discount > 0 ? (sum_total / 100) * discount  : 0
      result = sum_total - result_discount       
    }else if(DiscountType.value === 'baht'){
      result_discount = discount
      result = sum_total - result_discount
    }
    Data.discount_total_calculate = result_discount
    Data.sum_total_grand = result
    if(Job.use_sprice == 1){
      // console.log(Job.total_price);
      let tl = Number(Job.total_price - Bill?.job.voucher_discount)
      return numeral(tl).format('0,0.00')
    }else{
      let tl = Number(result - Bill?.job.voucher_discount)
      return numeral(tl).format('0,0.00')
    }
  }

  function DisplayVat7Calculate(Data){
    
    let result = 0
    if(vat7_active === true){

      // console.log({
      //   vat: PackageList.vat.value,
      //   grand: PackageList.sum_total_grand,
      //   cal: PackageList.sum_total_grand * (PackageList.vat.value / 107)
      // })
      //จำนวนเงินได้ที่จ่าย x อัตราภาษีหัก ณ ที่จ่าย / (100 - อัตราภาษีหัก ณ ที่จ่าย)
      // result = PackageList.sum_total_grand - (PackageList.sum_total_grand * (100 - PackageList.vat.value) / 100)
      // Fixed
      if(Job.use_sprice == 1){
        // console.log(Job.total_price);
        result = Job.total_price * 0.07;
      }else{
        result = PackageList.sum_total_grand * 0.07;
      }
      // result = PackageList.sum_total_grand - (PackageList.sum_total_grand * (PackageList.vat.value / 107))
    }else{
      result = 0
    }

    // DisplaySumTotalAfterVat(PackageList);

    // setVat7Total(result);
    // Data.vat_calculate = numeral(result).format('0.00')
    return numeral(result).format('0,0.00')
  }

  function DisplayVatCalculate(Data){
    
    let result = 0
    if(PackageList.vat_active === true){

      console.log({
        vat: PackageList.vat.value,
        grand: PackageList.sum_total_grand,
        cal: PackageList.sum_total_grand * (PackageList.vat.value / 107)
      })
      //จำนวนเงินได้ที่จ่าย x อัตราภาษีหัก ณ ที่จ่าย / (100 - อัตราภาษีหัก ณ ที่จ่าย)
      // result = PackageList.sum_total_grand - (PackageList.sum_total_grand * (100 - PackageList.vat.value) / 100)
      // Fixed
      result = PackageList.sum_total_grand - (PackageList.sum_total_grand * (PackageList.vat.value / 107))
    }else{
      result = 0
    }
    Data.vat_calculate = numeral(result).format('0.00')
    return numeral(result).format('0,0.00')
  }
  
  function DisplayTaxCalculate(Data){
    
    let result = []
    if(Data.tax_active === true){
      result = Data.sum_total_grand * (Data.tax.value / 100)
    }else{
      result = 0
    }
    Data.tax_calculate = numeral(result).format('0.00')
    return numeral(result).format('0,0.00')

  }

  function DisplaySumTotalAfterVat(Data){
    let result = Data.sum_total_grand
    Data.sum_total_after_vat =  numeral(result).format('0.00')
    DisplayOutstandingBalance(PackageList)

    if(Job.use_sprice == 1){
      // console.log(Job.total_price);
      let tl = vat7_active ? Job.total_price + vat7Total : Job.total_price;
      tl = tl - Bill?.job.voucher_discount
      return numeral(tl).format('0,0.00')
    }else{
      let tl = vat7_active ? result + vat7Total : result
      tl = tl - Bill?.job.voucher_discount
      return numeral(tl).format('0,0.00')
    }
  }

  const [OutPut, setOutPut] = useState(0);

  function DisplayOutstandingBalance(Data){
    const total = Number(PackageList.sum_total_after_vat)
    const cash = PackageList && PackageList.cash ? Number(PackageList.cash.amount) : 0
    const tranfer = PackageList && PackageList.tranfer ? Number(PackageList.tranfer.amount) : 0
    const slip = PackageList && PackageList.slip ? Number(PackageList.slip.amount) : 0
    const check = PackageList && PackageList.check ? Number(PackageList.check.amount) : 0
    
    const sum_result = cash + tranfer + slip + check
    let calculate = []
    let change  = 0
    if(sum_result < total){
      calculate = total - sum_result
      change = 0
    }else{
      calculate = 0
      change = numeral(Math.abs(total - sum_result)).format('0.00')
    }
    Data.change = change
    // Data.outstanding_balance = numeral(calculate).format('0.00')
    // console.log('testing', Bill)
    // console.log(Job.total_price);
    if(Job.use_sprice == 1){
      // console.log(Job.total_price);

      let tl = vat7_active ? Job.total_price + vat7Total : Job.total_price;
      tl = tl - Bill?.job.voucher_discount;

      Data.outstanding_balance = numeral(tl).format('0.00')
      return numeral(tl).format('0,0.00')
    }else{
      
      let tl = vat7_active ? calculate + vat7Total : calculate
      tl = tl - Bill?.job.voucher_discount;

      Data.outstanding_balance = numeral(tl).format('0.00')
      return numeral(tl).format('0,0.00')
    }
  }
  
  function SetPaymentMethod(Payment,Type){
    Payment.note = Payment.note ? Payment.note : null
    setPackageList({...PackageList,  [Type]: Payment } );
    setPayment('',[Type])
  }

  useEffect(() => {
    if (Discount.value === 'percent') {
      Setview_placeholder('%');
    } else if (Discount.value === 'baht') {
      Setview_placeholder('บาท');
    } 
    GetBill();
    GetBanks();
  },[])



  useEffect(() => {
    const stack_discount_check = []
    PackageList.StorageList?.map((item) => {
      if(item.discount_approved === 'pending'){
        stack_discount_check.push(item.discount_approved);
      }
    })
    setPackageList({...PackageList, discount_request_approved:stack_discount_check.includes("pending") });
  },[PackageList.StorageList])

  
  const printList = [
    {value: 'บิลเงินสด', label: 'บิลเงินสด', orientation: 'l', id: 'bill-1'},
    {value: 'ใบเสร็จรับเงิน', label: 'ใบเสร็จรับเงิน/ใบกำกับภาษีฉบับเต็ม', orientation: 'p', id: 'bill-2'},
    // {value: 'ใบกำกับภาษีฉบับเต็ม', label: 'ใบกำกับภาษีฉบับเต็ม', orientation: 'l', disabled: true},
    {value: 'ใบเสร็จรับเงินอย่างย่อ', label: 'ใบเสร็จรับเงินอย่างย่อ', orientation: 'l', id: 'bill-3'},
    {value: 'ใบกำกับภาษีอย่างย่อ', label: 'ใบกำกับภาษีอย่างย่อ', orientation: 'l', id: 'bill-4'},
    {value: 'ใบวางบิล', label: 'ใบวางบิล', orientation: 'p', id: 'bill-5'},
    {value: 'ใบส่งของ', label: 'ใบส่งของ', orientation: 'p', id: 'bill-6'},
  ]
  
  const [printFormat, setPrintFormat] = useState('บิลเงินสด');
  
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button type="button" className="button" disabled={Bill && Bill.status_pay !== 'paid'}>
        <img src={Print} /> &nbsp; พิมพ์{ printFormat }
      </Button>
    )
  }, [printFormat]);

  const [waiting, setWaiting] = useState(false);

  const handleAfterPrint = React.useCallback(() => {
  }, []);

  const handleBeforePrint = React.useCallback(() => {
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setWaiting(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setWaiting(false);
        resolve();
      }, 2000);
    });
  }, [setWaiting]);

  const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: true,
		print: async (printIframe) => {
      setWaiting(true);
			const document = printIframe.contentDocument;
			if (document) {
        const selectedChoice = printList.find(item => item.value === printFormat)
        const html = document.getElementById(selectedChoice.id)

        var opt = {
          filename:     `${selectedChoice.label}.pdf`,
          image:        { type: 'png', quality: 1 },
          html2canvas:  { scale: 2, logging: false },
          jsPDF: { unit: 'mm', format: 'a4', putOnlyUsedFonts:true, orientation: selectedChoice.orientation }
        };

        await html2pdf().set(opt).from(html).toPdf().get('pdf').then(function (pdfObj) 
        {
          setWaiting(false);
          pdfObj.autoPrint();
          window.open(pdfObj.output('bloburl'), '_blank');
        });
			}
		}
  });

  const textPayment = (val) => {
    switch (val) {
      case 'cash' :
        return 'เงินสด'
      case 'slip' :
        return 'บัตรเครดิต'
      case 'tranfer' :
        return 'โอนเงิน'
      case 'check' :
        return 'เช็ค'
    }
  };

  const DownloadImage = () => {
    setWaiting(true);
    setTimeout(() => {
      const selectedChoice = printList.find(item => item.value === printFormat)
      const html = document.getElementById(selectedChoice.id)
      htmlToImage.toJpeg(html, { quality: 1 })
      .then(function (dataUrl) {
        setWaiting(false);
        var link = document.createElement('a');
        link.download = `${selectedChoice.label}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
    }, 500);
  }

  useEffect(() => {

    window.Echo = new Echo({
        broadcaster: process.env.REACT_APP_BROADCASTER,
        key: process.env.REACT_APP_BROADCASTER_KEY,
        cluster: process.env.REACT_APP_BROADCASTER_CLUSTER,
        forceTLS: true,
        encryption: true,
        authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          }
        },
    });

  }, []);

  useEffect(() => {
    console.log('Init', Init)
    if (Init) {
      window.Echo.private(`approved-discount.${id}`).listen('ApprovedDiscount', (data) => {
        // console.log('approved-discount', PackageList, data.bill.job);
        
        setPackageList({
          ...PackageList,
          discount_approved: data.bill.job.discount_approved,
          // discount_approved_num:
          discount_is_equal: true,
          discount_request_approved: data.bill.job.discount_approved === "approved",
          // discount_request_approved_not_equal:
          // discount_request_approved_total:
          discount_total: data.bill.job.discount,
          discount_total_calculate: data.bill.job.discount_calculate,
        });

        setJob({
          ...Job,
          total_price: data.bill.job.total_price,
          use_sprice: data.bill.job.use_sprice
        });

        setBill({...Bill, job:{
          ...Bill.job,
            discount_approved: data.bill.job.discount_approved,
            discount_approved_num: data.bill.job.discount_approved_num,
            discount_calculate: data.bill.job.discount_calculate,
            discount_type: data.bill.job.discount_type,
            discount: data.bill.job.discount
          }
        })
        console.log('data.bill.job.discount_approved', data.bill.job.discount_approved)
        if (data.bill.job.discount_approved !== "approved") {
          setPackageList({
            ...PackageList, discount_total: null
          })
        }
        
        setDialogDiscount(true)
      })
    }
  }, [Init]);


  useEffect(() => {
    setOutPut(DisplayOutstandingBalance(PackageList))
  },[DiscountType, PackageList.discount_total,PackageList.vat_active
    ,PackageList.vat,PackageList.tax,PackageList.tax_active,
    PackageList.cash,PackageList.tranfer,PackageList.check,PackageList.slip,
    PackageList.outstanding_balance,showCash,showTransfer,showCheck,showSlip,
    isLoading,Payment.amount,Job
  ]);
  

  return (
    <>
      <Container fluid className="package-container Service Stock Bill bg-mrk">
        <div className="package">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">

              <div id="overlay" className={ waiting ? 'd-block' : ''}>
                <div className='spinner-field'>
                  <div className='spinner-border' role='status'></div>
                </div>
              </div>

              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>Bill &gt; รับชำระ</b>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-header-mobile">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={close}
                >
                  <img src={Back} />
                </button>
                <h5 className="modal-title" id="exampleModalLabel">
                  <img className="logo pr-2" src={BillImage} />
                  <b>Bill &gt; รับชำระ</b>
                </h5>
              </div>
              <div className="modal-body">
                <Row className="g-0 tabsicon justify-content-center ">
                  <TabsIcon />
                </Row>
                <Row className="g-0 set-body-height">
                  <Col className="">
                    <div className="bg-white mt-3 mb-3">
                      <Col className="col-title text-left d-flex align-items-center">
                        <Form.Label className="pl-3 font-weight-bold">
                          ข้อมูลเจ้าของรถ
                        </Form.Label>
                      </Col>
                      <Col className="pt-3 pb-3">
                        <Row className="justify-content-between">
                          <Col xs={12} lg={5}>
                            <Row className="justify-content-center">
                              <Col xs={11} className="mt-3 mb-3">
                                <Row>
                                  <Col xs={5} className="text-right">
                                    <Form.Label>ชื่อ : </Form.Label>
                                  </Col>
                                  <Col className="text-left p-0">
                                    <Form.Label>
                                    {
                                        Bill?.job.car_owner.cus !== null ?
                                        Bill?.job.car_owner.cus.first_name === null && Bill?.job.car_owner.cus.last_name === null ?
                                          Bill?.job.car_owner.cus.organization !== null ?
                                            Bill?.job.car_owner.cus.organization
                                          :
                                          '-'
                                        :
                                          Bill?.job.car_owner.cus?.first_name + ' ' + Bill?.job.car_owner.cus?.last_name +'('+Bill?.job.car_owner.cus?.nickname+')'
                                      : '-'
                                      }
                                    </Form.Label>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs={11} className="mt-3 mb-3">
                                <Row>
                                  <Col xs={5} className="text-right">
                                    <Form.Label>ที่อยู่ : </Form.Label>
                                  </Col>
                                  <Col className="text-left p-0">
                                    <Form.Label>
                                      {`
                                        ${Bill?.job?.customer?.address ? Bill?.job?.customer?.address : ''} 
                                        ${Bill?.job?.customer?.amphure?.name_th ? Bill?.job?.customer?.amphure?.name_th : ''}
                                        ${Bill?.job?.customer?.postcode ? Bill?.job?.customer?.postcode  : ''}
                                      `}
                                      
                                    </Form.Label>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs={11} className="mt-3 mb-3">
                                <Row>
                                  <Col xs={5} className="text-right">
                                    <Form.Label>เบอร์โทรศัพท์ : </Form.Label>
                                  </Col>
                                  <Col className="text-left p-0">
                                    <Form.Label>
                                      {
                                        Bill && Bill.job && Bill.job.customer && Bill.job.customer.phone ? 
                                        <span>{ `${JSON.parse(Bill.job.customer.phone)}` }</span>
                                        : '-' 
                                      }
                                    </Form.Label>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} lg={5}>
                            <Row className="justify-content-center text-left">
                              <Form.Label className="font-weight-bold  mb-3">
                                ข้อมูลผู้ชำระ
                              </Form.Label>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs={11} lg={10} className="bg-gray ">
                                <Row>
                                  <Col xs={12} className="mt-3 mb-3">
                                    <Row>
                                      <Col xs={5} className="text-right">
                                        <Form.Label>ชื่อ : </Form.Label>
                                      </Col>
                                      <Col className="text-left p-0">
                                        <Form.Label>
                                        {
                                        Bill?.job.type !== 'show_cost' ?
                                          Bill?.job.car_owner.cus !== null ?
                                            Bill?.job.car_owner.cus.first_name === null && Bill?.job.car_owner.cus.last_name === null ?
                                              Bill?.job.car_owner.cus.organization !== null ?
                                                Bill?.job.car_owner.cus.organization
                                              :
                                              '-'
                                            :
                                              Bill?.job.car_owner.cus?.first_name + ' ' + Bill?.job.car_owner.cus?.last_name +'('+Bill?.job.car_owner.cus?.nickname+')'
                                          : '-'
                                        : Bill?.job.showroom_contact !== null ?
                                            Bill?.job.showroom_contact.customer?.organization
                                          : Bill?.job.create_by.employee.fullname

                                      }
                                        </Form.Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} className="mt-3 mb-3">
                                    <Row>
                                      <Col xs={5} className="text-right">
                                        <Form.Label>ที่อยู่ : </Form.Label>
                                      </Col>
                                      <Col className="text-left p-0">
                                        <Form.Label>
                                        {`
                                          ${Bill?.job?.payer_by?.address ? Bill?.job?.payer_by?.address : ''}
                                          ${Bill?.job?.payer_by?.amphure?.name_th ? Bill?.job?.payer_by?.amphure?.name_th : ''}
                                          ${Bill?.job?.payer_by?.postcode ? Bill?.job?.payer_by?.postcode : ''}
                                        `}
                                        </Form.Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} className="mt-3 mb-3">
                                    <Row>
                                      <Col xs={5} className="text-right">
                                        <Form.Label>เบอร์โทรศัพท์ :</Form.Label>
                                      </Col>
                                      <Col className="text-left p-0">
                                        <Form.Label>
                                        {
                                          Bill && Bill.job && Bill.job.payer_by && Bill.job.payer_by.phone ? 
                                          <span>{ `${JSON.parse(Bill.job.payer_by.phone)}` }</span>
                                          : '-' 
                                        }
                                        </Form.Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mt-3  col-title text-left d-flex align-items-center">
                        <Form.Label className="pl-3 font-weight-bold">
                          ข้อมูลรถยนต์
                        </Form.Label>
                      </Col>
                      <Col className="">
                        <Row className="pt-5 pb-5 border-bottom">
                          <Form.Label>
                          เลขทะเบียนรถ :  {CarOwner?.license_plate ? CarOwner?.license_plate : '-'}  
                          รายละเอียด : 
                          ยี่ห้อ : {CarOwner?.car_serie?.car_model?.car_brand?.name ? CarOwner?.car_serie?.car_model?.car_brand?.name : '-'}, 
                          รุ่น {CarOwner?.car_serie?.car_model?.model ? CarOwner?.car_serie?.car_model?.model : '-'}, 
                          สี : {CarOwner?.color ? CarOwner?.color : '-'}, 
                          ป้าย : {CarOwner?.plate_type ? CarOwner?.plate_type : '-'}, 
                          จังหวัด : {CarOwner?.province?.name_th ? CarOwner?.province?.name_th : '-'},
                          เลขตัวถัง : {CarOwner?.chassis_number ? CarOwner?.chassis_number : '-'}
                          </Form.Label>
                        </Row>
                        <Row className="pt-4 pb-5 ">
                          <Form.Label className="mt-3 mb-3">ยอดรวม</Form.Label>
                          <h1>{numeral(Bill && Bill.job && Bill.job.total_price).format('0,0.00')}</h1>
                        </Row>
                      </Col>
                      <Col className="p-0  set-height">
                        <table className="table table-responsive table-wash-part ">
                          <thead>
                            <tr className="t-header-package">
                              <th className="text-left">ลำดับ</th>
                              <th className="text-left">รหัสสินค้า</th>
                              <th className="text-left" width="30%">
                                ชื่อสินค้า/บริการ
                              </th>
                              <th className="text-left">จำนวน</th>
                              <th className="text-left">ราคาต่อหน่วย</th>
                              <th className="text-left">
                              <div className="div-select-table">
                                <Select
                                  options={optionDiscount}
                                  placeholder={Discount.label}
                                  isClearable={false}
                                  isSearchable={false}
                                  onChange={(data) => SetDiscount(data)}
                                  className="select-in-table"
                                />
                              </div>
                              </th>
                              <th className="text-left">ราคารวม</th>
                            </tr>
                          </thead>
                          <tbody className="t-body-package ">
                            {
                              PackageList.StorageList?.map((item,index) => {
                                console.log(item)
                                return (
                                  <tr key={`${item.job_code}-${item.id}`}>
                                  <td className="text-left">{index+1}</td>
                                  <td className="text-left">{item.package_code}</td>
                                  <td className="text-left">{item.package_name}</td>
                                  <td className="text-left">
                                    <Form.Control
                                      value={item.quantity}
                                      disabled={true}
                                      onChange={
                                        (data) => {
                                          setPackageList(prevState => ({...PackageList,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? {...el, quantity: data.target.value ? Number(data.target.value) : '' }: el
                                            )
                                          }))
                                        }
                                      }
                                      isDisabled={PackageList.status === 'done' && PackageList.status_pay === 'done' ? false : true}
                                    ></Form.Control>
                                  </td>
                                  <td className="text-left">
                                    <Form.Control
                                      value={item.price}
                                      disabled={Bill.status_pay === 'paid' || Job.use_sprice == 1 ? true : false}
                                      onChange={
                                        (data) => {
                                          setPackageList(prevState => ({...PackageList,
                                            StorageList: prevState.StorageList.map(
                                              el => el.key === item.key ? {...el, price: data.target.value ? Number(data.target.value) : '' }: el
                                            )
                                          }))
                                        }
                                      }
                                      isDisabled={PackageList.status === 'done' && PackageList.status_pay === 'done' ? false : true}
                                    ></Form.Control>
                                  </td>
                                  <td className="text-left">
                                    <Form.Control
                                      type="number"
                                      className="input-in-table"
                                      placeholder={Discount.value === 'percent' ? '%' : 'บาท'}
                                      value={item.discount}
                                      disabled={Bill.status_pay === 'paid' || Job.use_sprice == 1 ? true : false}
                                      onChange={
                                        (data) => {
                                          CheckDiscountDetails(data,item)
                                        }
                                      }
                                      isDisabled={PackageList.status === 'done' && PackageList.status_pay === 'done' ? false : true}
                                    ></Form.Control>
                                  </td>
                                  <td className="text-left">{
                                    funcSumGrandTotal(item)
                                  }</td>
                                </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </Col>
                      <Col className=" mt-5 pb-5">
                        <Row>
                          <Col lg={6}>
                            <Row className="justify-content-center ">
                              <Col
                                xs={10}
                                lg={11}
                                className="bg-gray p-4  mb-3"
                              >
                                <Row className="mt-2 mb-2">
                                  <Col xs={5} className="display-flex">
                                    <Button
                                      type="button"
                                      className="button"
                                      onClick={
                                        () => {handleShowCash();
                                          setPayment(PackageList && PackageList.cash ?PackageList.cash : PaymentModel ,'cash')
                                          setAccountNumbers({...AccountNumbers, account_number: null})
                                        }
                                      }
                                    >
                                      เงินสด
                                    </Button>
                                    <Button
                                      type="button"
                                      className="button"
                                      style={{ maxWidth: 50 }}
                                      onClick={
                                        () =>{
                                          SetPaymentMethod(PaymentModel,'cash');
                                          setPackageList({...PackageList, cash:{ amount : ''}})
                                        }
                                      }
                                    >
                                      <img src={reload} className="icon-job" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      disabled 
                                      value={PackageList && PackageList.cash && PackageList.cash.amount}
                                      max
                                    ></Form.Control>
                                  </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                  <Col xs={5} className="display-flex">
                                    <Button
                                      type="button"
                                      className="button"
                                      onClick={
                                        () => {handleShowTransfer();
                                          setPayment(PackageList && PackageList.tranfer ?PackageList.tranfer : PaymentModel ,'tranfer')
                                          setAccountNumbers({...AccountNumbers, account_number: null})
                                        }
                                      }
                                    >
                                      โอนเงิน
                                    </Button>
                                    <Button
                                      type="button"
                                      className="button"
                                      style={{ maxWidth: 50 }}
                                      onClick={
                                        () =>{
                                          SetPaymentMethod(PaymentModel,'tranfer');
                                          setPackageList({...PackageList, tranfer:{ amount : ''}})
                                        }
                                      }
                                    >
                                      <img src={reload} className="icon-job" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      disabled 
                                      value={PackageList && PackageList.tranfer && PackageList.tranfer.amount}
                                    >
                                    </Form.Control>
                                  </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                  <Col xs={5} className="display-flex">
                                    <Button
                                      type="button"
                                      className="button"
                                      onClick={
                                        () => {handleShowSlip();
                                          setPayment(PackageList && PackageList.slip ?PackageList.slip : PaymentModel ,'slip')
                                          setAccountNumbers({...AccountNumbers, account_number: null})
                                        }
                                      }
                                    >
                                     บัตรเครดิต
                                    </Button>
                                    <Button
                                      type="button"
                                      className="button"
                                      style={{ maxWidth: 50 }}
                                      onClick={
                                        () =>{
                                          SetPaymentMethod(PaymentModel,'slip');
                                          setPackageList({...PackageList, slip:{ amount : ''}})
                                        }
                                      }
                                    >
                                      <img src={reload} className="icon-job" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      disabled 
                                      value={PackageList && PackageList.slip && PackageList.slip.amount}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                  <Col xs={5} className="display-flex">
                                    <Button  onClick={
                                      () => {handleShowCheck();
                                        setPayment(PackageList && PackageList.check ?PackageList.check : PaymentModel ,'check')
                                        setAccountNumbers({...AccountNumbers, account_number: null})
                                      }
                                    } type="button" className="button">
                                      เช็ค
                                    </Button>
                                    <Button
                                      type="button"
                                      className="button"
                                      style={{ maxWidth: 50 }}
                                      onClick={
                                        () =>{
                                          SetPaymentMethod(PaymentModel,'check');
                                          setPackageList({...PackageList, check:{ amount : ''}})
                                        }
                                      }
                                    >
                                      <img src={reload} className="icon-job" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      disabled 
                                      value={PackageList && PackageList.check && PackageList.check.amount}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="justify-content-center ">
                              <Col
                                xs={10}
                                lg={11}
                                className="bg-gray p-4  mb-3"
                                >
                                  {
                                    Bill?.payment_histories.length > 0 ?
                                    <>
                                      <Row className="mt-2 mb-2">
                                        <Col xs={5}>
                                            วันที่ชำระ
                                        </Col>
                                        <Col>
                                          <Form.Label>
                                            {
                                              Bill?.payment_histories.map((item) => {
                                                return (
                                                  dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')
                                                )
                                              })
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                      <Row className="mt-2 mb-2">
                                        <Col xs={5}>
                                          รายงานลงข้อมูล
                                        </Col>
                                        <Col>
                                          <Form.Label>
                                            {
                                              Bill?.report_paytype
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                      <Row className="mt-2 mb-2">
                                        <Col xs={5}>
                                            ช่องทางการชำระ
                                        </Col>
                                        <Col>
                                          <Form.Label>
                                            {
                                              Bill?.payment_histories.map((item) => {
                                                return (
                                                  <>
                                                    { textPayment(item.payment_method) }<br></br>
                                                    {item.payment_method == 'tranfer'? '(' + item.bank.name + '-' +  item.bank_branch.name + ')' : ''}
                                                  </>
                                                )
                                              })
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                      <Row className="mt-2 mb-2">
                                        <Col xs={5}>
                                            ยอดชำระ
                                        </Col>
                                        <Col>
                                          <Form.Label>
                                            {
                                              Bill?.payment_histories.map((item) => {
                                                return (
                                                  item.take_money
                                                )
                                              })
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                    </>
                                      
                                    : 
                                    <Row className="mt-2 mb-2">
                                      <Col xs={5}>
                                          ยอดค้างชำระ
                                      </Col>
                                      <Col>
                                        <Form.Label>
                                          {/* { DisplayOutstandingBalance(PackageList) } */}
                                          { OutPut }
                                        </Form.Label>
                                      </Col>
                                    </Row>
                                  }
                                  {/* <Row className="mt-2 mb-2">
                                    <Col xs={5}>
                                        เงินทอน
                                    </Col>
                                    <Col>
                                      <Form.Label>
                                        { PackageList.change }
                                      </Form.Label>
                                    </Col>
                                  </Row> */}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row className="justify-content-center ">
                              <Col xs={11} lg={11} className="bg-gray p-4  ">
                                <Row className="g-0  mb-3 justify-content-between">
                                  <Col className="text-left">
                                    <Form.Label>รวมเป็นเงิน </Form.Label>
                                  </Col>
                                  <Col className="text-right">
                                    <Form.Label>{funcSumTotal()} บาท</Form.Label>
                                  </Col>
                                </Row>
                                <Row className="g-0 mt-4 mb-4 justify-content-between">
                                  <Col className="text-left">
                                    <Form.Label>ส่วนลดรวม</Form.Label>
                                  </Col>
                                  <Col className="text-right" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                    <Select
                                      options={optionDiscount}
                                      isClearable={false}
                                      isSearchable={false}
                                      onChange={(data) => SetDiscountType(data)}
                                      value={DiscountType}
                                      className="select-list"
                                    />
                                  </Col>
                                  <Col className="text-right" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                    <Form.Control
                                      type="number"
                                      className="input-in-table"
                                      placeholder={DiscountType.label}
                                      value={PackageList.discount_total ? PackageList.discount_total : ''}
                                      disabled={Bill && Bill.status_pay === 'paid' ? true : false}
                                      onChange={
                                        (data) => {
                                          CheckDiscountTotal(data)
                                        }
                                      }
                                  ></Form.Control>
                                  </Col>
                                  <Col className="text-right">
                                  <Form.Label>{DisplayDiscountTotalCalculate(PackageList)}</Form.Label>
                                  </Col>
                                </Row>
                                <Row className="g-0 mt-4 mb-4 justify-content-between">
                                  <Col className="text-left">
                                    <Form.Label>ส่วนลดจากคูปอง</Form.Label>
                                  </Col>
                                  <Col className="text-right">
                                    <Form.Label>{ numeral(Bill?.job?.voucher_discount).format('0,0.00') }</Form.Label>
                                  </Col>
                                </Row>
                                <Row className="g-0 mt-4 mb-4 justify-content-between">
                                  <Col className="text-left">
                                    <Form.Label>ราคาหลังหักส่วนลด</Form.Label>
                                  </Col>
                                  <Col className="text-right">
                                    <Form.Label>{funcSumTotalGrand(PackageList)}</Form.Label>
                                  </Col>
                                </Row>
                                <Col className="border-bottom p-0 mt-4 mb-4"></Col>
                                <Row className="justify-content-between align-items-center" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                  <Col className="text-left  mt-3 mb-3">
                                    <div key={`inline-checkbox`}>
                                      <Row>
                                        <Col md={5} lg={7} xl={5} className="d-flex">
                                          <Form.Check
                                            inline
                                            label="ภาษีมูลค่าเพิ่ม"
                                            name="group_vat"
                                            type='checkbox'
                                            id={`inline-checkbox-vat`}
                                            className="checkbox-input"
                                            disabled={(Bill && Bill.status_pay === 'paid') || vat7_active  ? true : false}
                                            onChange={(data) => SetVatActive(data)}
                                            checked={PackageList.vat_active}
                                          />
                                        </Col>
                                        <Col xs={8} md={5} className="mt-1 mb-1">
                                          <Select
                                            options={optionVat}
                                            isClearable={false}
                                            isSearchable={false}
                                            isDisabled={PackageList.vat_active === true ? false : true}
                                            disabled={Bill && Bill.status_pay === 'paid' ? true : false}
                                            onChange={(data) => SetVat(data)}
                                            value={PackageList.vat}
                                            className="select-list"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col xs={3} className="text-right  mt-3 mb-3">
                                    <p>{DisplayVatCalculate(PackageList)}</p>
                                  </Col>
                                </Row>
                                {/* <Row className="justify-content-between align-items-center">
                                  <Col className="text-left  mt-3 mb-3">
                                    <div key={`inline-checkbox`}>
                                      <Row>
                                        <Col md={9} lg={9} xl={9} className="d-flex">
                                          <Form.Check
                                            inline
                                            label="ภาษีนอก 7%"
                                            name="7_vat"
                                            type='checkbox'
                                            id={`inline-checkbox-vat`}
                                            className="checkbox-input"
                                            disabled={(Bill && Bill.status_pay === 'paid') || PackageList.vat_active ? true : false}
                                            onChange={(data) => SetVat7Active(data)}
                                            checked={vat7_active}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col xs={3} className="text-right  mt-3 mb-3">
                                    <p>{DisplayVat7Calculate(PackageList)}</p>
                                  </Col>
                                </Row> */}
                                <Row className="justify-content-between mt-4 mb-4 align-items-center" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                  <Col xs={8} className="text-left  ">
                                    <div key={`inline-checkbox`}>
                                        <Row>
                                          <Col
                                            xs={12}
                                            md={5}
                                            lg={7}
                                            xl={7}
                                            className="d-flex"
                                          >
                                            <Form.Check
                                              inline
                                              label="หักภาษี ณ ที่จ่าย"
                                              name="group1"
                                              type='checkbox'
                                              id={`inline-checkbox-1`}
                                              className="checkbox-input"
                                              onChange={(data) => SetTaxActive(data)}
                                              checked={PackageList.tax_active}
                                              disabled={Bill && Bill.status_pay === 'paid' ? true : false}

                                            />
                                          </Col>
                                          <Col
                                            xs={8}
                                            md={5}
                                            className="mt-1 mb-1"
                                          >
                                            <Select
                                              options={optionTax}
                                              isClearable={false}
                                              isSearchable={false}
                                              isDisabled={PackageList.vat_active === true ? false : true}
                                              onChange={(data) => SetTax(data)}
                                              value={PackageList.tax}
                                              className="select-list"
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                  </Col>
                                  
                                  <Col className="text-right">
                                    <Form.Label>{DisplayTaxCalculate(PackageList)} บาท</Form.Label>
                                  </Col>
                                </Row>
                                <Row className="g-0 mt-5 mb-3 justify-content-between">
                                  <Col className="text-left">
                                    <Form.Label>รวมจำนวนทั้งสิ้น</Form.Label>
                                  </Col>
                                  <Col className="text-right">
                                    <Form.Label>{DisplaySumTotalAfterVat(PackageList)} บาท</Form.Label>
                                  </Col>
                                </Row>

                                <Row className="justify-content-between" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                  <Col className="text-left  mt-3 mb-3">
                                    <Row>
                                      <Col>
                                        <Form.Label>ใบสั่ง</Form.Label>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          type="number"
                                          className="input-in-table"
                                          value={PackageList.OrderText}
                                          disabled={Bill && Bill.status_pay === 'paid' ? true : false}
                                          onChange={
                                            (data) => {
                                              setPackageList({...PackageList, OrderText: data.target.value ? data.target.value : ''})
                                            }
                                          }
                                      ></Form.Control>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className="text-right mt-3 mb-3 ">
                                    
                                  </Col>
                                </Row>

                                <Row className="justify-content-between" className={Job.use_sprice == 1 ? 'd-none' : ''}>
                                  <Col className="text-left  mt-3 mb-3">
                                    <Row>
                                      <Col>
                                        <Form.Label>จ่ายเอง</Form.Label>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          type="number"
                                          className="input-in-table"
                                          // placeholder={Data.label}
                                          value={PackageList.OrderPay}
                                          disabled={Bill && Bill.status_pay === 'paid' ? true : false}
                                          onChange={
                                            (data) => {
                                              setPackageList({...PackageList, OrderPay: data.target.value ? data.target.value : ''})
                                            }
                                          }
                                      ></Form.Control>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className="text-right mt-3 mb-3 ">
                                    
                                  </Col>
                                </Row>

                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="pb-5 mb-5">
                        <Row className="justify-content-between">
                          <Col xs={12} md={9} className="mb-3">
                            <Row>
                              
                              <Col xs={4} md={4} lg={4}>
                                {/* { Bill && Bill.status_pay === 'paid' ? true : false || isLoading  === true ? 'true' : 'false'} */}
                                {
                                  (PackageList.discount_request_approved === true || PackageList.discount_request_approved_total === true) && Bill && Bill.status_pay == 'discount_approved' ? (
                                    <Button
                                      type="button"
                                      className="button"
                                      disabled={isLoading}
                                      onClick={() => {handleSubmitRequestApprovedDiscount()}}
                                    >
                                      <img src={Money} /> &nbsp; ขออนุมัติส่วนลด
                                  </Button>
                                  ) : (
                                    Bill && Bill.status_pay === 'paid' ? (
                                          <Button
                                          type="button"
                                          className="button btn-disabled"
                                          // onClick={handleShowFinishPayment}
                                          disabled={isLoading}
                                          isDisabled={isLoading}
                                          onClick={() => {cancelBill()}}
                                        >
                                          <img src={Return} /> &nbsp; ยกเลิกการชำระเงิน
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="button btn-disabled"
                                          // onClick={handleShowFinishPayment}
                                          disabled={ Bill && Bill.status_pay === 'paid' ? true : false || isLoading}
                                          isDisabled={PackageList.status === 'done' && PackageList.status_pay === 'done' ? true : false || isLoading}
                                          onClick={() => {handleSubmitPaymethod()}}
                                        >
                                          <img src={Money} /> &nbsp; ชำระเงิน
                                        </Button>
                                      )
                                  )
                                }
                                
                               
                              </Col>
                              <Col xs={4} md={4} lg={4}>
                                <Select
                                  className="text-left select-style select-list"
                                  options={printList}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  isOptionDisabled={(option) => option.disabled}
                                  isClearable={false}
                                  value={printFormat ? printList.find(item => item.value === printFormat) : null}
                                  isDisabled={Bill && Bill.status_pay !== 'paid'}
                                  onChange={(item) => setPrintFormat(item.value)}
                                />
                              </Col>
                              <Col xs={4} md={4} lg={4}>
                                {
                                  ['ใบเสร็จรับเงินอย่างย่อ'].includes(printFormat) &&
                                  <Button type="button" className="button" disabled={Bill && Bill.status_pay !== 'paid'} onClick={() => DownloadImage()}>
                                    ดาวน์โหลด{ printFormat }
                                  </Button>
                                }
                                {
                                  ['บิลเงินสด', 'ใบกำกับภาษีอย่างย่อ', 'ใบส่งของ', 'ใบวางบิล', 'ใบเสร็จรับเงิน'].includes(printFormat) ?
                                  <Button type="button" className="button" disabled={Bill && Bill.status_pay !== 'paid'} onClick={() => handlePrint()}>
                                    <img src={Print} /> &nbsp; พิมพ์{ printFormat }
                                  </Button>
                                  : ''
                                }

                                {
                                  !['บิลเงินสด', 'ใบเสร็จรับเงินอย่างย่อ', 'ใบส่งของ', 'ใบวางบิล', 'ใบเสร็จรับเงิน', 'ใบกำกับภาษีอย่างย่อ'].includes(printFormat) &&
                                  <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle="Bill"
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                  />
                                }

                                {
                                  Bill && printFormat === 'บิลเงินสด' &&
                                  <div style={{ display: `none` }}>
                                    <Bill1 Bill={Bill} ref={componentRef} />
                                  </div>
                                }

                                {
                                  Bill && printFormat === 'ใบเสร็จรับเงิน' &&
                                  <div style={{ display: `none` }}>
                                    <Bill2 Bill={Bill} Set={6} ref={componentRef} />
                                  </div>
                                }

                                {
                                  Bill && printFormat === 'ใบเสร็จรับเงินอย่างย่อ' &&
                                  <div style={{ display: `${waiting ? 'block' : 'none'}`, position: 'absolute', zIndex: '-10' }}>
                                    <Bill3 Bill={Bill} />
                                  </div>
                                }

                                {
                                  Bill && printFormat === 'ใบกำกับภาษีอย่างย่อ' &&
                                  <div style={{ display: 'none' }}>
                                    <Bill4 Bill={Bill} ref={componentRef} />
                                  </div>
                                }

                                {
                                  Bill && printFormat === 'ใบวางบิล' &&
                                  <div style={{ display: 'none' }}>
                                    <Bill5 Bill={Bill} ref={componentRef} />
                                  </div>
                                }

                                {
                                  Bill && printFormat === 'ใบส่งของ' &&
                                  <div style={{ display: 'none' }}>
                                    <Bill6 Bill={Bill} ref={componentRef} />
                                  </div>
                                }

                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="justify-content-end">
                              <Col md={6} lg={5}>
                                <Button type="button" onClick={close} className="button">
                                  <img src={Return} /> &nbsp; ออก
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                </Row>
                
                {/* <Row>
                  <Col lg={12}>Dispaly For Fill Data</Col>
                  <Col lg={12}>
                  {
                    Bill && 
                    <Bill2 Bill={Bill} ref={componentRef} />
                  }
                  </Col>
                </Row> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
      
      <Footer />
      {/* เงินสด */}
      <Modal
        className="modal-style"
        centered
        show={showCash}
        onHide={setShowCash}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setPayment('','cash');closeModalCash()
            }}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row className="justify-content-center ">
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-5 mb-5">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">เงินสด</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3">
                    <Form.Control className="form-control-order"
                      value={Payment && Payment.amount}
                      type="number"
                      onChange={(data) => {
                        setPayment({...Payment, 
                          amount: data.target.value ? Math.max(0, Math.min(Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance, Number(data.target.value) )) : ''
                        } )
                      }}
                    >

                    </Form.Control>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="mt-4 mb-5">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button"
                      onClick={() => {
                        SetPaymentMethod(Payment,'cash');closeModalCash()
                      }}
                    >
                      ตกลง
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button 
                      onClick={() => {
                        setPayment('','cash');closeModalCash()
                      }}
                      type="button" className="button">
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* โอนเงิน */}
      <Modal
        className="modal-style"
        centered
        show={showTransfer}
        onHide={setShowTransfer}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setPayment('','tranfer');closeModalTransfer();
            }}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={validated} onSubmit={handleSubmitTranfer} >
            <Row className="justify-content-center ">
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-3">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ยอดโอน</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3">
                    <Form.Control className="form-control-order"
                      value={Payment && Payment.amount}
                      type="number"
                      max={Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance}
                      required
                      onChange={(data) => {
                        setPayment({...Payment, 
                          amount: data.target.value ? Math.max(0, Math.min(Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance, Number(data.target.value) )) : ''
                        } )
                      }}
                    >
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">วันที่</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3">
                    <DatePicker
                      value={Payment && Payment.date}
                      required
                      className={`select-list ` + (!Payment.date && validated ? 'validate-fail' : '') }
                      onChange={(data) => {
                        setPayment({...Payment, 
                          date: data
                        } )
                      }}
                      placeholder=""
                      suffixIcon={<img src={Calendar} />}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ธนาคาร</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Banks}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={true}
                      isClearable={false}
                      value={Payment && Payment.bank}
                      required
                      onChange={(data) => {
                        setPayment({...Payment, 
                          bank: data,
                        } );
                        setBranchs(data.branches)
                      }}
                      className={`select-list ` + (!Payment.bank && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">สาขา</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Branchs}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      required
                      isSearchable={false}
                      isClearable={false}
                      value={Payment && Payment.bank_branches}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          bank_branches: data
                        });
                        setAccounts(data.accounts);
                      }}
                      className={`select-list ` + (!Payment.bank_branches && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      ชื่อบัญชี
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Accounts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={false}
                      isClearable={false}
                      value={Payment && Payment.account}
                      required
                      onChange={(data) => {
                        setPayment({...Payment, 
                          account: data
                        } )
                        setAccountNumbers(data);
                      }}
                      className={`select-list ` + (!Payment.account && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      เลขที่บัญชี
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Form.Label className="set-text-height">
                      { 
                        Payment.account && Payment.account.account_number ? Payment.account.account_number :  AccountNumbers.account_number
                      }
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="">
                  <Col xs={4} lg={3} className="text-left mt-3" >
                    <Form.Label className="set-text-height">
                      หมายเหตุ
                    </Form.Label>
                  </Col>
                  <Col xs={8} lg={8} className="p-0 pr-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-control-area"
                      onChange={(data) => {
                        setPayment({...Payment, 
                          note: data.target.value
                        } )
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="mt-4 mb-5">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button" 
                     >
                      ตกลง
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button"
                      onClick={() => {
                        setPayment('','tranfer');closeModalTransfer();
                        setValidated(false)
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* สลีป */}
      <Modal
        className="modal-style"
        centered
        show={showSlip}
        onHide={setShowSlip}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setPayment('','slip');closeModalSlip()
            }}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={validated} onSubmit={handleSubmitSlip}> 
            <Row className="justify-content-center ">
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-3">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ยอดชำระ</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3">
                    <Form.Control 
                      value={Payment && Payment.amount}
                      type="number"
                      max={Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          amount: data.target.value ? Math.max(0, Math.min(Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance, Number(data.target.value) )) : ''
                        } );
                      }}
                      required
                      className={`form-control-order select-list ` + (!Payment.amount && validated ? 'validate-fail' : '') }
                    ></Form.Control>
                    
                    
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ธนาคาร</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Banks}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={true}
                      isClearable={false}
                      value={Payment && Payment.bank}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          bank: data,
                        } );
                        setBranchs(data.branches)
                      }}
                      required
                      className={`select-list ` + (!Payment.bank && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">สาขา</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Branchs}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={false}
                      isClearable={false}
                      value={Payment && Payment.bank_branches}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          bank_branches: data
                        });
                        setAccounts(data.accounts);
                      }}
                      required
                      className={`select-list ` + (!Payment.bank_branches && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      ชื่อบัญชี
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Accounts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={false}
                      isClearable={false}
                      value={Payment && Payment.account}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          account: data
                        } )
                        setAccountNumbers(data);
                      }}
                      required
                      className={`select-list ` + (!Payment.account && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      เลขที่บัญชี
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Form.Label className="set-text-height">
                      { 
                        Payment.account && Payment.account.account_number ? Payment.account.account_number :  AccountNumbers.account_number
                      }
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      Trace No.
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 ">
                    <Form.Control 
                      required
                      className={`form-control-order select-list ` + (!Payment.trace_number && validated ? 'validate-fail' : '') }
                      onChange={(data) => {
                        setPayment({...Payment, 
                          trace_number: data.target.value,
                        } );
                      }}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      Card No.
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 ">
                    <Form.Control 
                      required
                      className={`form-control-order select-list ` + (!Payment.card_number && validated ? 'validate-fail' : '') }
                      onChange={(data) => {
                        setPayment({...Payment, 
                          card_number: data.target.value,
                        } );
                      }}
                    ></Form.Control>
                  </Col>
                </Row>

                <Row className="">
                  <Col xs={4} lg={3} className="text-left mt-3" >
                    <Form.Label className="set-text-height">
                      หมายเหตุ
                    </Form.Label>
                  </Col>
                  <Col xs={8} lg={8} className="p-0 pr-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-control-area"
                      onChange={(data) => {
                        setPayment({...Payment, 
                          note: data.target.value
                        } )
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="mt-4 mb-5">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button" >
                      ตกลง
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button"
                      onClick={() => {
                        setPayment('','slip');closeModalSlip()
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* check */}
      <Modal
        className="modal-style"
        centered
        show={showCheck}
        onHide={setShowCheck}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setPayment('','check');closeModalCheck()
            }}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form noValidate validated={validated} onSubmit={handleSubmitCheck}>
            <Row className="justify-content-center ">
              <Col xs={12} lg={7}>
                <Row className="align-items-center mt-3">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ยอดชำระ</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3">
                    <Form.Control 
                      required
                      className={`form-control-order select-list ` + (!Payment.amount && validated ? 'validate-fail' : '') }
                      value={Payment && Payment.amount}
                      type="number"
                      max={Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          amount: data.target.value ? Math.max(0, Math.min(Job.use_sprice == 1 ? Job.total_price : PackageList.outstanding_balance, Number(data.target.value) )) : ''
                        } );
                      }}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">ธนาคาร</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Select
                      options={Banks}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder=""
                      isSearchable={true}
                      isClearable={false}
                      value={Payment && Payment.bank}
                      onChange={(data) => {
                        setPayment({...Payment, 
                          bank: data,
                        } );
                        setBranchs(data.branches)
                      }}
                      required
                      className={`select-list ` + (!Payment.bank && validated ? 'validate-fail' : '') }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">สาขา</Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 mt-2 mb-2">
                    <Form.Control 
                        onChange={(data) => {
                          setPayment({...Payment, 
                            bank_branche_name: data.target.value,
                          } );
                        }}
                        required
                      className={`form-control-order select-list ` + (!Payment.bank_branche_name && validated ? 'validate-fail' : '') }
                      ></Form.Control>
                  </Col>
                </Row>
                {/* <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      ผู้สั่งจ่าย
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 ">
                    <Form.Control 
                      onChange={(data) => {
                        setPayment({...Payment, 
                          trace_number: data.target.value,
                        } );
                      }}
                      required
                      className={`select-list ` + (!Payment.bank_branche_name && validated ? 'validate-fail' : '') }
                    ></Form.Control>
                  </Col>
                </Row> */}
                <Row className="align-items-center ">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      หมายเลข
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 ">
                    <Form.Control 
                      onChange={(data) => {
                        setPayment({...Payment, 
                          card_number: data.target.value,
                        } );
                      }}
                      required
                      className={`form-control-order select-list ` + (!Payment.card_number && validated ? 'validate-fail' : '') }
                    ></Form.Control>
                  </Col>
                </Row>

                <Row className="">
                  <Col xs={4} lg={3} className="text-left mt-3" >
                    <Form.Label className="set-text-height">
                      เช็คลงวันที่
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="p-0 pr-3 ">
                    <DatePicker
                        value={Payment && Payment.date}
                        onChange={(data) => {
                          setPayment({...Payment, 
                            date: data
                          } )
                        }}
                        placeholder=""
                        suffixIcon={<img src={Calendar} />}
                        required
                      className={`form-control-order select-list ` + (!Payment.date && validated ? 'validate-fail' : '') }
                      />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="mt-4 mb-5">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="submit" className="button" >
                      ตกลง
                    </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button"
                      onClick={() => {
                        setPayment('','check');closeModalCheck()
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* แก้ไขผู้ชำระ */}
      <Modal
        className="modal-style"
        centered
        show={showEditPayer}
        onHide={setShowEditPayer}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModalEditPayer}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Form>
            <Row className="justify-content-center ">
              <Col xs={12} lg={7} className="mt-5 mb-5">
                <Row className="align-items-center mt-2">
                  <Col xs={4} lg={3} className="text-left">
                    <Form.Label className="set-text-height">
                      ชื่อผู้ชำระ
                    </Form.Label>
                  </Col>
                  <Col xs={12} lg={9} className="p-0 pr-3 pl-3">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={false}
                      // options={optionSearchPayer}
                      placeholder="ค้นหาโดยชื่อลูกค้า,เลขทะเบียน"
                      onChange={(data) => SetSearchPayer(data)}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg={7} className="mt-4 mb-5">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    {/* <PDFDownloadLink document={<PDFBill />} fileName="Bill">
                      {({ loading }) => 
                        loading ? (
                          <Button type="button" className="button">กำลังรอดาวโหลดไฟล์</Button>
                        ) : (
                      <Button type="button" className="button" >
                        ตกลง
                      </Button>
                        )
                      }
                    </PDFDownloadLink> */}
                    <Button type="button" className="button" >
                        ตกลง
                      </Button>
                  </Col>
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button"  onClick={closeModalEditPayer}>
                      ยกเลิก
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* อนุมัติส่วนลด */}
      <Modal
        className="modal-style"
        centered
        show={showfinishPayment}
        onHide={setShowFinishPayment}
      >
        <Modal.Header className="modal-header-style">
          <p>
            <b>PALYBLE AMOUNT</b>
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModalFinishPayment}
          >
            <span aria-hidden="true">X</span>
          </button>
        </Modal.Header>
        <Modal.Body className="body-set-height">
          <Row
            className="justify-content-center  align-items-center"
            style={{ height: '200px' }}
          >
            <Col xs={12} lg={7} className="mt-2 mb-2">
              <Row className="align-items-center mt-2">
                <Form.Label className="font-weight-bold">
                  ส่งคำขอส่วนลดของคุณแล้ว
                </Form.Label>
              </Row>
            </Col>

            <Col xs={12} lg={7} className="">
              <Row className="align-items-center justify-content-center">
                <Col xs={6} lg={5}>
                  <Button type="button" className="button">
                    ตกลง
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* alert */}
      {/* <Modal
        className="modal-style"
        centered
        show={showAlert}
        onHide={() => closeModalAlert}
      >
         <Modal.Body className="body-set-height">
            <ToastContainer position='top-center'>
                <Toast show={errors.length > 0 } delay={2500} bg='danger' autohide onClose={() => setErrors([])}>
                  <Toast.Header>
                    <strong className="me-auto">แจ้งเตือน</strong>
                  </Toast.Header>
                  <Toast.Body>{ errors }</Toast.Body>
                </Toast>
            </ToastContainer>
          </Modal.Body>
      </Modal> */}
      <Modal
        className="modal-style"
        centered
        show={DialogDiscount}
        onHide={ () => setDialogDiscount(false) }
      >
        <Modal.Header className="modal-header-style">
          <p>
            {
              `
                คำขออนุมัติส่วนลด
                หมายเลขบิล -
                ${Bill?.bill_code}
              `
            }
          </p>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ () => setDialogDiscount(false) }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <Modal.Body className="body-set-height">
          <Form noValidate validated={true}>
            <Row
              className="justify-content-center  align-items-center"
              style={{ height: '150px' }}
            >
              <Col xs={10} lg={10} className="mt-2 ">
                {
                  Bill && Bill.job && Bill.job.discount_approved && <h4>{Bill.discount_approved}</h4>
                }
                {
                  Bill && Bill.job && Bill.job.discount_approved === 'approved' && <h4>อนุมัติส่วนลด</h4>
                }
                {
                  Bill && Bill.job && Bill.job.discount_approved === 'rejected' && <h4>ไม่อนุมัติส่วนลด</h4>
                }
                
                {/* <h4>ไม่อนุมัติส่วนลด</h4> */}
              </Col>
              <Col xs={12} lg={7} className="">
                <Row className="align-items-center justify-content-center">
                  <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={() => setDialogDiscount(false)}>
                      ตกลง
                    </Button>
                  </Col>
                  {/* <Col xs={6} lg={5}>
                    <Button type="button" className="button" onClick={ () => handleSubmit('ไม่อนุมัติ')}>
                      ปฏิเสธ
                    </Button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
